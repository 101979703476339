import { useQuery } from '@tanstack/react-query';
import * as mentorApi from 'modules/mentor/api';

const useMentorCatalog = () => {
  return useQuery({
    queryKey: ['mentorCatalog'],
    queryFn: mentorApi.getMentorCatalog,
  });
};

export default useMentorCatalog;
