import { Button, haptic, Input, notify } from '@mindkit';
import { IllustrationSuccess } from '@mindkit/illustrations';
import dayjs from 'dayjs';
import * as authApi from 'modules/auth/api';
import React, { FormEvent, useState } from 'react';
import { AnalyticsService } from 'services/analytics';
import { ErrorService } from 'services/error';
import { SUPPORT_EMAIL } from 'shared/constants/supportEmail';
import getErrorMessage from 'shared/helpers/getErrorMessage';
import isValidEmail from 'shared/helpers/isValidEmail';

const EmailStep: React.FC<{
  redirect?: string;
  mentorId?: string;
  onEmailSent: (email: string) => void;
}> = ({ redirect, mentorId, onEmailSent }) => {
  const [email, setEmail] = useState('');
  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };

  const [isLoading, setIsLoading] = useState(false);

  const sendEmail = async (email: string) => {
    try {
      setIsLoading(true);
      await authApi.sendAuthEmail(email, dayjs.tz.guess(), redirect, mentorId);
      haptic.notification('success');

      setIsLoading(false);

      onEmailSent(email);
    } catch (e) {
      ErrorService.captureException(e);
      AnalyticsService.sendEvent('signin_error', { errorMessage: getErrorMessage(e) });
      notify(getErrorMessage(e), 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSignIn = (e: FormEvent) => {
    e.preventDefault();

    if (!isValidEmail(email)) {
      notify('Please enter a valid email address.', 'error');
      haptic.notification('warning');
      return;
    }

    sendEmail(email);
  };

  return (
    <form className="flex flex-col" onSubmit={handleSignIn}>
      <IllustrationSuccess className="self-center" />

      <div className="m-auto mt-10 flex max-w-[300px] flex-col text-center">
        <h2 className="mb-2">Welcome to Mindshare!</h2>
        <p>Enter your email below to receive a magic sign-in link.</p>
      </div>

      <Input
        id="email"
        name="email"
        autoCapitalize="off"
        autoCorrect="off"
        className="mt-3"
        placeholder="adele@gmail.com"
        value={email}
        onChange={handleInputChange}
        isDisabled={isLoading}
      />
      <Button type="submit" className="mt-3" isDisabled={isLoading} isLoading={isLoading}>
        Continue
      </Button>
      <Button
        className="mb-6 mt-1"
        as="a"
        target="_blank"
        href={`mailto:${SUPPORT_EMAIL}`}
        variant="light"
      >
        Contact support
      </Button>
    </form>
  );
};

export default EmailStep;
