import { Button, notify } from '@mindkit';
import { IconUser } from '@mindkit/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import { openSignInModal } from 'modules/auth';
import useAuth from 'modules/auth/hooks/useAuth';
import usePayment from 'modules/payment/hooks/usePayment';
import ShareReferralButton from 'modules/referral/components/ShareReferralButton/ShareReferralButton';
import {
  Timeslot,
  TimeslotCategory,
  timeslotHelpers,
  TimeslotStatus,
} from 'modules/timeslot/model';
import React from 'react';
import { AnalyticsService } from 'services/analytics';

const CalendarItem: React.FC<{
  timeslot: Timeslot;
}> = ({ timeslot }) => {
  const { event, seats, category, status } = timeslot;
  const isBooked = status !== TimeslotStatus.FREE;
  const isEvent = category === TimeslotCategory.EVENT;
  const isAvailable = timeslotHelpers.isTimeslotAvailable(timeslot);
  const isActual = timeslot.date.isAfter(dayjs());

  const { time, price } = timeslotHelpers.getTimeslotFormatted(timeslot, timeslot.types[0]);

  const { buyTimeslot } = usePayment();

  const [isBooking, setIsBooking] = React.useState(false);

  const { isAuth } = useAuth();
  const isFreeItem = timeslot.types[0].price === 0;

  const handleBook = async () => {
    AnalyticsService.sendEvent('btn_book_slot_click', {
      mentorId: timeslot.mentor.id,
      timeslotId: timeslot.id,
    });

    if (isFreeItem && !isAuth) {
      openSignInModal();
      notify('Please sign in to take part in the free event');
      return;
    }

    setIsBooking(true);

    await buyTimeslot(timeslot);

    setIsBooking(false);
  };

  const dateLabel = timeslot.date.format('ddd, MMM DD');

  let btnLabel;
  if (isBooked) {
    btnLabel = 'Booked';
  } else if (!isAvailable) {
    btnLabel = 'Closed';
  } else {
    btnLabel = price;
  }

  return (
    <div
      className={clsx('flex flex-col rounded-3xl border', {
        'border-disabled text-disabled': !isAvailable,
      })}
    >
      {isEvent && (
        <div
          className={clsx('border-b border-dashed px-4 py-3', {
            'border-disabled': !isAvailable,
          })}
        >
          <div className="flex flex-row items-center justify-end gap-2">
            {seats > 10 && (
              <div className="flex flex-row items-center gap-1 rounded-full bg-level2 px-3 py-1">
                <IconUser className="h-3 w-3" />
                <p>{seats} joined</p>
              </div>
            )}

            {isActual && (
              <ShareReferralButton mentorId={timeslot.mentor.id} timeslotId={timeslot.id} />
            )}
          </div>
          <p>{dateLabel}</p>
          <h3>{event?.name}</h3>
        </div>
      )}

      <div className="flex flex-row items-center justify-between gap-6 px-3 py-3">
        <p className="">{time}</p>
        <Button
          className="w-[100px]"
          isDisabled={!isAvailable || isBooking}
          onClick={handleBook}
          isLoading={isBooking}
        >
          {btnLabel}
        </Button>
      </div>
    </div>
  );
};

export default CalendarItem;
