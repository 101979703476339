import { Button } from '@mindkit';
import { openSignInModal } from 'modules/auth';
import React from 'react';

const SignInButton: React.FC<{ className?: string; size?: 'sm' | 'md' }> = ({
  className,
  size,
}) => {
  return (
    <Button className={className} onClick={openSignInModal} size={size}>
      Sign in
    </Button>
  );
};

export default SignInButton;
