import { ColorTheme, NotificationsRoot } from '@mindkit';
import { QueryClientProvider } from '@tanstack/react-query';
import AuthGuard from 'modules/auth/components/AuthGuard/AuthGuard';
import SignInModal from 'modules/auth/components/SignInModal/SignInModal';
import TelegramAutoAuth from 'modules/auth/components/TelegramAutoAuth/TelegramAutoAuth';
import BetaQuestionnaireModal from 'modules/betaPromo/components/BetaQuestionnaireModal/BetaQuestionnaireModal';
import AppLayout from 'modules/general/components/AppLayout/AppLayout';
import GlobalHelmet from 'modules/general/components/GlobalHelmet/GlobalHelmet';
import GlobalSearchParamsHandler from 'modules/general/components/GlobalSearchParamsHandler/GlobalSearchParamsHandler';
import PrefetchMentorCatalog from 'modules/mentor/components/PrefetchMentorCatalog/PrefetchMentorCatalog';
import CheckoutModal from 'modules/payment/components/CheckoutModal/CheckoutModal';
import AuthPage from 'pages/AuthPage';
import CheckoutResultPage from 'pages/CheckoutResultPage';
import HomePage from 'pages/HomePage';
import MentorManagerPage from 'pages/MentorManagerPage';
import MentorPage from 'pages/MentorPage';
import MentorRecordingsPage from 'pages/MentorRecordingsPage';
import SignInPage from 'pages/SignInPage';
import SignOutPage from 'pages/SignOutPage';
import TestUIPage from 'pages/TestUIPage';
import UnknownPage from 'pages/UnknownPage';
import UserBookingsPage from 'pages/UserBookingsPage';
import React from 'react';
import { BrowserRouter, Outlet, Route, Routes } from 'react-router-dom';
import { QueryService } from 'services/query';

const Root: React.FC = () => {
  return (
    <ColorTheme>
      <QueryClientProvider client={QueryService.getClient()}>
        <BrowserRouter>
          <Routes>
            <Route
              element={
                <>
                  <GlobalHelmet />
                  <GlobalSearchParamsHandler />
                  <PrefetchMentorCatalog />

                  <SignInModal />
                  <CheckoutModal />
                  <BetaQuestionnaireModal />

                  <NotificationsRoot />

                  <TelegramAutoAuth>
                    <Outlet />
                  </TelegramAutoAuth>
                </>
              }
            >
              <Route path="auth" element={<AuthPage />} />
              <Route path="sign-in" element={<SignInPage />} />
              <Route path="sign-out" element={<SignOutPage />} />

              <Route path="test-ui" element={<TestUIPage />} />

              <Route element={<AppLayout />}>
                <Route path="/" element={<HomePage />} />

                <Route
                  path="/me/bookings"
                  element={
                    <AuthGuard strategy="redirect_signin">
                      <UserBookingsPage />
                    </AuthGuard>
                  }
                />

                <Route
                  path="mentor/me"
                  element={
                    <AuthGuard strategy="redirect_signin">
                      <MentorManagerPage />
                    </AuthGuard>
                  }
                />
                <Route path="mentor/:mentorId" element={<MentorPage />} />
                <Route path="mentor/:mentorId/recordings" element={<MentorRecordingsPage />} />

                <Route path="checkout/:checkoutId" element={<CheckoutResultPage />} />
              </Route>
            </Route>

            <Route path="*" element={<UnknownPage />} />
          </Routes>
        </BrowserRouter>
      </QueryClientProvider>
    </ColorTheme>
  );
};

export default Root;
