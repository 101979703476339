import useAuth from 'modules/auth/hooks/useAuth';
import { Mentor } from 'modules/mentor/model';
import usePayment from 'modules/payment/hooks/usePayment';
import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

const useFastCheckout = (mentor: Mentor | undefined) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const checkoutTimeslotId = searchParams.get('timeslot_checkout');
  const timeslot = checkoutTimeslotId
    ? mentor?.timeslots.find((timeslot) => timeslot.id === checkoutTimeslotId)
    : undefined;

  const checkoutPackId = searchParams.get('pack_checkout');

  const checkoutRecordingId = searchParams.get('recording_checkout');

  const { isAuth } = useAuth();
  const { buyTimeslot, buyMentorPack, buyRecording } = usePayment();

  useEffect(() => {
    if (isAuth && checkoutPackId) {
      setSearchParams((prevParams) => {
        const updatedSearchParams = new URLSearchParams(prevParams.toString());
        updatedSearchParams.delete('pack_checkout');
        return updatedSearchParams;
      });

      buyMentorPack(checkoutPackId);
    }
  }, [isAuth, checkoutPackId, buyMentorPack, setSearchParams]);

  useEffect(() => {
    if (timeslot) {
      setSearchParams((prevParams) => {
        const updatedSearchParams = new URLSearchParams(prevParams.toString());
        updatedSearchParams.delete('timeslot_checkout');
        return updatedSearchParams;
      });

      buyTimeslot(timeslot);
    }
  }, [timeslot, buyTimeslot, setSearchParams]);

  useEffect(() => {
    if (checkoutRecordingId) {
      setSearchParams((prevParams) => {
        const updatedSearchParams = new URLSearchParams(prevParams.toString());
        updatedSearchParams.delete('recording_checkout');
        return updatedSearchParams;
      });

      buyRecording(checkoutRecordingId);
    }
  }, [isAuth, checkoutRecordingId, buyRecording, setSearchParams]);
};

export default useFastCheckout;
