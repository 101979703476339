import useMediaQuery from './useMediaQuery';

const DESKTOP_SCREEN_WIDTH = '1024px';

const useIsDesktopScreen = () => {
  const isDesktop = useMediaQuery(`(min-width: ${DESKTOP_SCREEN_WIDTH})`);

  return isDesktop;
};

export default useIsDesktopScreen;
