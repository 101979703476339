import { Loader, notify } from '@mindkit';
import { authActions } from 'modules/auth';
import useAuth from 'modules/auth/hooks/useAuth';
import { TgUser } from 'modules/general/model';
import React, { useEffect } from 'react';
import { createPortal } from 'react-dom';
import { ErrorService } from 'services/error';
import { TelegramService } from 'services/telegram';

const TelegramAutoAuth: React.FC<{ children?: React.ReactNode }> = ({ children }) => {
  const { isAuth } = useAuth();

  const tryAuth = async (tgUser: TgUser) => {
    try {
      await authActions.authViaTelegram(TelegramService.getBotId(), tgUser);
    } catch (e) {
      ErrorService.captureException(e, { tags: { authViaTelegram: 'error' } });

      notify('Failed to authenticate via Telegram. Please reload the app.', 'error');
    }
  };

  useEffect(() => {
    if (!TelegramService.isTelegramApp()) {
      return;
    }

    if (isAuth) {
      return;
    }

    const tgUser = TelegramService.getUser();
    if (!tgUser) {
      return;
    }

    tryAuth(tgUser);
  }, [isAuth]);

  if (TelegramService.isTelegramApp() && !isAuth) {
    return createPortal(
      <div className="fixed inset-0 z-50 flex flex-col items-center justify-center bg-level1">
        <Loader />
      </div>,
      document.body,
    );
  }

  return children;
};

export default TelegramAutoAuth;
