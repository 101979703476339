import { Dayjs } from 'dayjs';
import * as timeslotApi from 'modules/timeslot/api';
import { ManagedTimeslot, weekHelpers } from 'modules/timeslot/model';
import { QueryService } from 'services/query';

export const getMentorEvents = async (mentorId: string) => {
  return timeslotApi.getMentorEvents(mentorId);
};

export const getTimeslots = async (mentorId: string, startDate: Dayjs, endDate: Dayjs) => {
  const timeslots = await timeslotApi.getTimeslotsByMentorId(
    mentorId,
    startDate.subtract(1, 'day').utc().format('YYYY-MM-DD'),
    endDate.add(1, 'day').utc().format('YYYY-MM-DD'),
  );

  return timeslots.filter((t) => t.date.isBetween(startDate, endDate, 'day', '[]'));
};

export const invalidateTimeslots = (mentorId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['timeslots', 'mentor', mentorId] });
};

// startDate & endDate are local time
export const getManagedTimeslots = async (startDate: Dayjs, endDate: Dayjs) => {
  const timeslots = await timeslotApi.getManagedTimeslots(
    startDate.subtract(1, 'day').utc().format('YYYY-MM-DD'),
    endDate.add(1, 'day').utc().format('YYYY-MM-DD'),
  );

  return timeslots.filter((t) => t.date.isBetween(startDate, endDate, 'day', '[]'));
};

const handleTimeslotCreated = (timeslot: ManagedTimeslot) => {
  const week = weekHelpers.getWeek(timeslot.date);
  QueryService.getClient().setQueriesData(
    { queryKey: ['manager-timeslots', week.id] },
    (oldData?: ManagedTimeslot[]) => {
      if (oldData) {
        return [...oldData, timeslot];
      }
    },
  );
  QueryService.getClient().invalidateQueries({ queryKey: ['manager-timeslots', week.id] });
};

export const createTimeslot = async (date: Dayjs, timeslotTypeId: number) => {
  const timeslot = await timeslotApi.createTimeslot(
    date.format('YYYY-MM-DDTHH:mm:ssZ'),
    timeslotTypeId,
  );
  handleTimeslotCreated(timeslot);

  return timeslot;
};

const handleTimeslotDeleted = (timeslot: ManagedTimeslot) => {
  const week = weekHelpers.getWeek(timeslot.date);
  QueryService.getClient().setQueriesData(
    { queryKey: ['manager-timeslots', week.id] },
    (oldData?: ManagedTimeslot[]) => {
      if (oldData) {
        return oldData.filter((ts) => ts.id !== timeslot.id);
      }
    },
  );
  QueryService.getClient().invalidateQueries({ queryKey: ['manager-timeslots', week.id] });
};

export const deleteTimeslot = async (timeslot: ManagedTimeslot) => {
  await timeslotApi.deleteTimeslot(timeslot.id);

  handleTimeslotDeleted(timeslot);
};
