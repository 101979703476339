import React from 'react';
import { Navigate } from 'react-router-dom';
import { TelegramService } from 'services/telegram';

import MentorCatalogPage from './MentorCatalogPage';

const HomePage: React.FC = () => {
  if (TelegramService.isTelegramApp() && !TelegramService.isGeneralBot()) {
    return <Navigate to={TelegramService.getSafeRootRedirect()} />;
  }

  return <MentorCatalogPage />;
};

export default HomePage;
