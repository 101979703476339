export interface TgUser {
  id: number;
  first_name: string;
  last_name?: string;
  username?: string;
  language_code?: string;
  allows_write_to_pm?: boolean;
}

export enum PlatformType {
  TELEGRAM = 'telegram',
  WEB = 'web',
}
