import { Dayjs } from 'dayjs';
import { NotOwnedRecording, Recording } from 'modules/recording/model';

export enum TimeslotStatus {
  FREE = 'Free',
  BOOKED = 'Booked',
}

export enum TimeslotCategory {
  PERSONAL = 'Personal',
  EVENT = 'Event',
}

export interface EventInfo {
  id: string;
  name: string;
  description: string;
  minSeats: number;
  maxSeats: number;

  recording?: Recording | NotOwnedRecording;
}

export interface TimeslotType {
  id: number;
  name: string;
  duration: number;
  price: number;
  currency: string;
}

export interface Timeslot {
  id: string;
  category: TimeslotCategory;
  types: TimeslotType[];
  date: Dayjs;
  status: TimeslotStatus;
  seats: number;

  mentor: {
    id: string;
    displayName: string;
    profilePhotoUrl?: string;
  };

  event?: EventInfo;
}

export interface ManagedTimeslot {
  id: string;
  category: TimeslotCategory;
  types: TimeslotType[];
  date: Dayjs;
  status: TimeslotStatus;

  seats: number;
  event?: EventInfo;
}
