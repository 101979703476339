import React, { useCallback, useEffect, useState } from 'react';

interface ThemeContextValue {
  theme: 'light' | 'dark';
  isDark: boolean;
  isLight: boolean;
  toggleTheme: () => void;
  setTheme: (theme: 'light' | 'dark') => void;
  resetToSystemTheme: () => void;
}

const ThemeContext = React.createContext<ThemeContextValue>({
  theme: 'light',
  isDark: false,
  isLight: true,
  toggleTheme: () => {},
  setTheme: () => {},
  resetToSystemTheme: () => {},
});

const useTheme = () => {
  const [theme, setThemeState] = useState<'light' | 'dark'>(() => {
    const savedTheme = localStorage.getItem('mindkit-theme');
    if (savedTheme === 'dark' || savedTheme === 'light') {
      return savedTheme;
    }

    return window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light';
  });
  const setTheme = useCallback((theme: 'light' | 'dark') => {
    localStorage.setItem('mindkit-theme', theme);
    setThemeState(theme);
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.setTheme = setThemeState;

  const toggleTheme = useCallback(() => {
    setTheme(theme === 'light' ? 'dark' : 'light');
  }, [theme, setTheme]);

  const resetToSystemTheme = useCallback(() => {
    localStorage.removeItem('mindkit-theme');
    setThemeState(window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark' : 'light');
  }, []);

  useEffect(() => {
    const mediaQuery = window.matchMedia('(prefers-color-scheme: dark)');
    const listener = () => {
      const systemTheme = mediaQuery.matches ? 'dark' : 'light';
      const savedTheme = localStorage.getItem('mindkit-theme');
      if (savedTheme !== 'dark' && savedTheme !== 'light') {
        setThemeState(systemTheme);
      }
    };
    mediaQuery.addEventListener('change', listener);
    return () => {
      mediaQuery.removeEventListener('change', listener);
    };
  }, []);

  return {
    theme,
    isDark: theme === 'dark',
    isLight: theme === 'light',
    setTheme,
    toggleTheme,
    resetToSystemTheme,
  };
};

export const ColorTheme: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const themeData = useTheme();

  useEffect(() => {
    document.documentElement.dataset.theme = themeData.theme;
    document.documentElement.classList.toggle('dark', themeData.theme === 'dark');
  }, [themeData.theme]);

  return <ThemeContext.Provider value={themeData}>{children}</ThemeContext.Provider>;
};

export const useColorTheme = () => {
  return React.useContext(ThemeContext);
};
