/* eslint-disable no-nested-ternary */
import clsx from 'clsx';
import BookingItem from 'modules/booking/components/BookingItem/BookingItem';
import { Booking } from 'modules/booking/model';
import RecordingItem from 'modules/recording/components/RecordingItem/RecordingItem';
import { Recording } from 'modules/recording/model';
import React from 'react';

import Tabs from './Tabs';

const TabOptions = [
  { id: 'sessions', label: 'Sessions' },
  { id: 'recordings', label: 'Recordings' },
];

const UserArchiveCalls: React.FC<{
  archive: Booking[];
  recordings: Recording[];
  className?: string;
}> = ({ archive, recordings, className }) => {
  const [selectedTab, setSelectedTab] = React.useState(TabOptions[0].id);

  const isEmpty = !archive.length && !recordings.length;
  if (isEmpty) {
    return null;
  }

  return (
    <section className={clsx('page-section', className)}>
      <div className="page-section-header">
        <h2 className="mb-4">Archive</h2>
        <Tabs
          className="overflow-auto"
          tabs={TabOptions}
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
        />
      </div>

      {selectedTab === 'sessions' ? (
        archive.length > 0 ? (
          <div className="flex flex-col gap-2 desk:gap-4">
            {archive.map((booking) => {
              return <BookingItem key={booking.id} booking={booking} />;
            })}
          </div>
        ) : (
          <div>
            <p className="py-12 text-center">You don&apos;t have any sessions in archive</p>
          </div>
        )
      ) : null}

      {selectedTab === 'recordings' ? (
        recordings.length > 0 ? (
          <div className="flex flex-col gap-2 desk:gap-4">
            {recordings.map((recording) => {
              return <RecordingItem key={recording.id} recording={recording} />;
            })}
          </div>
        ) : (
          <div>
            <p className="py-12 text-center">You don&apos;t have any recordings</p>
          </div>
        )
      ) : null}
    </section>
  );
};

export default UserArchiveCalls;
