import { PlatformType } from 'modules/general/model';
import { TelegramService } from 'services/telegram';

export const getPlatform = () => {
  if (TelegramService.isTelegramApp()) {
    return PlatformType.TELEGRAM;
  }

  return PlatformType.WEB;
};
