import { IconCheck } from '@mindkit/icons';
import clsx from 'clsx';
import React from 'react';

const StepCard: React.FC<{
  title: string;

  notDoneSlot?: React.ReactNode;
  doneSlot?: React.ReactNode;

  isDone?: boolean;
  isOptional?: boolean;
  className?: string;
}> = ({ title, notDoneSlot, doneSlot, isDone, isOptional, className }) => {
  return (
    <div
      className={clsx(
        'rounded-3xl px-5 pb-5 pt-2 text-sm',
        {
          'bg-primary-surface text-on-surface': !isDone,
          'bg-success-surface text-on-surface': isDone,
        },
        className,
      )}
    >
      <h4 className="mb-2 text-sm">
        {title}
        {isOptional && <span className="ml-1 font-normal">(optional)</span>}
      </h4>
      {isDone ? (
        <div className="flex flex-row gap-2">
          <IconCheck className="h-6 w-6 shrink-0" />
          {doneSlot}
        </div>
      ) : (
        notDoneSlot
      )}
    </div>
  );
};

export default StepCard;
