import { Dropdown } from '@mindkit';
import { IconCalendar, IconDashboard, IconExit, IconHome, IconSupport } from '@mindkit/icons';
import { openQuestionnaireModal } from 'modules/betaPromo/components/BetaQuestionnaireModal/betaQuestionnaireStore';
import useProfile from 'modules/user/hooks/useProfile';
import React from 'react';
import { Link } from 'react-router-dom';
import { TelegramService } from 'services/telegram';
import { SUPPORT_EMAIL } from 'shared/constants/supportEmail';

import ProfileButton from './ProfileButton';

const UserInfoBar: React.FC<{ className?: string }> = ({ className }) => {
  const { data: profile } = useProfile();

  return (
    <Dropdown
      placement="bottom-end"
      trigger={<ProfileButton profile={profile} className={className} />}
      content={
        <ul className="flex w-[300px] flex-col divide-y divide-disabled transition-colors">
          <li>
            <Link
              type="button"
              className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
              to="/me/bookings"
            >
              <IconHome />
              <span>My profile</span>
            </Link>
          </li>

          {!TelegramService.isTelegramApp() && profile?.user.isMentor && (
            <li>
              <Link
                type="button"
                className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                to="/mentor/me"
              >
                <IconDashboard />
                <span>Mentor dashboard</span>
              </Link>
            </li>
          )}

          <li>
            <a
              type="button"
              className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
              href={
                TelegramService.isTelegramApp()
                  ? 'https://t.me/mindshare_support_bot?start=start'
                  : `mailto:${SUPPORT_EMAIL}`
              }
              target="_blank"
              rel="noreferrer"
            >
              <IconSupport />
              <div>
                <p>Contact support</p>
                <p className="text-xs text-disabled">{SUPPORT_EMAIL}</p>
              </div>
            </a>
          </li>

          {!profile?.user.isMentor && (
            <li>
              <button
                type="button"
                className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                onClick={() => openQuestionnaireModal('user_menu')}
              >
                <IconCalendar />
                <span>Want the same tool?</span>
              </button>
            </li>
          )}

          {!TelegramService.isTelegramApp() && (
            <li>
              <Link
                type="button"
                className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                to="/sign-out"
              >
                <IconExit />
                <span>Sign out</span>
              </Link>
            </li>
          )}
        </ul>
      }
    />
  );
};

export default UserInfoBar;
