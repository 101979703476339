import { Button } from '@mindkit';
import clsx from 'clsx';
import { Booking } from 'modules/booking/model';
import React from 'react';

const CalendarLink: React.FC<{ booking: Booking; className?: string }> = ({
  booking,
  className,
}) => {
  const { googleCalendarLink } = booking;

  if (!googleCalendarLink) {
    return null;
  }

  return (
    <Button
      className={clsx(className)}
      variant="light"
      size="sm"
      as="a"
      href={googleCalendarLink}
      target="_blank"
      rel="noreferrer"
    >
      Add to calendar
    </Button>
  );
};

export default CalendarLink;
