import axios, { isAxiosError } from 'axios';
import { ErrorService } from 'services/error';

const BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

export const ApiService = {
  init: (getAuthToken: () => string | null, onTokenInvalid: () => void) => {
    ApiService.apiInstance.interceptors.request.use((config) => {
      const token = getAuthToken();
      if (token) {
        config.headers.setAuthorization(`Bearer ${token}`);
      }

      return config;
    });

    ApiService.apiInstance.interceptors.response.use(
      (response) => response,
      (error) => {
        if (isAxiosError(error)) {
          ErrorService.captureAxiosError(error);

          if (error.response?.status === 401) {
            setTimeout(() => {
              onTokenInvalid();
            });
          }
        }

        return Promise.reject(error);
      },
    );
  },

  apiInstance: axios.create({ baseURL: `${BASE_URL}/api` }),
};
