import { useQuery } from '@tanstack/react-query';
import { recordingActions } from 'modules/recording';

const useMentorRecordings = (mentorId: string) => {
  return useQuery({
    queryKey: ['mentor', mentorId, 'recordings'],
    queryFn: () => {
      return recordingActions.getMentorRecordings(mentorId);
    },
  });
};

export default useMentorRecordings;
