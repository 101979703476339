import { useQuery } from '@tanstack/react-query';
import { mentorActions } from 'modules/mentor';

const useMentorManager = () => {
  return useQuery({
    queryKey: ['mentorManager'],
    queryFn: mentorActions.getMentorManager,
  });
};

export default useMentorManager;
