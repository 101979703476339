import {
  autoUpdate,
  Placement,
  useClick,
  useDismiss,
  useFloating,
  UseFloatingOptions,
  useFocus,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import * as React from 'react';

export interface PopoverController {
  open: () => void;
  close: () => void;
}

export interface PopoverOptions {
  initialOpen?: boolean;
  middleware?: UseFloatingOptions['middleware'];
  placement?: Placement;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
  contollerRef?: React.Ref<PopoverController>;
}

const usePopover = ({
  initialOpen = false,
  placement = 'bottom',
  middleware = [],
  open: controlledOpen,
  onOpenChange: setControlledOpen,
  contollerRef,
}: PopoverOptions = {}) => {
  const [uncontrolledOpen, setUncontrolledOpen] = React.useState(initialOpen);

  const isControlled = controlledOpen != null;

  const open = isControlled ? controlledOpen : uncontrolledOpen;
  const setOpen = isControlled ? setControlledOpen! : setUncontrolledOpen;

  const setController = React.useCallback(
    (value: PopoverController | null) => {
      if (contollerRef) {
        if (typeof contollerRef === 'function') {
          contollerRef(value);
        } else if (contollerRef) {
          // 👇 Something wrong with ref type
          // eslint-disable-next-line
          // @ts-ignore
          // eslint-disable-next-line
          contollerRef.current = value;
        }
      }
    },
    [contollerRef],
  );

  React.useEffect(() => {
    const controller: PopoverController = {
      open: () => setOpen(true),
      close: () => setOpen(false),
    };
    setController(controller);

    return () => {
      setController(null);
    };
  }, [setController, setOpen]);

  const floatingData = useFloating({
    placement,
    open,
    onOpenChange: setOpen,
    whileElementsMounted: autoUpdate,
    middleware,
  });

  const { context } = floatingData;

  const click = useClick(context, { enabled: !isControlled });
  const dismiss = useDismiss(context);
  const role = useRole(context);

  const interactions = useInteractions([click, dismiss, role]);

  return React.useMemo(
    () => ({
      open,
      setOpen,
      ...interactions,
      ...floatingData,
    }),
    [open, setOpen, interactions, floatingData],
  );
};

export default usePopover;
