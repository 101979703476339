import { IconCross } from '@mindkit/icons';
import clsx from 'clsx';

import IconButton from '../IconButton/IconButton';
import { ModalContent, ModalContentProps, useModalContext } from '../ModalCore';
import cm from './OverlayContainer.module.css';

// ToDo: Add ref forwarding
const OverlayContainer: React.FC<ModalContentProps> = ({ className, children, ...props }) => {
  const { setOpen } = useModalContext();

  return (
    <ModalContent
      {...props}
      wrapperClassName={clsx('fixed inset-0', cm.overlayContainer)}
      className={clsx(
        'h-full max-h-full min-h-full w-full min-w-full max-w-full overflow-auto bg-level1 px-5 pb-4 pt-9',
        className,
      )}
      transitionConfig={{
        initial: { opacity: 0, transform: 'scale(1.03)' },
        close: { opacity: 0 },
        duration: { open: 300, close: 200 },
      }}
      noOverlay
    >
      <IconButton
        className="absolute right-4 top-4 z-20"
        onClick={(e) => setOpen(false, e.nativeEvent, 'click')}
      >
        <IconCross />
      </IconButton>

      {children}
    </ModalContent>
  );
};

export default OverlayContainer;
