import { Dayjs } from 'dayjs';
import { PaymentMethod, PaymentStatus } from 'modules/payment/model';
import { EventInfo } from 'modules/timeslot/model';

export enum BookingStatus {
  WAITING_FOR_PAYMENT = 'WaitingForPayment',
  BOOKED = 'Booked',
  CONFIRMED = 'Confirmed',
  CANCELED = 'Canceled',
  COMPLETED = 'Completed',
}

export interface Booking {
  id: string;
  userId: string;
  timeslotId: string;
  duration: number;
  price: number;
  currency: string;
  status: BookingStatus;
  payment: PaymentStatus;
  paymentLinks: Array<{ type: PaymentMethod; url: string }>;
  date: Dayjs;
  endTime: Dayjs;
  mentor: {
    id: string;
    displayName: string;
    profilePhotoUrl?: string;
  };
  description?: string;
  callInfo?: string;
  googleCalendarLink?: string;
  event?: EventInfo;
}
