import clsx from 'clsx';
import React from 'react';

type TextAreaProps = React.ComponentProps<'textarea'> & {
  className?: string;
  isDisabled?: boolean;
  isError?: boolean;
};

const TextArea = React.forwardRef<HTMLTextAreaElement, TextAreaProps>(
  ({ className, isDisabled, isError, children, ...other }, ref) => {
    const autoHeight = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      // const { lineHeight } = getComputedStyle(e.target);
      // const lineHeightNum = Number(lineHeight.replace('px', ''));
      // const minHeight = lineHeightNum * (other.rows || 1) + 10 * 2;

      const minHeight = 48;

      e.target.style.height = '0px';
      e.target.style.height = `${Math.max(e.target.scrollHeight + 2, minHeight)}px`;

      other.onInput?.(e);
    };

    return (
      <textarea
        ref={ref}
        {...other}
        className={clsx(
          'h-12 rounded-xl border bg-level1 px-[18px] py-[9px] outline-none transition-colors placeholder:text-secondary',
          {
            'border-red-200 focus:border-danger': isError,
            'border-disabled focus:border-primary': !isError,
            'text-disabled': isDisabled,
          },
          className,
        )}
        disabled={isDisabled}
        onInput={autoHeight}
        aria-invalid={isError}
      />
    );
  },
);
TextArea.displayName = 'TextArea';

export default TextArea;
