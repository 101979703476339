import clsx from 'clsx';
import React from 'react';

import cm from './Input.module.css';

type InputProps = React.ComponentProps<'input'> & {
  isDisabled?: boolean;
  isError?: boolean;
  className?: string;
};

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  ({ className, isDisabled, isError, children, ...other }, ref) => {
    return (
      <input
        {...other}
        ref={ref}
        className={clsx(
          cm.mindkitInput,
          'h-12 appearance-none rounded-xl border bg-level1 px-[18px] outline-none transition-colors placeholder:text-secondary',
          {
            'border-red-200 focus:border-danger': isError,
            'border-disabled focus:border-primary': !isError,
            'text-disabled': isDisabled,
          },
          className,
        )}
        disabled={isDisabled}
        aria-invalid={isError}
      />
    );
  },
);
Input.displayName = 'Input';

export default Input;
