import { useQuery } from '@tanstack/react-query';
import useAuth from 'modules/auth/hooks/useAuth';
import { getBookings } from 'modules/booking/api';

const useBookings = () => {
  const { isAuth } = useAuth();

  return useQuery({
    queryKey: ['bookings'],
    queryFn: () => {
      return getBookings();
    },
    initialData: [],
    enabled: isAuth,
  });
};

export default useBookings;
