import React, { useState } from 'react';

import CodeStep from './CodeStep';
import EmailStep from './EmailStep';

type Step = { type: 'email' } | { type: 'code'; email: string };

const SignInForm: React.FC<{
  redirect?: string;
  mentorId?: string;
  onSignInSuccess?: () => void;
  className?: string;
}> = ({ redirect, mentorId, onSignInSuccess, className }) => {
  const [step, setStep] = useState<Step>({ type: 'email' });

  const handleEmailSent = (email: string) => {
    setStep({ type: 'code', email });
  };

  const handleBackToTheStart = () => {
    setStep({ type: 'email' });
  };

  return (
    <div className={className}>
      {step.type === 'email' && (
        <EmailStep redirect={redirect} mentorId={mentorId} onEmailSent={handleEmailSent} />
      )}
      {step.type === 'code' && (
        <CodeStep email={step.email} onBack={handleBackToTheStart} onSignIn={onSignInSuccess} />
      )}
    </div>
  );
};

export default SignInForm;
