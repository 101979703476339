import { IconButton, Loader } from '@mindkit';
import { IconArrowLeft } from '@mindkit/icons';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import useMentor from 'modules/mentor/hooks/useMentor';
import MentorPacksWidget from 'modules/pack/components/MentorPacksWidget/MentorPacksWidget';
import MentorRecordingsSection from 'modules/recording/components/MentorRecordingsSection/MentorRecordingsSection';
import MentorEventsSection from 'modules/timeslot/components/MentorEventsSection/MentorEventsSection';
import React from 'react';
import { Link, useParams } from 'react-router-dom';

const MentorRecordingsPage: React.FC = () => {
  const { mentorId } = useParams();
  if (!mentorId) {
    throw new Error('Mentor id is required');
  }

  const { data: mentor, isPending, isError } = useMentor(mentorId);

  if (isError) {
    return <p className="m-auto text-danger">Something went wrong ...</p>;
  }

  if (isPending) {
    return <Loader className="m-auto" />;
  }

  return (
    <PageLayout
      bodyClassName="gap-8"
      deskBodyClassName="max-w-xl"
      body={
        <>
          <div className="flex flex-row items-center gap-2">
            <Link to={`/mentor/${mentorId}`}>
              <IconButton>
                <IconArrowLeft />
              </IconButton>
            </Link>

            <h1 className="text-h2">Events & Recordings</h1>
          </div>

          <MentorEventsSection mentorId={mentorId} />

          <MentorRecordingsSection mentorId={mentorId} />
        </>
      }
      deskSidebar={mentor.packs.length ? <MentorPacksWidget mentor={mentor} /> : null}
    />
  );
};

export default MentorRecordingsPage;
