import useAuth from 'modules/auth/hooks/useAuth';
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

const AuthGuard: React.FC<{
  children?: React.ReactNode;
  strategy?: 'redirect_home' | 'redirect_signin';
}> = ({ children, strategy }) => {
  const { isAuth } = useAuth();

  const location = useLocation();

  if (!isAuth && (!strategy || strategy === 'redirect_home')) {
    return <Navigate to="/" />;
  }

  if (!isAuth && strategy === 'redirect_signin') {
    return <Navigate to={`/sign-in?redirect=${location.pathname}`} />;
  }

  return children;
};

export default AuthGuard;
