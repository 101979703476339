import { Button, Loader, Modal, notify } from '@mindkit';
import { IllustrationDiscount } from '@mindkit/illustrations';
import { getPlatform } from 'modules/general/actions/generalActions';
import { PlatformType } from 'modules/general/model';
import { referralActions } from 'modules/referral';
import { MentorReferral } from 'modules/referral/model';
import copyToClipboard from 'modules/shared/helpers/copyToClipboard';
import React, { useEffect, useState } from 'react';
import { AnalyticsService } from 'services/analytics';
import { TelegramService } from 'services/telegram';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const MentorReferralModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  mentorId: string;
  timeslotId?: string;
}> = ({ isOpen, onClose, mentorId, timeslotId }) => {
  const [referral, setReferral] = useState<MentorReferral | null>(null);

  const generateReferral = async (mentorId: string, timeslotId?: string) => {
    setReferral(null);
    try {
      const referral = await referralActions.generateMentorReferral(
        mentorId,
        TelegramService.isTelegramApp() ? TelegramService.getBotId() : undefined,
        timeslotId,
      );
      setReferral(referral);
    } catch (error) {
      notify(getErrorMessage(error), 'error');
    }
  };
  useEffect(() => {
    if (isOpen) {
      generateReferral(mentorId, timeslotId);
    }
  }, [isOpen, mentorId, timeslotId]);

  const handleCopyReferralLink = () => {
    if (!referral) {
      return;
    }

    AnalyticsService.sendEvent('copy_mentor_referral_link', { mentorId, timeslotId });

    const platform = getPlatform();
    const isTelegram = platform === PlatformType.TELEGRAM;

    const link = isTelegram ? referral.telegramLink : referral.webLink;
    copyToClipboard(link);
  };

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="flex w-[420px] flex-col items-center">
      {referral ? (
        <>
          <IllustrationDiscount className="mb-6 shrink-0" />

          <h2>Your referral code is:</h2>
          <h2 className="mb-4 text-primary">{referral?.code}</h2>

          <p className="text-center">
            Share the link with your friends to get $5 discount for every friend who pays a session
            or event with your referral code. Friends will also get discounts when they use your
            referral link.
          </p>
          <Button
            className="mb-4 mt-4 shrink-0"
            variant="outlined"
            size="sm"
            color="primary dark:primary-inverse"
            onClick={handleCopyReferralLink}
          >
            Copy referral link
          </Button>
          <p className="text-center">
            May not be available for some events or sessions. Pay attention to the checkout details.
          </p>
        </>
      ) : (
        <Loader className="my-52" />
      )}
    </Modal>
  );
};

export default MentorReferralModal;
