import ZoomLogo from '@assets/ZoomLogo.png';
import { Button, Dropdown, IconButton, Modal, notify } from '@mindkit';
import { IconCalendar, IconEdit, IconSettings } from '@mindkit/icons';
import clsx from 'clsx';
import { mentorActions } from 'modules/mentor';
import { mentorHelpers, MentorManager } from 'modules/mentor/model';
import React, { useState } from 'react';
import getErrorMessage from 'shared/helpers/getErrorMessage';

import MentorDetailsEditForm from '../MentorDetailsEditForm/MentorDetailsEditForm';

const IntegrationCard: React.FC<{
  icon: React.ReactNode;
  title: string;
  description?: string;
  withDisconnect: boolean;
  isDisconnecting?: boolean;
  onDisconnect: () => void;
}> = ({ icon, title, description, withDisconnect, onDisconnect, isDisconnecting }) => {
  return (
    <div className="flex w-full flex-row gap-3 px-5 py-2 text-left">
      {icon}
      <div>
        <p>{title}</p>
        {description && <p className="text-xs text-secondary">{description}</p>}
        {withDisconnect && (
          <Button
            className="mt-1"
            variant="light"
            color="danger"
            size="sm"
            isDisabled={isDisconnecting}
            onClick={onDisconnect}
          >
            Disconnect
          </Button>
        )}
      </div>
    </div>
  );
};

const SettingsDropdown: React.FC<{ mentor: MentorManager; className?: string }> = ({
  mentor,
  className,
}) => {
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const handleOpenEditModal = () => {
    setIsEditModalOpen(true);
  };
  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
  };

  const status = mentorHelpers.getMentorManagerStatus(mentor);

  const handleConnectZoom = () => {
    window.open(status.zoomIntegration!.authUrl!, '_blank');
  };
  const [isDisconnectingZoom, setIsDisconnectingZoom] = useState(false);
  const handleDisconnectZoom = async () => {
    setIsDisconnectingZoom(true);
    try {
      await mentorActions.revokeMentorManagerIntegration('zoom');
      notify('Zoom account disconnected', 'success');
    } catch (e) {
      notify(getErrorMessage(e), 'error');

      throw e;
    } finally {
      setIsDisconnectingZoom(false);
    }
  };

  const handleConnectCalendar = () => {
    window.open(status.calendarIntegration!.authUrl!, '_blank');
  };
  const [isDisconnectingCalendar, setIsDisconnectingCalendar] = useState(false);
  const handleDisconnectCalendar = async () => {
    setIsDisconnectingCalendar(true);
    try {
      await mentorActions.revokeMentorManagerIntegration('calendar');
      notify('Calendar account disconnected', 'success');
    } catch (e) {
      notify(getErrorMessage(e), 'error');

      throw e;
    } finally {
      setIsDisconnectingCalendar(false);
    }
  };

  return (
    <>
      <Modal className="w-[540px] !pt-14" isOpen={isEditModalOpen} onClose={handleCloseEditModal}>
        <MentorDetailsEditForm
          onSuccess={() => {
            notify('Saved', 'success');
            handleCloseEditModal();
          }}
        />
      </Modal>

      <Dropdown
        placement="bottom-end"
        trigger={
          <IconButton className={clsx(className)} color="primary">
            <IconSettings />
          </IconButton>
        }
        content={
          <ul className="flex w-[300px] flex-col divide-y divide-disabled transition-colors">
            <li>
              <button
                type="button"
                className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                onClick={handleOpenEditModal}
              >
                <IconEdit />
                <span>Edit profile</span>
              </button>
            </li>
            <li>
              {status.isZoomConnected ? (
                <IntegrationCard
                  icon={<img src={ZoomLogo} alt="Zoom logo" className="mt-1 h-6 w-6" />}
                  title="Zoom"
                  description={status.zoomIntegration?.activeProfile}
                  withDisconnect={status.zoomIntegration?.canRevoke ?? false}
                  onDisconnect={handleDisconnectZoom}
                  isDisconnecting={isDisconnectingZoom}
                />
              ) : (
                <button
                  type="button"
                  className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                  onClick={handleConnectZoom}
                >
                  <img src={ZoomLogo} alt="Zoom logo" className="h-6 w-6" />
                  <span>Connect zoom</span>
                </button>
              )}
            </li>
            <li>
              {status.isCalendarConnected ? (
                <IntegrationCard
                  icon={<IconCalendar className="mt-1" />}
                  title="Calendar"
                  description={status.calendarIntegration?.activeProfile}
                  withDisconnect={status.calendarIntegration?.canRevoke ?? false}
                  onDisconnect={handleDisconnectCalendar}
                  isDisconnecting={isDisconnectingCalendar}
                />
              ) : (
                <button
                  type="button"
                  className="dropdown-item flex h-20 w-full flex-row items-center gap-3 px-5 py-4 text-left hover:text-primary"
                  onClick={handleConnectCalendar}
                >
                  <IconCalendar />
                  <span>Connect calendar</span>
                </button>
              )}
            </li>
          </ul>
        }
      />
    </>
  );
};

export default SettingsDropdown;
