import { Loader } from '@mindkit';
import { IllustrationSandClock } from '@mindkit/illustrations';
import clsx from 'clsx';
import { ManagedTimeslot, timeslotHelpers } from 'modules/timeslot/model';
import React, { useMemo } from 'react';

import TimeslotItem from './TimeslotItem';

const sortTimeslots = (timeslots: ManagedTimeslot[]) => {
  return timeslots.sort((a, b) => {
    return a.date.diff(b.date);
  });
};

const TimeslotList: React.FC<{
  timeslots: ManagedTimeslot[];
  isLoading?: boolean;
  isError?: boolean;
  className?: string;
}> = ({ timeslots, isLoading, isError, className }) => {
  const isEmpty = !timeslots.length;

  const sortedTimeslots = useMemo(() => sortTimeslots(timeslots), [timeslots]);
  const groups = timeslotHelpers.groupTimeslotsByDay(sortedTimeslots);

  let body;
  if (isError) {
    body = <p className="py-20 text-center text-danger">Unable to load timeslots...</p>;
  } else if (isLoading && isEmpty) {
    body = <Loader className="m-auto self-center" />;
  } else if (isEmpty) {
    body = (
      <div className="flex flex-grow flex-col items-center justify-center">
        <IllustrationSandClock />
        <p className="mt-2 max-w-xs text-center">
          This week hasn&apos;t been planned yet, it is time to schedule slots!
        </p>
      </div>
    );
  } else {
    body = (
      <>
        {groups.map((group) => {
          const dateLabel = group.date.format('ddd, MMM DD');

          return (
            <div key={group.date.toISOString()} className="mb-5">
              <p>{dateLabel}</p>

              <div className="mt-1 flex flex-col gap-2">
                {group.timeslots.map((timeslot) => {
                  return <TimeslotItem key={timeslot.id} timeslot={timeslot} />;
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return <div className={clsx('flex min-h-[320px] flex-col', className)}>{body}</div>;
};

export default TimeslotList;
