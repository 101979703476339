import { haptic, Modal } from '@mindkit';
import { closeCheckoutModal, useCheckoutStore } from 'modules/payment';
import CheckoutForm from 'modules/payment/components/CheckoutForm/CheckoutForm';
import { CheckoutProcessResult } from 'modules/payment/model';
import React, { useEffect } from 'react';
import { AnalyticsService } from 'services/analytics';
import { TelegramService } from 'services/telegram';

const CheckoutModal: React.FC = () => {
  const { isOpen, checkout } = useCheckoutStore();

  // Save checkout to the local state to prevent interface flickering when checkout becomes undefined, but modal is still visible
  const [lastCheckout, setLastCheckout] = React.useState(checkout);
  useEffect(() => {
    if (checkout) {
      setLastCheckout(checkout);

      TelegramService.openFull();
    }
  }, [checkout]);
  const actualCheckout = checkout || lastCheckout;

  useEffect(() => {
    if (isOpen) {
      haptic.notification('success');
    }
  }, [isOpen]);

  const handleClose = () => {
    AnalyticsService.sendEvent('checkout_modal_btn_close_click', checkout?.aggregatedInfo);

    closeCheckoutModal('cancelled');
  };

  const handleCheckoutResult = (result: CheckoutProcessResult) => {
    if (result === 'paid' || result === 'redirect') {
      closeCheckoutModal(result);
    }
  };

  return (
    <Modal
      type="overlay-classic"
      isOpen={isOpen}
      onClose={handleClose}
      className="w-lg overflow-auto !p-0 desk:h-auto"
      closeOnClickOutside={false}
    >
      {actualCheckout ? (
        <CheckoutForm
          className="h-full"
          checkout={actualCheckout}
          onResult={handleCheckoutResult}
        />
      ) : (
        <p>Nothing to checkout...</p>
      )}
    </Modal>
  );
};

export default CheckoutModal;
