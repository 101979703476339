import { IconCross } from '@mindkit/icons';
import clsx from 'clsx';

import IconButton from '../IconButton/IconButton';
import { ModalContent, ModalContentProps, useModalContext } from '../ModalCore';
import cm from './MobileContainer.module.css';

// ToDo: Add ref forwarding
const MobileContainer: React.FC<ModalContentProps> = ({ className, children, ...props }) => {
  const { setOpen } = useModalContext();

  return (
    <ModalContent
      {...props}
      wrapperClassName="fixed inset-0"
      className={clsx(
        'absolute z-20 min-w-full max-w-full overflow-auto overscroll-contain rounded-2xl rounded-b-none bg-level1 px-5 pb-4 pt-9',
        cm.mobileContainer,
        className,
      )}
      transitionConfig={{
        initial: { transform: 'translateY(60%)' },
        close: {
          transform: 'translateY(100%)',
        },

        duration: { open: 300, close: 300 },
      }}
    >
      <IconButton
        className="absolute right-4 top-4"
        onClick={(e) => setOpen(false, e.nativeEvent, 'click')}
      >
        <IconCross />
      </IconButton>

      {children}
    </ModalContent>
  );
};

export default MobileContainer;
