/* eslint-disable no-plusplus */
/* eslint-disable no-param-reassign */

export const poll = async <T = unknown>(
  getPromise: () => Promise<T>,
  validate: (result: T) => boolean,
  retries = Infinity,
  timeoutBetweenAttempts = 1000,
) => {
  const attemp = async (): Promise<T> => {
    const result = await getPromise();

    if (validate(result)) {
      return result;
    }

    if (retries > 0) {
      retries--;

      await new Promise((res) => {
        setTimeout(res, timeoutBetweenAttempts);
      });

      const attempResult = await attemp();
      return attempResult;
    }

    throw new Error('Polling failed');
  };

  const result = await attemp();
  return result;
};
