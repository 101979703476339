import * as Sentry from '@sentry/react';
import { AxiosError } from 'axios';

const MODE = import.meta.env.MODE as string;
const BASE_URL = import.meta.env.VITE_API_BASE_URL as string;

type SentryCaptureExeptionParams = Parameters<typeof Sentry.captureException>;

export const ErrorService = {
  init: () => {
    Sentry.init({
      enabled: MODE !== 'dev',
      environment: MODE,
      dsn: 'https://852731d57c6322eba3c2eb25642d9d0c@o4506355201540096.ingest.sentry.io/4506355203178496',
      integrations: [
        new Sentry.BrowserTracing({
          // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
          tracePropagationTargets: [
            'localhost',
            /^https:\/\/.*.ngrok-free.app\/?.*/,
            new RegExp(BASE_URL),
          ],
        }),
        // new Sentry.Replay(),
      ],
      // Performance Monitoring
      tracesSampleRate: 1.0, //  Capture 100% of the transactions

      // beforeSend: (event) => {
      //   console.log('Sentry beforeSend', event);

      //   return event;
      // },

      // Session Replay
      // replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      // replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  },

  captureException: (e: SentryCaptureExeptionParams[0], d?: SentryCaptureExeptionParams[1]) => {
    Sentry.captureException(e, d);

    if (MODE === 'dev') {
      console.error(e);
    }
  },

  captureAxiosError: (error: AxiosError) => {
    let level: Sentry.SeverityLevel = 'error';
    const fingerprint: string[] = [];
    let invalidAuthToken = false;

    if (error.response?.status === 401) {
      level = 'warning';
      invalidAuthToken = true;
      fingerprint.push('invalid-auth-token');
    }

    if (!error.status && error.message === 'Network Error') {
      level = 'warning';
      fingerprint.push('network-error');
    }

    ErrorService.captureException(error, {
      tags: { apiError: true, ...(invalidAuthToken ? { invalidAuthToken } : {}) },
      extra: { apiRequestUrl: `${error.config?.baseURL}/${error.config?.url}` },
      fingerprint: fingerprint.length ? fingerprint : undefined,
      level,
    });
  },
};
