import { Loader, Modal, notify } from '@mindkit';
import { Dayjs } from 'dayjs';
import useMentorManager from 'modules/mentor/hooks/useMentorManager';
import CreateTimeslotForm from 'modules/timeslot/components/CreateTimeslotForm/CreateTimeslotForm';
import React from 'react';

const CreateTimeslotModal: React.FC<{
  isOpen: boolean;
  onClose: () => void;
  initialDate?: Dayjs;
}> = ({ isOpen, onClose, initialDate }) => {
  const { data: mentor, isError, isPending } = useMentorManager();

  const handleTimeslotCreated = () => {
    notify('Slot added', 'success');
  };

  let body;
  if (isError) {
    body = <p className="text-danger">Something went wrong...</p>;
  } else if (isPending) {
    body = (
      <div className="flex h-40 flex-col items-center justify-center">
        <Loader />
      </div>
    );
  } else if (mentor.timeslotTypes.length > 0) {
    body = (
      <CreateTimeslotForm
        timeslotTypes={mentor.timeslotTypes}
        onCreated={handleTimeslotCreated}
        initialDate={initialDate}
      />
    );
  } else {
    body = (
      <p className="">
        You don&apos;t have slot options to create 😞
        <br />
        Please contact us to help you with this.
      </p>
    );
  }

  return (
    <Modal isOpen={isOpen} onClose={onClose} className="w-[380px]">
      {body}
    </Modal>
  );
};

export default CreateTimeslotModal;
