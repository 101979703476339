import { CheckoutPending, CheckoutProcessResult } from 'modules/payment/model';
import { create } from 'zustand';

export interface CheckoutStore {
  isOpen: boolean;
  onClose?: (result: CheckoutProcessResult) => void;

  checkout?: CheckoutPending;
}

export const useCheckoutStore = create<CheckoutStore>((set) => ({
  isOpen: false,
  onClose: undefined,

  checkout: undefined,
}));

export const openCheckoutModal = (payment: CheckoutPending) => {
  return new Promise<CheckoutProcessResult>((resolve) => {
    useCheckoutStore.setState({
      isOpen: true,
      onClose: resolve,

      checkout: payment,
    });
  });
};

export const closeCheckoutModal = (result: CheckoutProcessResult) => {
  const { onClose } = useCheckoutStore.getState();
  onClose?.(result);

  useCheckoutStore.setState({
    isOpen: false,
    onClose: undefined,

    checkout: undefined,
  });
};

export const setCheckout = (checkout: CheckoutPending) => {
  useCheckoutStore.setState({
    checkout,
  });
};
