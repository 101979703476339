import dayjs, { Dayjs } from 'dayjs';
import {
  ManagedTimeslot,
  Timeslot,
  TimeslotCategory,
  TimeslotStatus,
  TimeslotType,
} from 'modules/timeslot/model';

const WEEKS_TO_PRELOAD = 3;

export const getTimeslotFormatted = (
  timeslot: Timeslot | ManagedTimeslot,
  timeslotType: TimeslotType,
) => {
  const date = timeslot.date.format('ddd, MMM D');

  const startTime = timeslot.date.format('hh:mm\xA0A');
  const endTime = timeslot.date.add(timeslotType.duration, 'minute').format('hh:mm\xA0A');
  const time = `${startTime} -\xA0${endTime}`;

  const price = `$${timeslotType.price}`;

  return { date, time, price };
};

export const getTimeslotsPreloadRange = () => {
  const startDate = dayjs().startOf('week');
  const endDate = startDate.add(WEEKS_TO_PRELOAD - 1, 'week').endOf('week');

  return { startDate, endDate };
};

export const isTimeslotTooClose = (timeslot: Timeslot | ManagedTimeslot) => {
  const now = dayjs();
  if (timeslot.category === TimeslotCategory.EVENT) {
    return timeslot.date.diff(now, 'minute') < 15;
  }
  return timeslot.date.diff(now, 'hour') < 24;
};

export const isTimeslotAvailable = (timeslot: Timeslot | ManagedTimeslot) => {
  const now = dayjs();

  return (
    timeslot.status === TimeslotStatus.FREE &&
    timeslot.date.isAfter(now) &&
    !isTimeslotTooClose(timeslot)
  );
};

export const filterWeekTimeslots = (timeslots: Timeslot[], startDate: Dayjs, endDate: Dayjs) => {
  return timeslots
    .filter((timeslot) => {
      return timeslot.date.isBetween(startDate, endDate, 'day', '[]');
    })
    .sort((t1, t2) => (t1.date.isAfter(t2.date) ? 1 : -1));
};
export const groupTimeslotsByDay = <T extends { date: Dayjs }>(timeslots: T[]) => {
  const groups: Array<{ date: Dayjs; timeslots: T[] }> = [];

  timeslots.forEach((timeslot) => {
    const index = groups.findIndex((g) => g.date.isSame(timeslot.date, 'day'));
    if (index !== -1) {
      groups[index].timeslots.push(timeslot);
    } else {
      groups.push({ date: timeslot.date, timeslots: [timeslot] });
    }
  });

  return groups;
};

export const mergeTimeslots = (currentTimeslots: Timeslot[], addedTimeslots: Timeslot[]) => {
  const result = [...currentTimeslots];
  addedTimeslots.forEach((addedTimeslot) => {
    const index = result.findIndex((t) => t.id === addedTimeslot.id);
    if (index !== -1) {
      result[index] = addedTimeslot;
    } else {
      result.push(addedTimeslot);
    }
  });

  return result;
};

export const findNearestAvailableTimeslotDate = (timeslots: Timeslot[]): Dayjs | undefined => {
  const sortedTimeslots = timeslots
    .filter((t) => !isTimeslotTooClose(t) && t.status === TimeslotStatus.FREE)
    .sort((t1, t2) => (t1.date.isAfter(t2.date) ? 1 : -1));

  const timeslot = sortedTimeslots[0];
  if (!timeslot) {
    return undefined;
  }

  return timeslot.date;
};
