import clsx from 'clsx';
import { Mentor } from 'modules/mentor/model';
import React from 'react';

const MentorDetails: React.FC<{ mentor: Mentor; className?: string }> = ({ mentor, className }) => {
  const { profileDetails } = mentor;
  const { booked, returned, expertise, about } = profileDetails;

  const showBooked = Boolean(booked && booked > 0);
  const showReturned = Boolean(returned && returned > 0);

  return showBooked || showReturned || expertise || about ? (
    <div className={clsx('flex flex-col gap-6', className)}>
      {(showBooked || showReturned) && (
        <div className="flex flex-row gap-12">
          {showBooked && (
            <div>
              <h2>{booked}</h2>
              <p>HOURS BOOKED</p>
            </div>
          )}

          {showReturned && (
            <div>
              <h2>{returned}</h2>
              <p>REPEAT RATE</p>
            </div>
          )}
        </div>
      )}

      {expertise && (
        <div>
          <h4>Expertise:</h4>
          <p className="whitespace-pre-wrap">{expertise}</p>
        </div>
      )}

      {about && (
        <div>
          <h4>About:</h4>
          <p className="whitespace-pre-wrap">{about}</p>
        </div>
      )}
    </div>
  ) : null;
};

export default MentorDetails;
