import React from 'react';

const cards = [
  {
    title: 'Clarify Objectives',
    text: 'Clearly define your objectives and what you hope to achieve during the 1:1 session.',
  },
  {
    title: 'Prepare Questions',
    text: 'Prepared questions help maintain a structured and purposeful conversation.',
  },
  {
    title: 'Technical Setup Check',
    text: 'Check your internet connection, test your microphone and camera.',
  },
  {
    title: 'Be Open-Minded and Flexible',
    text: 'This adaptability can lead to unexpected but valuable insights during the call.',
  },
];

const HowToGetReady: React.FC<{ className?: string }> = ({ className }) => {
  return (
    <section className={className}>
      <h2 className="mb-4">How to get ready for 1:1</h2>
      <div className="flex snap-x snap-mandatory flex-row gap-2 overflow-hidden overflow-x-scroll desk:grid desk:grid-cols-2 desk:gap-4">
        {cards.map((card) => {
          return (
            <div
              key={card.title}
              className="w-11/12 max-w-sm shrink-0 snap-start rounded-3xl bg-level2 p-5 desk:w-auto desk:bg-level1"
            >
              <h4 className="mb-1">{card.title}</h4>
              <p>{card.text}</p>
            </div>
          );
        })}
      </div>
    </section>
  );
};

export default HowToGetReady;
