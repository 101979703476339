import { IfScreen } from '@mindkit';
import clsx from 'clsx';
import React, { isValidElement } from 'react';

const PageLayout: React.FC<{
  body?: React.ReactNode;
  mobileBody?: React.ReactNode;
  deskBody?: React.ReactNode;
  deskSidebar?: React.ReactNode;

  bodyClassName?: string;
  mobileBodyClassName?: string;
  deskBodyClassName?: string;
  sidebarClassName?: string;

  footer?: React.ReactNode;
  footerClassName?: string;
}> = ({
  body,
  mobileBody,
  deskBody,
  deskSidebar,
  bodyClassName,
  mobileBodyClassName,
  deskBodyClassName,
  sidebarClassName,
  footer,
  footerClassName,
}) => {
  const withSidebar = Boolean(isValidElement(deskSidebar));

  const footerBlock = footer && (
    <footer
      className={clsx(
        'flex flex-col items-center border-t border-disabled bg-level2 px-5 py-8',
        footerClassName,
      )}
    >
      {footer}
    </footer>
  );

  return (
    <IfScreen
      mobile={
        <>
          <main
            className={clsx(
              'flex flex-grow flex-col px-5 py-5',
              bodyClassName,
              mobileBodyClassName,
            )}
          >
            {mobileBody ?? body}
          </main>
          {footerBlock}
        </>
      }
      desktop={
        withSidebar ? (
          <>
            <div
              className={clsx(
                'mx-auto my-6 flex w-[1024px] max-w-screen-desk flex-grow flex-row justify-center gap-6',
              )}
            >
              <main
                className={clsx(
                  'flex flex-grow flex-col overflow-x-auto',
                  bodyClassName,
                  deskBodyClassName,
                )}
              >
                {deskBody ?? body}
              </main>

              {deskSidebar && (
                <aside
                  className={clsx(
                    'flex w-full max-w-page-sidebar flex-shrink-0 flex-col empty:hidden',
                    sidebarClassName,
                  )}
                >
                  {deskSidebar}
                </aside>
              )}
            </div>
            {footerBlock}
          </>
        ) : (
          <>
            <main
              className={clsx(
                'mx-auto my-6 flex w-[1024px] max-w-screen-desk flex-grow flex-col',
                bodyClassName,
                deskBodyClassName,
              )}
            >
              {deskBody ?? body}
            </main>
            {footerBlock}
          </>
        )
      }
    />
  );
};

export default PageLayout;
