import { useQuery } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import useAuth from 'modules/auth/hooks/useAuth';
import { Mentor } from 'modules/mentor/model';
import { timeslotActions } from 'modules/timeslot';
import { Timeslot } from 'modules/timeslot/model';
import { QueryService } from 'services/query';

interface TimeslotQueryData {
  timeslots: Timeslot[];
  range: { start: Dayjs; end: Dayjs };
}

const getInitialDataFromMentor = (
  mentorId: string,
  startDate: Dayjs,
  endDate: Dayjs,
  isAuth: boolean,
): TimeslotQueryData | undefined => {
  const mentor = QueryService.getClient().getQueryData<Mentor>([
    'mentor',
    mentorId,
    'auth',
    isAuth,
  ]);
  if (!mentor) {
    return undefined;
  }

  const timeslots = mentor.timeslots.filter((t) =>
    t.date.isBetween(startDate, endDate, 'day', '[]'),
  );

  return { timeslots, range: { start: startDate, end: endDate } };
};

const useTimeslots = (mentorId: string, startDate: Dayjs, endDate: Dayjs) => {
  const { isAuth } = useAuth();

  const dateKey = `${startDate.format('YYYY-MM-DD')}-${endDate.format('YYYY-MM-DD')}`;

  return useQuery<TimeslotQueryData>({
    queryKey: ['timeslots', 'mentor', mentorId, dateKey, 'auth', isAuth],
    queryFn: async () => {
      const timeslots = await timeslotActions.getTimeslots(mentorId, startDate, endDate);

      return { timeslots, range: { start: startDate, end: endDate } };
    },
    initialData: getInitialDataFromMentor(mentorId, startDate, endDate, isAuth),
  });
};

export default useTimeslots;
