import { PlatformType } from 'modules/general/model';
import { ApiService } from 'services/api';

import {
  checkoutAdapter,
  paymentLinkAdapter,
  unfinishedCheckoutAdapter,
} from './paymentApiAdapters';
import {
  CheckoutPaidResponse,
  CheckoutResponse,
  CreateCheckoutRequest,
  PaymentLinkRequest,
  PaymentLinkResponse,
  UnfinishedCheckoutResponse,
} from './paymentApiInterfaces';

export const createCheckout = async (data: CreateCheckoutRequest, isAuth: boolean) => {
  const response = await ApiService.apiInstance.post<CheckoutResponse>(
    isAuth ? 'payments/create-for' : 'payments/create',
    data,
  );

  return checkoutAdapter(response.data);
};

export const getCheckout = async (checkoutId: string, platform: PlatformType) => {
  const response = await ApiService.apiInstance.get<CheckoutResponse>(
    `payments/checkout/${checkoutId}`,
    { params: { platform } },
  );

  return checkoutAdapter(response.data);
};

export const renewCheckout = async (checkoutId: string, platform: PlatformType) => {
  const response = await ApiService.apiInstance.get<CheckoutResponse>(
    `payments/checkout/${checkoutId}/renew`,
    { params: { platform } },
  );

  return checkoutAdapter(response.data);
};

export const generatePaymentLink = async (data: PaymentLinkRequest) => {
  const response = await ApiService.apiInstance.post<PaymentLinkResponse>('payments/session', data);

  return paymentLinkAdapter(response.data);
};

export const payWithPackage = async (checkoutId: string, userPackageId: string) => {
  // ToDo: Check the response from backend, it should contain the full data
  await ApiService.apiInstance.post<CheckoutPaidResponse>(`payments/${checkoutId}/redeem-package`, {
    userPackageId,
  });
};

export const payFree = async (checkoutId: string) => {
  await ApiService.apiInstance.get<CheckoutPaidResponse>(`payments/${checkoutId}/redeem-free`);
};

export const getUnfinishedCheckout = async (mentorId?: string) => {
  const response = await ApiService.apiInstance.get<{
    checkout: UnfinishedCheckoutResponse | null;
  }>('payments/checkout/continue-payment', { params: { mentor: mentorId } });

  const { checkout } = response.data;

  return checkout ? unfinishedCheckoutAdapter(checkout) : undefined;
};

export const cancelCheckout = async (checkoutId: string) => {
  await ApiService.apiInstance.post(`payments/${checkoutId}/cancel`);
};

export const applyCoupon = async (
  checkoutId: string,
  couponCode: string,
  platform: PlatformType,
  timezone: string,
  email?: string,
) => {
  const response = await ApiService.apiInstance.post<CheckoutResponse>(
    `payments/${checkoutId}/apply-coupon`,
    {
      couponCode,
      platform,
      timezone,
      email,
    },
  );

  const checkout = checkoutAdapter(response.data);
  if (checkout.status !== 'Pending') {
    throw new Error('Checkout is not pending');
  }

  return checkout;
};
