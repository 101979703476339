import { notify } from '@mindkit';
import copyToClipboard from 'shared/helpers/copyToClipboard';

const copy = async (text: string) => {
  if (!text) {
    notify('Nothing to copy', 'error');
    return;
  }

  try {
    await copyToClipboard(text);
    notify('Copied', 'success');
  } catch {
    notify('Failed to copy', 'error');
  }
};

export default copy;
