import { referralActions } from 'modules/referral';
import React, { useEffect, useRef } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { TelegramService } from 'services/telegram';

const GlobalSearchParamsHandler: React.FC = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  // Save referral on the first render
  const ref = useRef(true);
  if (ref.current) {
    ref.current = false;

    const referral =
      searchParams.get('referral') || TelegramService.getSearchParams().get('referral');
    if (referral) {
      referralActions.setReferral(referral);
    }
  }

  useEffect(() => {
    const tgSearchParams = TelegramService.getSearchParams();
    if (!tgSearchParams.toString()) {
      return;
    }

    const updatedSearchParams = new URLSearchParams(searchParams.toString());
    Array.from(tgSearchParams.entries()).forEach(([key, value]) => {
      updatedSearchParams.set(key, value);
    });
    setSearchParams(updatedSearchParams);

    const mentorId = tgSearchParams.get('mentorId');
    if (mentorId) {
      navigate(`/mentor/${mentorId}?${updatedSearchParams.toString()}`);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return null;
};

export default GlobalSearchParamsHandler;
