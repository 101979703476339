import { ApiService } from 'services/api';

import {
  mentorAdapter,
  mentorCatalogAdapter,
  mentorManagerAdapter,
  mentorManagerIntegrationAdapter,
} from './mentorApiAdapters';
import {
  MentorCatalogResponse,
  MentorIntegrationResponse,
  MentorManagerResponse,
  MentorResponse,
} from './mentorApiInterfaces';

export const getMentor = async (mentorId: string, startDate?: string, endDate?: string) => {
  const response = await ApiService.apiInstance.get<MentorResponse>(`mentors/${mentorId}`, {
    params: {
      startDate,
      endDate,
    },
  });

  return mentorAdapter(response.data);
};

export const getMentorManager = async () => {
  const response = await ApiService.apiInstance.get<MentorManagerResponse>('mentors/me');

  return mentorManagerAdapter(response.data);
};

export const getMentorCatalog = async () => {
  const response = await ApiService.apiInstance.get<MentorCatalogResponse>('mentors/catalog');

  return mentorCatalogAdapter(response.data);
};

export const updateMentorManager = async (data: {
  name?: string;
  description?: string;
  expertise?: string;
  about?: string;
  photoUrl?: string;
}) => {
  const response = await ApiService.apiInstance.patch<MentorManagerResponse>('mentors/me', data);

  return mentorManagerAdapter(response.data);
};

export const uploadMentorAvatar = async (file: File) => {
  const formData = new FormData();
  formData.append('file', file);
  const response = await ApiService.apiInstance.post<{ fileUrl: string }>(
    'mentors/me/avatar',
    formData,
  );

  return response.data;
};

export const revokeMentorManagerIntegration = async (type: 'zoom' | 'calendar') => {
  const response = await ApiService.apiInstance.post<MentorIntegrationResponse>(
    `mentors/me/revoke`,
    { type },
  );

  return mentorManagerIntegrationAdapter(response.data);
};
