export interface HapticService {
  selection(): void;
  notification(type: 'success' | 'warning' | 'error'): void;
}

let hapticService: HapticService | null;

export const haptic = {
  setImplementation: (service: HapticService | null) => {
    hapticService = service;
  },
  selection: () => hapticService?.selection(),
  notification: (type: 'success' | 'warning' | 'error') => hapticService?.notification(type),
};
