import { haptic } from '@mindkit';
import { Mentor } from 'modules/mentor/model';
import { useState } from 'react';

import MentorDetailsModal from '../MentorDetailsModal/MentorDetailsModal';

const MentorDetailsButton: React.FC<{ mentor: Mentor; children: React.ReactNode }> = ({
  mentor,
  children,
}) => {
  const [isMentorDetailsModalOpen, setIsMentorDetailsModalOpen] = useState(false);
  const handleOpenMentorDetailsModal = () => {
    haptic.selection();
    setIsMentorDetailsModalOpen(true);
  };
  const handleClosedMentorDetailsModal = () => {
    setIsMentorDetailsModalOpen(false);
  };

  return (
    <>
      <MentorDetailsModal
        mentor={mentor}
        isOpen={isMentorDetailsModalOpen}
        onClose={handleClosedMentorDetailsModal}
      />
      <button
        type="button"
        onClick={handleOpenMentorDetailsModal}
        className="text-left"
        aria-label="Open mentor details"
      >
        {children}
      </button>
    </>
  );
};

export default MentorDetailsButton;
