import { Modal } from '@mindkit';
import MentorPackItem from 'modules/pack/components/MentorPackItem/MentorPackItem';
import { MentorPack } from 'modules/pack/model';

const MentorPacksModal: React.FC<{
  packs: MentorPack[];
  isOpen: boolean;
  onClose: () => void;
}> = ({ packs, isOpen, onClose }) => {
  const mentorName = packs[0]?.mentor.displayName ?? '';

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <h2 className="mb-4">{mentorName} Packs</h2>

      <div className="flex flex-col gap-3">
        {packs.map((mentorPack) => (
          <MentorPackItem key={mentorPack.id} pack={mentorPack} />
        ))}
      </div>
    </Modal>
  );
};
export default MentorPacksModal;
