import { ApiService } from 'services/api';

import { TelegramAuthRequest } from './authInterfaces';

export const authViaTelegram = async (authData: TelegramAuthRequest) => {
  const response = await ApiService.apiInstance.post<{ accessToken: string; userId: string }>(
    'auth/telegram',
    authData,
  );

  return response.data;
};

export const authViaCode = async (code: string, timezone: string) => {
  const response = await ApiService.apiInstance.post<{ accessToken: string; userId: string }>(
    'auth/code',
    {
      code,
      timezone,
    },
  );

  return response.data;
};

export const sendAuthEmail = async (
  email: string,
  timezone: string,
  redirect?: string,
  mentorId?: string,
) => {
  await ApiService.apiInstance.post('auth/login', { email, timezone, redirect, mentorId });
};
