import { create } from 'zustand';

type From = 'user_menu' | 'page_header';

export interface BetaQuestionnaireStore {
  isOpen: boolean;
  from: From | null;
}

export const useQuestionnaireModalStore = create<BetaQuestionnaireStore>((set) => ({
  isOpen: false,
  from: null,
}));

export const openQuestionnaireModal = (from: From) => {
  useQuestionnaireModalStore.setState({ isOpen: true, from });
};

export const closeQuestionnaireModal = () => {
  useQuestionnaireModalStore.setState({ isOpen: false, from: null });
};
