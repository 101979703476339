import { Timeslot } from 'modules/timeslot/model';
import { ApiService } from 'services/api';

import { managedTimeslotAdapter, timeslotAdapter } from './timeslotApiAdapters';
import { ManagedTimeslotResponse, TimeslotResponse } from './timeslotApiInterfaces';

export const getTimeslotsByMentorId = async (
  mentorId: string,
  startDate: string,
  endDate: string,
): Promise<Timeslot[]> => {
  const response = await ApiService.apiInstance.get<TimeslotResponse[]>('timeslots', {
    params: { mentorId, startDate, endDate },
  });

  return response.data.map((timeslot) => timeslotAdapter(timeslot));
};

export const getManagedTimeslots = async (startDate: string, endDate: string) => {
  const response = await ApiService.apiInstance.get<ManagedTimeslotResponse[]>(
    'timeslots/managed',
    {
      params: { startDate, endDate },
    },
  );

  return response.data.map((timeslot) => managedTimeslotAdapter(timeslot));
};

export const createTimeslot = async (dateTime: string, timeslotTypeId: number) => {
  const response = await ApiService.apiInstance.put<{
    errors: { dateTime: string; message: string }[];
    timeslots: ManagedTimeslotResponse[];
  }>('/timeslots/create', {
    timeslots: [{ dateTime, typeIds: [timeslotTypeId] }],
  });

  if (response.data.timeslots.length === 0) {
    throw new Error(response.data.errors[0]?.message || 'Failed to create timeslot');
  }

  const timeslot = response.data.timeslots[0];
  return managedTimeslotAdapter(timeslot);
};

export const deleteTimeslot = async (timeslotId: string) => {
  await ApiService.apiInstance.delete(`/timeslots/${timeslotId}`);
};

export const getMentorEvents = async (mentorId: string) => {
  const response = await ApiService.apiInstance.get<TimeslotResponse[]>(
    'timeslots/events/by-mentor',
    {
      params: { mentor: mentorId },
    },
  );

  return response.data.map((timeslot) => timeslotAdapter(timeslot));
};
