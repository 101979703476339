import { useMergeRefs } from '@floating-ui/react';
import React, { useCallback } from 'react';

import { usePopoverContext } from '../Popover/PopoverContext';

const CloseOnClick = React.forwardRef<HTMLElement, React.HTMLProps<HTMLElement>>(
  function CloseOnClick({ children, ...props }, propRef) {
    const { setOpen } = usePopoverContext();

    const childrenRef = (children as any).ref;
    const ref = useMergeRefs([propRef, childrenRef]);

    if (!React.isValidElement(children)) {
      throw new Error('PopoverClose requires a valid React element as a child');
    }

    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLElement>) => {
        const isDropdownItem = Boolean((e.target as HTMLElement)?.closest('.dropdown-item'));
        if (isDropdownItem) {
          setOpen?.(false);
        }
        children.props.onClick?.(e);
      },
      [children.props, setOpen],
    );

    return React.cloneElement(children, { ...children.props, ...props, ref, onClick: handleClick });
  },
);

export default CloseOnClick;
