import clsx from 'clsx';
import React, { useCallback } from 'react';

import { haptic } from '../../haptic';
import { useColorTheme } from '../../theme/ColorTheme';
import Loader from '../Loader/Loader';
import cm from './Button.module.css';

type Size = 'sm' | 'md';
type Variant = 'solid' | 'outlined' | 'light';
type Color = 'primary' | 'primary-inverse' | 'danger';

const SizeVariants = {
  md: 'h-12 px-5 text-sm desk:text-base',
  sm: 'h-10 px-5 text-xs desk:text-sm',
};

const LightSizeVariants = {
  md: 'h-10 text-sm desk:text-base',
  sm: 'h-8 text-xs desk:text-sm',
};

const ButtonVariants = {
  solid: {
    default: (size: Size, disabled: boolean) => clsx(SizeVariants[size], 'rounded-2xl'),

    primary: (size: Size, disabled: boolean) => ({
      [cm.primaryHoverShadow]: !disabled,
      'border border-primary bg-primary text-primary-foreground active:bg-primary-active':
        !disabled,
      'bg-disabled text-disabled-foreground': disabled,
    }),

    'primary-inverse': () => '',

    danger: () => '',
  },
  outlined: {
    default: (size: Size, disabled: boolean) => clsx(SizeVariants[size], 'rounded-2xl'),

    primary: (size: Size, disabled: boolean) => ({
      [cm.primaryHoverShadow]: !disabled,
      'border border-primary text-primary active:bg-primary-surface': !disabled,
      'border border-disabled text-disabled': disabled,
    }),

    'primary-inverse': (size: Size, disabled: boolean) => ({
      [cm.primaryInverseHoverShadow]: !disabled,
      'border border-white text-white active:bg-white/20': !disabled,
      'border border-white/60 text-white/60': disabled,
    }),

    danger: () => '',
  },
  light: {
    default: (size: Size, disabled: boolean) => clsx(LightSizeVariants[size]),

    primary: (size: Size, disabled: boolean) => ({
      [cm.primaryHoverTextShadow]: !disabled,
      'text-primary active:text-primary-active': !disabled,
      'text-disabled': disabled,
    }),

    'primary-inverse': () => '',

    danger: (size: Size, disabled: boolean) => ({
      [cm.dangerHoverTextShadow]: !disabled,
      'text-danger': !disabled,
      'text-disabled': disabled,
    }),
  },
};

export interface ButtonProps {
  isDisabled?: boolean;
  isLoading?: boolean;
  children?: React.ReactNode;
  className?: string;
  variant?: Variant;
  color?: Color | `${Color} dark:${Color}`;
  size?: Size;
  haptic?: 'default' | 'success' | 'warning' | 'error' | 'none';
  capitalize?: boolean;
}

type RefProp<Tag extends React.ElementType> = React.ComponentPropsWithRef<Tag>['ref'];

type PolimorphProps<Tag extends React.ElementType> = {
  as?: Tag;
  children?: React.ReactNode;
  ref?: RefProp<Tag>;
} & React.ComponentPropsWithoutRef<Tag>;

const ButtonInner = <Tag extends React.ElementType = 'button'>(
  props: PolimorphProps<Tag> & ButtonProps,
  ref: RefProp<Tag>,
) => {
  const {
    isDisabled = false,
    onClick,
    className,
    isLoading,
    variant = 'solid',
    color: colorProp = 'primary',
    size = 'md',
    haptic: hapticType = 'default',
    capitalize = true,

    as: Component = 'button',
    children,

    ...other
  } = props;

  let color: Color;
  const { isDark } = useColorTheme();
  if (colorProp.includes(' dark:')) {
    const [light, dark] = colorProp.split(' dark:');
    color = isDark ? (dark as Color) : (light as Color);
  } else {
    color = colorProp as Color;
  }

  const handleClick = useCallback(
    (e: React.MouseEvent<HTMLElement>) => {
      if (hapticType === 'default') {
        haptic.selection();
      } else if (hapticType === 'success' || hapticType === 'warning' || hapticType === 'error') {
        haptic.notification(hapticType);
      }

      onClick?.(e);
    },
    [onClick, hapticType],
  );

  const classes = clsx(
    'transition-shadows relative duration-200 ease-out inline-flex items-center justify-center',

    { 'cursor-default pointer-events-none': isDisabled },

    ButtonVariants[variant].default(size, isDisabled),
    ButtonVariants[variant][color]?.(size, isDisabled),

    { capitalize },

    className,
  );

  const body = isLoading ? (
    <Loader sm className="absolute left-1/2 top-1/2 -translate-x-1/2 -translate-y-1/2" />
  ) : (
    children
  );

  const customProps: Record<string, unknown> = {};
  if (Component === 'a') {
    customProps.rel = 'noreferrer';
  } else if (Component === 'button') {
    customProps.type = 'button';
  }

  return (
    <Component
      className={classes}
      disabled={isDisabled}
      onClick={handleClick}
      {...customProps}
      {...other}
      ref={ref}
    >
      {body}
    </Component>
  );
};

const Button = React.forwardRef(ButtonInner) as typeof ButtonInner;

export default Button;
