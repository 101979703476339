import { useQuery } from '@tanstack/react-query';
import useAuth from 'modules/auth/hooks/useAuth';
import { paymentActions } from 'modules/payment';
import { UnfinishedCheckout } from 'modules/payment/model';

const useUnfinishedCheckout = (mentorId?: string) => {
  const { isAuth } = useAuth();

  return useQuery<UnfinishedCheckout | null>({
    queryKey: ['unfinishedCheckout', 'mentor', mentorId],
    queryFn: async () => {
      const unfinishedCheckout = await paymentActions.getUnfinishedCheckout(mentorId);
      return unfinishedCheckout || null;
    },
    enabled: isAuth,
  });
};

export default useUnfinishedCheckout;
