import { recordingApi } from 'modules/recording/api';

export const getUserRecordings = async () => {
  const recordings = await recordingApi.getUserRecordings();

  return recordings;
};

export const getMentorRecordings = async (mentorId: string) => {
  const recordings = await recordingApi.getMentorRecordings(mentorId);

  return recordings;
};
