import { notify } from '@mindkit';
import { IconTrash } from '@mindkit/icons';
import clsx from 'clsx';
import { timeslotActions } from 'modules/timeslot';
import { ManagedTimeslot, timeslotHelpers, TimeslotStatus } from 'modules/timeslot/model';
import React from 'react';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const TimeslotItem: React.FC<{ timeslot: ManagedTimeslot }> = ({ timeslot }) => {
  const { time, price } = timeslotHelpers.getTimeslotFormatted(timeslot, timeslot.types[0]);

  const isBooked = timeslot.status === TimeslotStatus.BOOKED;

  const [isDeleting, setIsDeleting] = React.useState(false);
  const handleDelete = async () => {
    setIsDeleting(true);
    try {
      await timeslotActions.deleteTimeslot(timeslot);
    } catch (e) {
      notify(getErrorMessage(e), 'error');

      throw e;
    } finally {
      setIsDeleting(false);
    }
  };

  return (
    <div
      className={clsx('group flex max-w-sm flex-row items-center rounded-3xl border px-5 py-5', {
        'border-disabled text-disabled': isBooked,
      })}
    >
      <p>{time}</p>
      <p className="ml-1">({price})</p>

      <div className="flex-grow" />
      {isBooked && (
        <p className={clsx('rounded-full bg-disabled px-2 py-1 text-sm text-disabled-foreground')}>
          Booked
        </p>
      )}
      {!isBooked && (
        <button
          type="button"
          className="shrink-0 transition-all hover:text-danger group-hover:block desk:hidden"
          aria-label="Remove"
          onClick={handleDelete}
          disabled={isDeleting}
        >
          <IconTrash className="" />
        </button>
      )}
    </div>
  );
};

export default TimeslotItem;
