import { Dayjs } from 'dayjs';

export interface Week {
  id: string;
  start: Dayjs;
  end: Dayjs;
}

export const getWeek = (day: Dayjs) => {
  const start = day.startOf('week');
  const end = day.endOf('week');

  return { id: `${start.format('YYYY-MM-DD')}--${end.format('YYYY-MM-DD')}`, start, end };
};

export const getWeeks = (from: Dayjs, count: number) => {
  const weeks: Week[] = [];

  for (let i = 0; i < count; i++) {
    const currentDay = from.add(i, 'week');
    const week = getWeek(currentDay);
    weeks.push(week);
  }

  return weeks;
};
