import { Button, Loader } from '@mindkit';
import { isAxiosError } from 'axios';
import { getPlatform } from 'modules/general/actions/generalActions';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import { paymentActions } from 'modules/payment';
import CheckoutItemResult from 'modules/payment/components/CheckoutItemResult/CheckoutItemResult';
import { CheckoutFailed, CheckoutPaid } from 'modules/payment/model';
import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { ErrorService } from 'services/error';
import { SUPPORT_EMAIL } from 'shared/constants/supportEmail';
import { poll } from 'shared/helpers/polling';

const CheckoutResultPage: React.FC = () => {
  const { checkoutId } = useParams();
  if (!checkoutId) {
    throw new Error('Unable to get checkoutId from URL');
  }

  const [checkout, setCheckout] = useState<CheckoutPaid | CheckoutFailed | null>(null);
  const [isError, setIsError] = useState(false);
  const [isExpired, setIsExpired] = useState(false);

  const fetchCheckout = async (checkoutId: string) => {
    try {
      const checkout = (await poll(
        () => paymentActions.getCheckout(checkoutId, getPlatform()),

        (checkout) => {
          return checkout.status !== 'Pending';
        },
        20,
        1000,
      )) as CheckoutPaid | CheckoutFailed;

      setCheckout(checkout);
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 401) {
        setIsExpired(true);
        return;
      }

      ErrorService.captureException(e);
      setIsError(true);
    }
  };

  useEffect(() => {
    setTimeout(async () => {
      fetchCheckout(checkoutId);
    }, 5000); // Delay to allow the payment to be fully processed
  }, [checkoutId]);

  if (isError && !checkout) {
    return <p className="m-auto text-danger">Something went wrong ...</p>;
  }

  if (isExpired && !checkout) {
    return (
      <div className="flex flex-grow flex-col items-center justify-center">
        <h2>Ooops!</h2>
        <p className="mt-2 text-center">
          Link has expired.
          <br />
          Please Sign-in to see checkout details.
        </p>
      </div>
    );
  }

  if (!checkout) {
    return <Loader className="m-auto" />;
  }

  if (checkout.status === 'Failed') {
    return (
      <div className="m-auto flex flex-col">
        <h2 className="text-danger">Checkout failed 😵</h2>
        <Button as="a" href={`mailto:${SUPPORT_EMAIL}`} target="_blank" variant="light">
          CONTACT SUPPORT
        </Button>
      </div>
    );
  }

  return (
    <PageLayout
      mobileBodyClassName="!p-0 !m-0"
      body={
        <CheckoutItemResult mentorId={checkout.mentorId} checkoutResult={checkout.checkoutResult} />
      }
    />
  );
};

export default CheckoutResultPage;
