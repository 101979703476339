import { IconPackDiamond, IconPackGift } from '@mindkit/icons';

import {
  PackColorPreset,
  PackColorPresetType,
  PackIcon,
  PackIconPresetType,
} from './packStyleInterfaces';

const packColorPresets: Record<PackColorPresetType, PackColorPreset> = {
  [PackColorPresetType.BLACK]: {
    bgColorStart: '#473b3b',
    bgColorEnd: '#000000',
  },
  [PackColorPresetType.BLUE]: {
    bgColorStart: '#45b6ff',
    bgColorEnd: '#1a3eff',
  },
  [PackColorPresetType.PURPLE]: {
    bgColorStart: '#906bf3',
    bgColorEnd: '#5f4adf',
  },
  [PackColorPresetType.MINT]: {
    bgColorStart: '#73d6de',
    bgColorEnd: '#0b9fce',
  },
};

const packIconPresets: Record<PackIconPresetType, PackIcon> = {
  [PackIconPresetType.GIFT]: IconPackGift,
  [PackIconPresetType.DIAMOND]: IconPackDiamond,
};

const getPackColorPreset = (color: PackColorPresetType): PackColorPreset => {
  return packColorPresets[color];
};

const getPackIconPreset = (icon: PackIconPresetType): PackIcon => {
  return packIconPresets[icon];
};

export const getPackStylePreset = (color: PackColorPresetType, icon: PackIconPresetType) => {
  return {
    colorPreset: getPackColorPreset(color),
    IconPreset: getPackIconPreset(icon),
  };
};
