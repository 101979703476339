import { Dayjs } from 'dayjs';
import * as mentorApi from 'modules/mentor/api';
import { MentorIntegration, MentorManager } from 'modules/mentor/model';
import { QueryService } from 'services/query';

export const getMentor = async (
  mentorId: string,
  timeslotRange?: { startDate: Dayjs; endDate: Dayjs },
) => {
  if (timeslotRange) {
    return mentorApi.getMentor(
      mentorId,
      timeslotRange.startDate.subtract(1, 'day').utc().format('YYYY-MM-DD'),
      timeslotRange.endDate.add(1, 'day').utc().format('YYYY-MM-DD'),
    );
  }

  return mentorApi.getMentor(mentorId);
};

export const getMentorManager = async () => {
  return mentorApi.getMentorManager();
};

export const prefetchMentorCatalog = async () => {
  await QueryService.getClient().prefetchQuery({
    queryKey: ['mentorCatalog'],
    queryFn: mentorApi.getMentorCatalog,
  });
};

export const invalidateMentor = (mentorId: string) => {
  QueryService.getClient().invalidateQueries({ queryKey: ['mentor', mentorId] });
};

const handleMentorUpdated = (mentor: MentorManager) => {
  QueryService.getClient().setQueryData(['mentorManager'], mentor);
};

export const updateMentorManager = async (data: {
  name?: string;
  description?: string;
  expertise?: string;
  about?: string;
  photoUrl?: string;
}) => {
  const mentor = await mentorApi.updateMentorManager(data);
  handleMentorUpdated(mentor);

  return mentor;
};

export const updateMentorAvatar = async (file: File) => {
  return mentorApi.uploadMentorAvatar(file);
};

const handleMentorManagerIntegrationUpdated = (integration: MentorIntegration) => {
  QueryService.getClient().setQueryData(['mentorManager'], (manager: MentorManager) => {
    if (!manager) {
      return manager;
    }
    const updatedManager = { ...manager };
    updatedManager.integrations = updatedManager.integrations.map((int) => {
      if (int.type === integration.type) {
        return integration;
      }

      return int;
    });

    return updatedManager;
  });
};

export const revokeMentorManagerIntegration = async (type: 'zoom' | 'calendar') => {
  const integration = await mentorApi.revokeMentorManagerIntegration(type);
  handleMentorManagerIntegrationUpdated(integration);
};
