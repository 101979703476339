import { Loader, notify } from '@mindkit';
import { IconCamera } from '@mindkit/icons';
import clsx from 'clsx';
import { mentorActions } from 'modules/mentor';
import React from 'react';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const MAX_SIZE = 5 * 1024 * 1024; // 5MB

type Props = React.ComponentProps<'input'> & {
  avatarUrl?: string;
  onChange: (avatarUrl: string) => void;
  className?: string;
};

const Avatar = React.forwardRef<HTMLInputElement, Props>(
  ({ avatarUrl, onChange, className, ...other }, ref) => {
    const [isUploading, setIsUploading] = React.useState(false);
    const [previewUrl, setPreviewUrl] = React.useState(avatarUrl);
    const hasPreview = Boolean(previewUrl);

    const handleFileSelected = async (event: React.ChangeEvent<HTMLInputElement>) => {
      const file = event.target.files?.[0];
      if (!file) {
        return;
      }

      if (file.size > MAX_SIZE) {
        notify('File size should be less than 5MB', 'error');
        return;
      }

      setIsUploading(true);
      try {
        const { fileUrl } = await mentorActions.updateMentorAvatar(file);
        setPreviewUrl(fileUrl);

        onChange(fileUrl);
      } catch (e) {
        notify(getErrorMessage(e), 'error');
      } finally {
        setIsUploading(false);
      }
    };

    return (
      <div className={className}>
        <label
          className={clsx(
            'group relative flex h-24 w-24 cursor-pointer flex-col items-center justify-center rounded-full',
            { 'bg-disabled': !hasPreview },
          )}
          htmlFor="photoUrl"
        >
          {hasPreview && (
            <img
              src={previewUrl}
              alt="avatar"
              className="block h-full w-full rounded-full object-cover"
            />
          )}
          {!hasPreview && !isUploading && <IconCamera />}

          {hasPreview && !isUploading && (
            <div className="absolute inset-0 flex flex-col items-center justify-center rounded-full bg-black/60 opacity-0 transition-all group-hover:opacity-100">
              <IconCamera className="text-primary-foreground" />
            </div>
          )}

          {isUploading && <Loader className="absolute top-1/2 -translate-y-1/2" sm />}

          <input
            {...other}
            ref={ref}
            id="photoUrl"
            className="hidden"
            type="file"
            aria-label="Select avatar image"
            accept=".png,.jpg,.webm"
            onChange={handleFileSelected}
            value=""
          />
        </label>
      </div>
    );
  },
);
Avatar.displayName = 'Avatar';

export default Avatar;
