import { Booking } from 'modules/booking/model';
import { MentorPack, UserPack } from 'modules/pack/model';
import { NotOwnedRecording, Recording } from 'modules/recording/model';
import { Timeslot } from 'modules/timeslot/model';

export enum PaymentMethod {
  STRIPE = 'Stripe',
  RU_CARD = 'RuCard',
  PACKAGE = 'Package',
  FREE = 'Free',
}

export enum PaymentStatus {
  PENDING = 'Pending',
  APPROVED = 'Approved',
  REVERTED = 'Reverted',
  REFUNDED = 'Refunded',
}

export type CheckoutProcessResult = 'redirect' | 'paid' | 'cancelled' | 'failed';

type CheckoutRequest =
  | {
      type: 'timeslot';
      timeslotTypeId: number;
      timeslot: Timeslot;
    }
  | {
      type: 'package';
      pack: MentorPack;
    }
  | {
      type: 'recording';
      recording: NotOwnedRecording;
    }
  | {
      type: 'free';
    };

type CheckoutResult =
  | {
      type: 'booking';
      booking: Booking;
    }
  | {
      type: 'userPackage';
      pack: UserPack;
    }
  | {
      type: 'recording';
      recording: Recording;
    };

export type PaymentOption =
  | {
      method: PaymentMethod.RU_CARD;
    }
  | {
      method: PaymentMethod.STRIPE;
    }
  | {
      method: PaymentMethod.PACKAGE;
      title: string;
      productId: string;
    }
  | {
      method: PaymentMethod.FREE;
    };

export interface CheckoutPending {
  id: string;
  status: 'Pending';

  emailRequired: boolean;
  suggestedEmail?: string;

  policyRequired: boolean;

  paymentOptions: PaymentOption[];

  checkoutRequest: CheckoutRequest;

  mentorId: string;

  priceSummary: {
    total: number;
    currency: string;
    tax: number;
    discount: number;
    subtotal: number;
  };

  aggregatedInfo: {
    checkoutId: string;
    mentorId?: string;
    productId: string;
    productType: string;
  };

  coupons: Coupon[];
  selectedCoupon?: Coupon;
}

export interface CheckoutPaid {
  id: string;
  status: 'Paid';

  checkoutResult: CheckoutResult;

  mentorId: string;

  priceSummary: {
    total: number;
    currency: string;
    tax: number;
    discount: number;
    subtotal: number;
  };
}

export interface CheckoutFailed {
  id: string;
  status: 'Failed';
}

export type Checkout = CheckoutPending | CheckoutPaid | CheckoutFailed;

export interface PaymentLink {
  type: PaymentMethod;
  url: string;
  expiry: number;
}

export interface UnfinishedCheckout {
  id: string;

  checkoutRequest: CheckoutRequest;
}

export interface Coupon {
  id: string;
  type: 'percentage' | 'amount';
  code: string;
  description: string;
  value: number;
}
