import { dropAuth } from 'modules/auth/actions/authActions';
import useAuth from 'modules/auth/hooks/useAuth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

const SignOutPage: React.FC = () => {
  const navigate = useNavigate();

  const { isAuth } = useAuth();

  useEffect(() => {
    dropAuth();
  }, []);

  useEffect(() => {
    if (!isAuth) {
      window.open('/', '_self');
    }
  }, [isAuth, navigate]);

  return <div>Redirect...</div>;
};

export default SignOutPage;
