import dayjs from 'dayjs';
import { MentorPack, UserPack } from 'modules/pack/model';

import { MentorPackResponse, UserPackResponse } from './packApiInterfaces';

export const mentorPackAdapter = (mentorPackageResponse: MentorPackResponse): MentorPack => {
  return {
    id: mentorPackageResponse.id,

    name: mentorPackageResponse.name,
    description: mentorPackageResponse.description,

    numberOfSessions: mentorPackageResponse.numberOfSessions ?? undefined,

    listPriority: mentorPackageResponse.listPriority ?? 0,

    price: mentorPackageResponse.price,
    currency: mentorPackageResponse.currency,
    duration: mentorPackageResponse.duration,

    mentor: {
      id: mentorPackageResponse.mentor.mentorId,
      displayName: mentorPackageResponse.mentor.displayName,
      profilePhotoUrl: mentorPackageResponse.mentor.profilePhotoUrl ?? undefined,
    },

    designDetails: {
      icon: mentorPackageResponse.designDetails.icon,
      color: mentorPackageResponse.designDetails.color,

      oldPrice: mentorPackageResponse.designDetails.oldPrice ?? undefined,
    },
  };
};

export const userPackAdapter = (userPackResponse: UserPackResponse): UserPack => {
  return {
    id: userPackResponse.id,
    active: userPackResponse.active,
    expiresAt: dayjs(userPackResponse.expiresAt),
    sessionsLeft: userPackResponse.sessionsLeft ?? undefined,

    mentorPack: mentorPackAdapter(userPackResponse.package),
  };
};
