import { Button } from '@mindkit';
import usePayment from 'modules/payment/hooks/usePayment';
import { NotOwnedRecording } from 'modules/recording/model';
import { ButtonProps } from 'shared/mindkit/components/Button/Button';

const BuyRecordingButton: React.FC<
  ButtonProps & { recording: NotOwnedRecording; className?: string }
> = ({ recording, className, ...other }) => {
  const { price, currency } = recording;

  const label = currency === 'USD' ? `$${price}` : `${price} ${currency}`;

  const { buyRecording } = usePayment();
  const handleBuy = () => {
    buyRecording(recording.id);
  };

  if (!price || !currency) {
    return null;
  }

  return (
    <Button {...other} onClick={handleBuy}>
      {label}
    </Button>
  );
};

export default BuyRecordingButton;
