import { IconPlay } from '@mindkit/icons';
import clsx from 'clsx';
import React from 'react';

const RecordingCard: React.FC<{
  name: string | React.ReactNode;
  subtitle?: string | React.ReactNode;
  isHighlighted?: boolean;
  footer?: React.ReactNode;
  className?: string;
}> = ({ name, subtitle, isHighlighted, footer, className }) => {
  return (
    <div
      className={clsx(
        'flex flex-col rounded-3xl border pb-4',
        { 'border-none bg-success-surface text-on-surface': isHighlighted },
        className,
      )}
    >
      <div className={clsx('flex flex-row items-center gap-2 p-4')}>
        <IconPlay className="h-8 w-8 shrink-0" />
        <h3 className="flex-grow">{name}</h3>
      </div>

      {subtitle && <p className="px-4">{subtitle}</p>}

      {footer && <div className={clsx('mt-3 flex flex-row px-4')}>{footer}</div>}
    </div>
  );
};

export default RecordingCard;
