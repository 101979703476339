import { Loader } from '@mindkit';
import { IllustrationSandClock } from '@mindkit/illustrations';
import useMentorEvents from 'modules/timeslot/hooks/useMentorEvents';
import React, { useMemo } from 'react';

import CalendarItem from '../Calendar/CalendarItem';

const MentorEventsSection: React.FC<{ mentorId: string }> = ({ mentorId }) => {
  const { data: timeslots, isPending, isError } = useMentorEvents(mentorId);

  const sortedTimeslots = useMemo(() => {
    if (!timeslots?.length) {
      return undefined;
    }

    const sroted = [...timeslots];
    sroted.sort((a, b) => {
      return a.date.isAfter(b.date) ? 1 : -1;
    });
    return sroted;
  }, [timeslots]);

  let body;
  if (isPending) {
    body = (
      <div className="flex h-72 flex-col items-center justify-center">
        <Loader />
      </div>
    );
  } else if (isError) {
    body = <p className="text-danger">Unable to load events...</p>;
  } else {
    const isEmpty = !sortedTimeslots?.length;
    if (isEmpty) {
      body = (
        <div className="flex flex-col items-center pb-12 pt-6 text-center">
          <IllustrationSandClock />
          <p>This mentor does not have any events for now.</p>
        </div>
      );
    } else {
      body = (
        <div className="flex flex-col gap-2 desk:gap-4">
          {sortedTimeslots.map((timeslot) => (
            <CalendarItem key={timeslot.id} timeslot={timeslot} />
          ))}
        </div>
      );
    }
  }

  return (
    <section className="page-section">
      <h2 className="mb-4">Upcoming</h2>
      {body}
    </section>
  );
};

export default MentorEventsSection;
