import { Modal } from '@mindkit';
import { closeSignInModal, useAuthStore } from 'modules/auth';
import SignInForm from 'modules/auth/components/SignInForm/SignInForm';
import React from 'react';
import { useLocation, useParams } from 'react-router-dom';

const SignInModal: React.FC = () => {
  const location = useLocation();

  const { isSignInModalOpen } = useAuthStore();

  const { mentorId } = useParams();

  const handleSignInSuccess = () => {
    closeSignInModal();
  };

  return (
    <Modal isOpen={isSignInModalOpen} onClose={closeSignInModal} className="w-[420px]">
      <SignInForm
        redirect={location.pathname}
        mentorId={mentorId}
        onSignInSuccess={handleSignInSuccess}
      />
    </Modal>
  );
};

export default SignInModal;
