import dayjs from 'dayjs';
import { Booking } from 'modules/booking/model';
import { eventInfoAdapter } from 'modules/timeslot/api';
import { EventInfo } from 'modules/timeslot/model';

import { BookingResponse } from './bookingApiInterfaces';

export const bookingAdapter = (booking: BookingResponse): Booking => {
  const hoursMinutes = booking.time.split(':');
  const date = dayjs
    .utc(booking.date)
    .hour(Number(hoursMinutes[0]))
    .minute(Number(hoursMinutes[1]))
    .tz(dayjs.tz.guess());

  const endTime = date.add(booking.duration, 'minute');

  let event: EventInfo | undefined;
  if (booking.event) {
    event = eventInfoAdapter(booking.event);
  }

  return {
    id: booking.id,
    userId: booking.userId,
    timeslotId: booking.timeslotId,
    date,
    endTime,
    duration: booking.duration,
    price: booking.price,
    currency: booking.currency,
    status: booking.status,
    payment: booking.payment,
    paymentLinks: booking.paymentLinks ?? [],
    mentor: {
      id: booking.mentor.mentorId,
      displayName: booking.mentor.displayName,
      profilePhotoUrl: booking.mentor.profilePhotoUrl ?? undefined,
    },
    callInfo: booking.callInfo ?? undefined,
    description: booking.description ?? undefined,
    googleCalendarLink: booking.googleCalendarLink ?? undefined,
    event,
  };
};
