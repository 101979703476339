import { Booking } from 'modules/booking/model';
import { ApiService } from 'services/api';

import { bookingAdapter } from './bookingApiAdapters';
import { BookingResponse, CreateBookingRequest } from './bookingApiInterfaces';

export const getBookings = async (mentorId?: string): Promise<Booking[]> => {
  const response = await ApiService.apiInstance.get<BookingResponse[]>('bookings', {
    params: { mentor: mentorId },
  });

  return response.data.map((booking) => bookingAdapter(booking));
};

export const getBooking = async (id: string): Promise<Booking> => {
  const response = await ApiService.apiInstance.get<BookingResponse>(`bookings/${id}`);

  return bookingAdapter(response.data);
};

export const getBookingTemporary = async (id: string): Promise<Booking> => {
  const response = await ApiService.apiInstance.get<BookingResponse>(`bookings/${id}/lookup`);

  return bookingAdapter(response.data);
};

export const createBooking = async (
  data: CreateBookingRequest,
  isAuth?: boolean,
): Promise<Booking> => {
  const response = await ApiService.apiInstance.post<BookingResponse>(
    isAuth ? 'bookings/create-for' : 'bookings/create',
    data,
  );

  return bookingAdapter(response.data);
};
