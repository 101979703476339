import dayjs from 'dayjs';
import { recordingAdapter } from 'modules/recording/api';
import {
  EventInfo,
  ManagedTimeslot,
  Timeslot,
  TimeslotCategory,
  TimeslotType,
} from 'modules/timeslot/model';

import {
  EventInfoResponse,
  ManagedTimeslotResponse,
  TimeslotResponse,
  TimeslotTypeResponse,
} from './timeslotApiInterfaces';

export const eventInfoAdapter = (event: EventInfoResponse): EventInfo => {
  return {
    id: event.id,
    name: event.name,
    description: event.description,
    minSeats: event.minSeats,
    maxSeats: event.maxSeats,

    recording: event.recording ? recordingAdapter(event.recording) : undefined,
  };
};

export const timeslotTypeAdapter = (timeslotType: TimeslotTypeResponse): TimeslotType => {
  return {
    id: timeslotType.id,
    name: timeslotType.name,
    duration: timeslotType.duration,
    price: timeslotType.price,
    currency: timeslotType.currency,
  };
};

const baseTimeslotAdapter = (timeslot: TimeslotResponse | ManagedTimeslotResponse) => {
  const hoursMinutes = timeslot.time.split(':');
  const date = dayjs
    .utc(timeslot.date)
    .hour(Number(hoursMinutes[0]))
    .minute(Number(hoursMinutes[1]))
    .tz(dayjs.tz.guess());

  const types = timeslot.types.map((type) => timeslotTypeAdapter(type));

  let event: EventInfo | undefined;
  if (timeslot.category === TimeslotCategory.EVENT) {
    // if (!timeslot.event) {
    //   throw new Error('timeslotAdapter: timeslot.event is undefined');
    // }
    if (timeslot.event) {
      event = eventInfoAdapter(timeslot.event);
    }
  }

  return {
    id: timeslot.id,
    category: timeslot.category,
    date,
    types,
    status: timeslot.status,

    seats: timeslot.seats,
    event,
  };
};

export const timeslotAdapter = (timeslot: TimeslotResponse): Timeslot => {
  return {
    ...baseTimeslotAdapter(timeslot),
    mentor: {
      id: timeslot.mentor.mentorId,
      displayName: timeslot.mentor.displayName,
      profilePhotoUrl: timeslot.mentor.profilePhotoUrl ?? undefined,
    },
  };
};

export const managedTimeslotAdapter = (timeslot: ManagedTimeslotResponse): ManagedTimeslot => {
  return {
    ...baseTimeslotAdapter(timeslot),
  };
};
