import dayjs from 'dayjs';
import { NotOwnedRecording, Recording } from 'modules/recording/model';

import { RecordingResponse } from './recordingApiInterfaces';

export const recordingAdapter = (recording: RecordingResponse): Recording | NotOwnedRecording => {
  const baseRecording = {
    id: recording.id,
    name: recording.name,
    subtitle: recording.subtitle ?? undefined,
    description: recording.description,
    date: dayjs.utc(recording.recordingDate),
    link: recording.link ?? undefined,
    passcode: recording.passcode ?? undefined,
    duration: recording.duration ?? undefined,
  };

  if (recording.hasAccess) {
    return {
      ...baseRecording,
      status: 'owned',
    };
  }

  return {
    ...baseRecording,
    status: 'not_owned',

    price: recording.price ?? undefined,
    currency: recording.currency ?? undefined,
  };
};

export const recordingOwnedAdapter = (recording: RecordingResponse): Recording => {
  const result = recordingAdapter(recording);
  if (result.status !== 'owned') {
    throw new Error('recordingOwnedAdapter: Expected recording.status to be "owned"');
  }

  return result;
};

export const recordingNotOwnedAdapter = (recording: RecordingResponse): NotOwnedRecording => {
  const result = recordingAdapter(recording);
  if (result.status !== 'not_owned') {
    throw new Error('recordingOwnedAdapter: Expected recording.status to be "not_owned"');
  }

  return result;
};
