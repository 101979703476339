import { useQuery } from '@tanstack/react-query';
import { timeslotActions } from 'modules/timeslot';

const useMentorEvents = (mentorId: string) => {
  return useQuery({
    queryKey: ['mentor', mentorId, 'events'],
    queryFn: () => {
      return timeslotActions.getMentorEvents(mentorId);
    },
  });
};

export default useMentorEvents;
