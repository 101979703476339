import dayjs from 'dayjs';
import { Booking, BookingStatus } from 'modules/booking/model';

export const getBookingFormated = (booking: Booking) => {
  const date = booking.date.format('ddd, MMM DD');

  const startTime = booking.date.format('hh:mm\xA0A');
  const endTime = booking.endTime.format('hh:mm\xA0A');
  const time = `${startTime} -\xA0${endTime}`;

  return { date, time };
};

export const getBookingCategory = (booking: Booking) => {
  const isBookingActual = (b: Booking) => b.date.isAfter(dayjs().subtract(12, 'hours'));

  if (booking.status === BookingStatus.CONFIRMED && isBookingActual(booking)) {
    return 'upcoming';
  }
  if (booking.status === BookingStatus.COMPLETED || !isBookingActual(booking)) {
    return 'archive';
  }

  return 'unknown';
};

export const getBookingCategories = (bookings: Booking[]) => {
  const dateSortAsc = (t1: Booking, t2: Booking) => (t1.date.isAfter(t2.date) ? 1 : -1);
  const dateSortDesc = (t1: Booking, t2: Booking) => (t1.date.isAfter(t2.date) ? -1 : 1);

  const sortedBookings = [...bookings].sort(dateSortAsc);

  const upcoming: Booking[] = [];
  let archive: Booking[] = [];
  const unknown: Booking[] = [];

  sortedBookings.forEach((b) => {
    const category = getBookingCategory(b);

    if (category === 'upcoming') {
      upcoming.push(b);
    } else if (category === 'archive') {
      archive.push(b);
    } else {
      unknown.push(b);
    }
  });

  archive = [...archive].sort(dateSortDesc);

  const isEmpty = !upcoming.length && !archive.length;

  return { upcoming, archive, unknown, isEmpty };
};
