import clsx from 'clsx';
import React from 'react';

import { ReactComponent as MindshareLogo } from '../../assets/MindshareLogo.svg';
import { ReactComponent as MindshareLogoWithLabel } from '../../assets/MindshareLogoWithLabel.svg';

const MindLogo: React.FC<{ className?: string; withLabel?: boolean }> = ({
  className,
  withLabel,
}) => {
  const cm = clsx('block h-10 w-auto', className);

  return withLabel ? <MindshareLogoWithLabel className={cm} /> : <MindshareLogo className={cm} />;
};

export default MindLogo;
