import { Skeleton } from '@mindkit';
import { IllustrationSleep } from '@mindkit/illustrations';
import RecordingItem from 'modules/recording/components/RecordingItem/RecordingItem';
import useMentorRecordings from 'modules/recording/hooks/useMentorRecordings';
import React from 'react';

import RecordingItemSkeleton from '../RecordingItemSkeleton/RecordingItemSkeleton';
import Tabs from './Tabs';

const TabOptions = [
  { id: 'all', label: 'All' },
  { id: 'purchased', label: 'Purchased' },
];

const MentorRecordingsSection: React.FC<{ mentorId: string }> = ({ mentorId }) => {
  const {
    data: recordings,
    isPending: isRecordingsPending,
    isError: isRecordingsError,
  } = useMentorRecordings(mentorId);

  const [selectedTab, setSelectedTab] = React.useState(TabOptions[0].id);

  let recordingsBody;
  if (isRecordingsPending) {
    recordingsBody = (
      <div className="flex flex-col items-center gap-2 desk:gap-4">
        <Skeleton.Multiplier count={5}>
          <RecordingItemSkeleton className="w-full" />
        </Skeleton.Multiplier>
      </div>
    );
  } else if (isRecordingsError) {
    recordingsBody = <p className="text-danger">Unable to load recordings...</p>;
  } else {
    const isEmpty = !recordings.length;
    if (isEmpty) {
      recordingsBody = (
        <div className="flex flex-col items-center pb-12 pt-6 text-center">
          <IllustrationSleep />
          <p>This mentor does not have any recordings for now.</p>
        </div>
      );
    } else {
      recordingsBody = (
        <div className="flex flex-col gap-2 desk:gap-4">
          {recordings
            .filter((recording) => (selectedTab === 'all' ? true : recording.status === 'owned'))
            .map((recording) => (
              <RecordingItem
                key={recording.id}
                recording={recording}
                isHighlighted={recording.status === 'owned'}
              />
            ))}
        </div>
      );
    }
  }

  const showTabs = (recordings || []).some((recording) => recording.status === 'owned');

  return (
    <section className="page-section">
      <h2 className="mb-4">Recordings</h2>
      {showTabs && (
        <Tabs
          tabs={TabOptions}
          selectedTab={selectedTab}
          onSelect={setSelectedTab}
          className="mb-4"
        />
      )}

      {recordingsBody}
    </section>
  );
};

export default MentorRecordingsSection;
