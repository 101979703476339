import { Button, notify } from '@mindkit';
import clsx from 'clsx';
import { Booking } from 'modules/booking/model';

const ZoomLink: React.FC<{ booking: Booking; className?: string }> = ({ booking, className }) => {
  const { callInfo, event } = booking;
  const isEvent = Boolean(event);

  const handleEventUnknownZoomLink = () => {
    notify('We will send you a Zoom link 10 minutes prior to the event start time', 'success', {
      title: 'Booking confirmed',
    });
  };

  if (callInfo) {
    return (
      <Button
        className={clsx('gap-1', className)}
        variant="outlined"
        size="sm"
        as="a"
        href={callInfo}
        target="_blank"
        rel="noreferrer"
      >
        Join in Zoom
      </Button>
    );
  }

  if (isEvent && !callInfo) {
    return (
      <Button
        className={clsx('gap-1', className)}
        variant="outlined"
        size="sm"
        onClick={handleEventUnknownZoomLink}
      >
        Join in Zoom
      </Button>
    );
  }

  return null;
};

export default ZoomLink;
