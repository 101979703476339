import { Button, haptic, Input, notify, useShakeAnim } from '@mindkit';
import { IconCheck, IconCheckOutlined } from '@mindkit/icons';
import clsx from 'clsx';
import useAuth from 'modules/auth/hooks/useAuth';
import CheckoutItemPreview from 'modules/payment/components/CheckoutItemPreview/CheckoutItemPreview';
import useCheckout from 'modules/payment/hooks/useCheckout';
import { CheckoutPending, CheckoutProcessResult, PaymentMethod } from 'modules/payment/model';
import React, { useCallback, useEffect, useState } from 'react';
import { AnalyticsService } from 'services/analytics';
import isValidEmail from 'shared/helpers/isValidEmail';

import CouponField from './CouponField';
import PaymentOptionSelect from './PaymentOptionSelect';

const CheckoutForm: React.FC<{
  checkout: CheckoutPending;
  onResult?: (result: CheckoutProcessResult) => void;
  className?: string;
}> = ({ checkout, onResult, className }) => {
  const { isAuth } = useAuth();

  const { paymentOptions, emailRequired, suggestedEmail, policyRequired } = checkout;

  const { processCheckout } = useCheckout();

  useEffect(() => {
    AnalyticsService.sendEvent('checkout_form_show', checkout.aggregatedInfo);
    return () => {
      AnalyticsService.sendEvent('checkout_form_hide', checkout.aggregatedInfo);
    };
  }, [checkout]);

  const [paymentMethod, setPaymentMethod] = useState<PaymentMethod>(paymentOptions[0].method);
  const isPaymentWithPackage = paymentMethod === PaymentMethod.PACKAGE;

  const isFree = paymentMethod === PaymentMethod.FREE;

  const [email, setEmail] = useState(suggestedEmail);
  const [isEmailError, setIsEmailError] = useState(false);
  const [emailShakeClass, shakeEmail] = useShakeAnim();
  const handleEmailChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setIsEmailError(false);
    setEmail(e.target.value);
  }, []);

  const [privacyChecked, setPrivacyChecked] = useState(false);
  const handlePrivacyChange = useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setPrivacyChecked(e.target.checked);
  }, []);

  const [isProcessing, setIsProcessing] = useState(false);

  const handleCheckout = useCallback(
    async (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      AnalyticsService.sendEvent('button_purchase_click', {
        ...checkout.aggregatedInfo,
        paymentMethod,
      });

      if (checkout.emailRequired && !isValidEmail(email)) {
        setIsEmailError(true);
        shakeEmail();
        ((e.target as HTMLFormElement).elements.namedItem('email') as HTMLInputElement).focus();

        notify('Please enter a valid email', 'error');
        haptic.notification('warning');

        return;
      }

      if (policyRequired && !privacyChecked) {
        notify('Please agree with Privacy Policy', 'error');
        haptic.notification('warning');

        return;
      }

      setIsProcessing(true);
      const paymentOption = paymentOptions.find((option) => option.method === paymentMethod)!;
      const result = await processCheckout(checkout, paymentOption, privacyChecked, email);
      setIsProcessing(false);

      if (result) {
        onResult?.(result);
      }
    },
    [
      checkout,
      paymentOptions,
      paymentMethod,
      processCheckout,
      email,
      policyRequired,
      privacyChecked,
      onResult,
      shakeEmail,
    ],
  );

  const isShowSubtotal =
    checkout.priceSummary.subtotal &&
    checkout.priceSummary.subtotal !== checkout.priceSummary.total;

  return (
    <form onSubmit={handleCheckout} className={clsx('flex flex-col gap-8 px-5 pt-9', className)}>
      <CheckoutItemPreview checkoutRequest={checkout.checkoutRequest} />

      {paymentOptions.length > 1 && (
        <div>
          <h4 className="mb-2">Payment Option</h4>
          <PaymentOptionSelect
            paymentOptions={paymentOptions}
            selectedMethod={paymentMethod}
            onSelect={setPaymentMethod}
          />
        </div>
      )}

      {emailRequired && (
        <div>
          <h4 className="mb-2">Enter your email to receive confirmation</h4>
          <Input
            id="email"
            name="email"
            autoCapitalize="off"
            autoCorrect="off"
            className={clsx('w-full', emailShakeClass)}
            placeholder="adele@mindshare.chat"
            value={email}
            onChange={handleEmailChange}
            isError={isEmailError}
          />
        </div>
      )}

      {!isPaymentWithPackage && !isFree && (
        <CouponField checkout={checkout} isEmailRequired={!isAuth} email={email} />
      )}

      {policyRequired && (
        <label
          htmlFor="privacy"
          className={clsx('flex flex-row items-center justify-between gap-2')}
        >
          <span>
            I have read and agreed with{' '}
            <a className="underline" href="/privacy-policy.pdf" target="_blank">
              Privacy Policy
            </a>{' '}
            and{' '}
            <a className="underline" href="/terms-and-conditions.pdf" target="_blank">
              Terms and Conditions
            </a>
          </span>

          {privacyChecked ? (
            <IconCheck className="h-6 w-6 shrink-0 cursor-pointer text-primary" />
          ) : (
            <IconCheckOutlined className="h-6 w-6 shrink-0 cursor-pointer text-secondary" />
          )}

          <input
            id="privacy"
            name="privacy"
            value="true"
            className="appearance-none"
            type="checkbox"
            onChange={handlePrivacyChange}
            checked={privacyChecked}
          />
        </label>
      )}

      <div className="sticky bottom-0 mt-auto bg-level1 pb-4">
        <hr className="-mx-5 mb-4" />
        <div className="flex flex-row items-center">
          <div className="flex flex-grow flex-row items-center gap-2">
            {isPaymentWithPackage ? (
              <>
                <h2>$0</h2>
                <p className="strike-anim text-secondary">${checkout.priceSummary.total}</p>
              </>
            ) : (
              <>
                <h2>${checkout.priceSummary.total}</h2>
                {isShowSubtotal && (
                  <p className="strike-anim text-secondary">${checkout.priceSummary.subtotal}</p>
                )}
              </>
            )}
          </div>

          <Button
            className="min-w-[124px]"
            type="submit"
            isDisabled={isProcessing}
            isLoading={isProcessing}
          >
            Purchase
          </Button>
        </div>
      </div>
    </form>
  );
};

export default CheckoutForm;
