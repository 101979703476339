import * as referralApi from 'modules/referral/api';
import { AnalyticsService } from 'services/analytics';

export const generateMentorReferral = async (
  mentorId: string,
  botId?: string,
  timeslotId?: string,
) => {
  const result = await referralApi.generateMentorReferral(mentorId, botId, timeslotId);
  return result;
};

export const setReferral = (referral: string) => {
  sessionStorage.setItem('referral', referral);
  AnalyticsService.setMetaData({ referral });
};

export const getReferral = () => {
  return sessionStorage.getItem('referral') ?? undefined;
};
