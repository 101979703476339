import React from 'react';

const SkeletonMultiplier: React.FC<{
  children: React.ReactElement;
  count?: number;
}> = ({ children, count = 3 }) => {
  // eslint-disable-next-line react/no-array-index-key
  return Array.from({ length: count }).map((_, i) => React.cloneElement(children, { key: i }));
};

export default SkeletonMultiplier;
