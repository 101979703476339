import { haptic } from '@mindkit';
import { IconArrowLeft, IconArrowRight } from '@mindkit/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import useCalendar from 'modules/timeslot/hooks/useCalendar';
import { weekHelpers } from 'modules/timeslot/model';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { AnalyticsService } from 'services/analytics';

import CalendarTab from '../CalendarTab/CalendarTab';
import CalendarList from './CalendarList';

const INITIAL_WEEKS_COUNT = 3;
const LOAD_MORE_WEEKS_COUNT = 4;

const Calendar: React.FC<{ mentorId: string; className?: string }> = ({ mentorId, className }) => {
  const [weeks, setWeeks] = useState(() => weekHelpers.getWeeks(dayjs(), INITIAL_WEEKS_COUNT));
  const { timeslotsByWeeks } = useCalendar(mentorId, weeks);

  const initialWeekId = useMemo(
    () =>
      timeslotsByWeeks.length
        ? timeslotsByWeeks.find((w) => w.availableCount > 0)?.id || timeslotsByWeeks[0].id
        : undefined,
    [timeslotsByWeeks],
  );

  const [currentWeekId, setCurrentWeekId] = useState(initialWeekId);
  const currentWeek = timeslotsByWeeks.find((w) => w.id === currentWeekId);

  const handleWeekClick = useCallback(
    (id: string) => {
      if (id !== currentWeekId) {
        AnalyticsService.sendEvent('calendar_week_change_click');
        setCurrentWeekId(id);
      }
    },
    [currentWeekId],
  );

  useEffect(() => {
    if (!currentWeekId && initialWeekId) {
      setCurrentWeekId(initialWeekId);
    }
  }, [currentWeekId, initialWeekId]);

  const handleLoadMore = () => {
    haptic.selection();

    AnalyticsService.sendEvent('calendar_load_more_click');

    const updatedWeeks = weekHelpers.getWeeks(dayjs(), weeks.length + LOAD_MORE_WEEKS_COUNT);
    setWeeks(updatedWeeks);
  };

  const handleNextWeek = () => {
    haptic.selection();

    const currentIndex = timeslotsByWeeks.findIndex((w) => w.id === currentWeekId);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= timeslotsByWeeks.length) {
      AnalyticsService.sendEvent('calendar_load_more_click');

      const updatedWeeks = weekHelpers.getWeeks(dayjs(), weeks.length + LOAD_MORE_WEEKS_COUNT);
      setWeeks(updatedWeeks);
      setCurrentWeekId(updatedWeeks[nextIndex].id);
    } else {
      setCurrentWeekId(timeslotsByWeeks[nextIndex]?.id);
    }
  };
  const handlePrevWeek = () => {
    haptic.selection();

    const currentIndex = timeslotsByWeeks.findIndex((w) => w.id === currentWeekId);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentWeekId(timeslotsByWeeks[prevIndex]?.id);
    }
  };

  return (
    <div className={clsx('', className)}>
      <div className="flex max-w-full flex-row gap-2 overflow-hidden">
        <button
          type="button"
          className="py-4 transition-all active:scale-95 mobile:hidden"
          onClick={handlePrevWeek}
          aria-label="Prev week"
        >
          <IconArrowLeft />
        </button>

        <div
          className="hide-scrollbar flex flex-grow snap-x snap-mandatory flex-row gap-2 overflow-x-scroll"
          role="tablist"
        >
          {timeslotsByWeeks.map((week) => {
            const { id, start, end, availableCount, isLoaded } = week;

            return (
              <CalendarTab
                key={id}
                className="shrink-0 flex-grow basis-[152px] snap-start"
                start={start}
                end={end}
                slotsLabel={`${availableCount} slots`}
                isSelected={id === currentWeekId}
                onClick={() => handleWeekClick(id)}
                isLoading={!isLoaded}
              />
            );
          })}

          <button
            className="rounded-3xl border border-contrast px-4 text-contrast transition-colors duration-300 hover:bg-contrast hover:text-contrast-foreground desk:hidden"
            type="button"
            aria-label="Load more slots"
            onClick={handleLoadMore}
          >
            <IconArrowRight />
          </button>
        </div>

        <button
          type="button"
          className="py-4 transition-all active:scale-95 mobile:hidden"
          onClick={handleNextWeek}
          aria-label="Next week"
        >
          <IconArrowRight />
        </button>
      </div>

      <CalendarList
        className="mt-4"
        events={currentWeek?.events || []}
        timeslots={currentWeek?.timeslots || []}
        isLoading={!currentWeek?.isLoaded}
        isError={currentWeek?.isError}
      />
    </div>
  );
};

export default Calendar;
