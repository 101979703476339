import {
  OpenChangeReason,
  useDismiss,
  useFloating,
  useInteractions,
  useRole,
} from '@floating-ui/react';
import * as React from 'react';

type ContextType =
  | (ReturnType<typeof useFloating> &
      ReturnType<typeof useInteractions> & {
        isOpen: boolean;
        setOpen: (open: boolean, event?: Event, reason?: OpenChangeReason) => void;
      })
  | null;

const ModalContext = React.createContext<ContextType>(null);

export const useModalContext = () => {
  const context = React.useContext(ModalContext);

  if (context == null) {
    throw new Error('Modal components must be wrapped in <Modal />');
  }

  return context;
};

export interface ModalCoreProps {
  children: React.ReactNode;

  isOpen: boolean;
  onClose: (reason?: OpenChangeReason) => void;

  closeOnClickOutside?: boolean;
}

export const ModalCore: React.FC<ModalCoreProps> = ({
  children,
  isOpen,
  onClose,
  closeOnClickOutside = true,
}) => {
  const setOpen = React.useCallback(
    (open: boolean, event?: Event, reason?: OpenChangeReason) => {
      if (!open) {
        onClose(reason);
      }
    },
    [onClose],
  );
  const floatingData = useFloating({
    open: isOpen,
    onOpenChange: setOpen,
  });

  const dismiss = useDismiss(floatingData.context, { outsidePress: closeOnClickOutside });
  const role = useRole(floatingData.context);
  const interactions = useInteractions([dismiss, role]);

  const modalContext = React.useMemo(
    () => ({
      isOpen,
      setOpen,
      ...interactions,
      ...floatingData,
    }),
    [isOpen, setOpen, floatingData, interactions],
  );

  return <ModalContext.Provider value={modalContext}>{children}</ModalContext.Provider>;
};
