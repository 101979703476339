import {
  FloatingFocusManager,
  FloatingOverlay,
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import clsx from 'clsx';
import React from 'react';

import cm from './ModalContent.module.css';
import { useModalContext } from './ModalCore';

interface Props {
  wrapperClassName?: string;
  transitionConfig?: UseTransitionStylesProps;
  noOverlay?: boolean;
}

export type ModalContentProps = Props &
  React.HTMLProps<HTMLDivElement> &
  React.RefAttributes<HTMLDivElement>;

export const ModalContent = React.forwardRef<HTMLDivElement, ModalContentProps>(
  function ModalContent(
    { transitionConfig, wrapperClassName, noOverlay, style, ...props },
    propRef,
  ) {
    const { context: floatingContext, ...context } = useModalContext();
    const ref = useMergeRefs([context.refs.setFloating, propRef]);

    const { isMounted, styles: transitionStyles } = useTransitionStyles(
      floatingContext,
      transitionConfig,
    );
    const hasTransition = Boolean(transitionConfig);
    const isRendered = floatingContext.open || (hasTransition && isMounted);

    if (!isRendered) {
      return null;
    }

    return (
      <FloatingPortal>
        <FloatingOverlay
          className={clsx(
            !noOverlay && (floatingContext.open ? cm.showOverlay : cm.hideOverlay),
            'z-[1000]',
          )}
          lockScroll
        />

        <FloatingFocusManager context={floatingContext} modal>
          <div className={clsx(wrapperClassName, 'z-[1001]')}>
            <div
              {...context.getFloatingProps(props)}
              ref={ref}
              style={{ ...style, ...(hasTransition ? transitionStyles : {}) }}
            >
              {props.children}
            </div>
          </div>
        </FloatingFocusManager>
      </FloatingPortal>
    );
  },
);
