import clsx from 'clsx';
import { MentorPack } from 'modules/pack/model';
import React from 'react';

const MentorPackPreview: React.FC<{
  pack: MentorPack;
  isMinimal?: boolean;
  className?: string;
}> = ({ pack, isMinimal, className }) => {
  return (
    <div className={clsx('', className)}>
      {isMinimal ? <h3 className="mb-1">{pack.name}</h3> : <h2 className="mb-1">{pack.name}</h2>}
      {!isMinimal ? <p className="mb-2 whitespace-pre-wrap">{pack.description}</p> : null}
    </div>
  );
};

export default MentorPackPreview;
