import { haptic, IconButton, IfScreen, MindLogo } from '@mindkit';
import { IconSearch } from '@mindkit/icons';
import clsx from 'clsx';
import SignInButton from 'modules/auth/components/SignInButton/SignInButton';
import useAuth from 'modules/auth/hooks/useAuth';
import UserInfoBar from 'modules/user/components/UserInfoBar/UserInfoBar';
import { Link, useLocation } from 'react-router-dom';
import { TelegramService } from 'services/telegram';

import GeneralSearchBar from '../GeneralSearchBar/GeneralSearchBar';

const NO_SEARCH_PAGES = ['/', '/mentor/me'];

const PageHeader: React.FC<{ className?: string }> = ({ className }) => {
  const { isAuth } = useAuth();

  let redirect = '/';
  if (TelegramService.isTelegramApp()) {
    redirect = TelegramService.getSafeRootRedirect();
  }

  const location = useLocation();
  const showSearch =
    (!TelegramService.isTelegramApp() || TelegramService.isGeneralBot()) &&
    !NO_SEARCH_PAGES.includes(location.pathname);

  return (
    <header
      className={clsx('z-10 bg-level1 shadow-lg desk:shadow-none desk:dark:bg-level2', className)}
    >
      <div className="mx-auto flex shrink-0 flex-row items-center gap-2 py-4 desk:h-16 desk:gap-4 desk:py-16">
        <IfScreen
          mobile={
            <>
              <Link
                to={redirect}
                onClick={() => {
                  haptic.selection();
                }}
              >
                <MindLogo className="h-10" />
              </Link>

              <div className="flex-grow" />

              {showSearch && (
                <Link
                  to="/?focus=true"
                  onClick={() => {
                    haptic.selection();
                  }}
                >
                  <IconButton>
                    <IconSearch className="text-secondary" />
                  </IconButton>
                </Link>
              )}

              {isAuth ? <UserInfoBar /> : <SignInButton size="sm" />}
            </>
          }
          desktop={
            <>
              <Link
                to={redirect}
                onClick={() => {
                  haptic.selection();
                }}
              >
                <MindLogo className="h-16" withLabel />
              </Link>

              {showSearch && <GeneralSearchBar />}

              <div className="flex-grow" />

              {isAuth ? <UserInfoBar /> : <SignInButton />}
            </>
          }
        />
      </div>
    </header>
  );
};

export default PageHeader;
