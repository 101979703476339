import { Loader } from '@mindkit';
import { IllustrationSandClock } from '@mindkit/illustrations';
import clsx from 'clsx';
import { Timeslot, timeslotHelpers } from 'modules/timeslot/model';
import React from 'react';
import TimezoneLabel from 'shared/components/TimezoneLabel/TimezoneLabel';

import CalendarItem from './CalendarItem';

const CalendarList: React.FC<{
  events: Timeslot[];
  timeslots: Timeslot[];
  isLoading?: boolean;
  isError?: boolean;
  className?: string;
}> = ({ events, timeslots, isLoading, isError, className }) => {
  const isEmpty = !events.length && !timeslots.length;

  const groups = timeslotHelpers.groupTimeslotsByDay(timeslots);

  let body;
  if (isError) {
    body = <p className="py-20 text-center text-danger">Unable to load timeslots...</p>;
  } else if (isLoading && isEmpty) {
    body = <Loader className="my-10 self-center" />;
  } else if (isEmpty) {
    body = (
      <>
        <IllustrationSandClock className="mt-4 self-center" />
        <p className="mb-20 mt-2 text-center">
          Calls haven&apos;t been scheduled for this week just yet, but I&apos;m working on it!
        </p>
      </>
    );
  } else {
    body = (
      <>
        {events.length ? (
          <div className="mb-8 flex flex-col gap-2">
            {events.map((timeslot) => (
              <CalendarItem key={timeslot.id} timeslot={timeslot} />
            ))}
          </div>
        ) : null}

        {groups.map((group, idx) => {
          const isFirst = idx === 0;

          const dateLabel = group.date.format('ddd, MMM DD');

          return (
            <div key={group.date.toISOString()} className="mb-5">
              {isFirst ? (
                <div className="flex flex-row justify-between">
                  <p>{dateLabel}</p>
                  <TimezoneLabel className="text-sm text-secondary" isFull />
                </div>
              ) : (
                <p>{dateLabel}</p>
              )}

              <div className="mt-1 flex flex-col gap-2">
                {group.timeslots.map((timeslot) => {
                  return <CalendarItem key={timeslot.id} timeslot={timeslot} />;
                })}
              </div>
            </div>
          );
        })}
      </>
    );
  }

  return <div className={clsx('flex flex-col', className)}>{body}</div>;
};

export default CalendarList;
