import clsx from 'clsx';
import toast, { Toaster } from 'react-hot-toast';

import { IconCheck, IconError } from '../../icons';
import cm from './Notifications.module.css';

export const notify = (
  message: string,
  status: 'success' | 'error' = 'success',
  config: { title?: string } = {},
) => {
  toast.custom(
    (t) => {
      let icon = null;
      let title = '';
      if (status === 'success') {
        title = 'Success';
        icon = <IconCheck className="h-7 w-7 shrink-0 text-primary" />;
      } else if (status === 'error') {
        title = 'Oh no!';
        icon = <IconError className="h-7 w-7 shrink-0 text-danger" />;
      }
      if (config.title) {
        title = config.title;
      }

      return (
        <div
          className={clsx('flex flex-row items-center gap-3 rounded-xl py-4 pl-4 pr-8 shadow-xl', {
            [cm.showToast]: t.visible,
            [cm.hideToast]: !t.visible,

            'border border-danger bg-level1': status === 'error',
            'border border-primary bg-level1': status === 'success',
          })}
        >
          {icon}
          <div>
            <h4>{title}</h4>
            <p className="whitespace-pre-wrap break-words">{message}</p>
          </div>
        </div>
      );
    },
    { duration: 2000 },
  );
};

export const NotificationsRoot: React.FC = () => {
  return <Toaster position="bottom-center" containerClassName={cm.notificationsContainer} />;
};
