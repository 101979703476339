import { Loader, notify } from '@mindkit';
import { isAxiosError } from 'axios';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import MentorDetailsEditForm from 'modules/mentor/components/MentorDetailsEditForm/MentorDetailsEditForm';
import MentorManagerInfo from 'modules/mentor/components/MentorManagerInfo/MentorManagerInfo';
import MentorSetupProfile from 'modules/mentor/components/MentorSetupProfile/MentorSetupProfile';
import useMentorManager from 'modules/mentor/hooks/useMentorManager';
import { mentorHelpers } from 'modules/mentor/model';
import CreateTimeslotButton from 'modules/timeslot/components/CreateTimeslotButton/CreateTimeslotButton';
import ManagerCalendar from 'modules/timeslot/components/ManagerCalendar/ManagerCalendar';
import { Week } from 'modules/timeslot/model/weekHelpers';
import React, { useState } from 'react';
import { Navigate } from 'react-router-dom';
import TimezoneLabel from 'shared/components/TimezoneLabel/TimezoneLabel';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const MentorManagerPage: React.FC = () => {
  const { data: mentor, isError, error, isPending } = useMentorManager();

  const [currentWeek, setCurrentWeek] = useState<Week | undefined>();

  if (isError) {
    if (isAxiosError(error) && error.response?.status === 403) {
      notify('You are not a mentor', 'error');
    } else {
      notify(getErrorMessage(error), 'error');
    }
    return <Navigate to="/" />;
  }
  if (isPending) {
    return <Loader className="m-auto" />;
  }

  const handleMentorCreated = () => {
    notify('Great! You have became a mentor', 'success');
  };

  const status = mentorHelpers.getMentorManagerStatus(mentor);

  if (!status.isInfoFilled) {
    return (
      <PageLayout
        deskBodyClassName="max-w-xl"
        body={
          <section className="page-section">
            <div className="page-section-header">
              <h2 className="mb-1">Welcome to Mindshare!</h2>
              <p>Fill the form to activate your mentor account</p>
            </div>

            <MentorDetailsEditForm onSuccess={handleMentorCreated} />
          </section>
        }
      />
    );
  }

  return (
    <PageLayout
      bodyClassName="gap-8"
      body={
        <>
          <MentorManagerInfo />

          <MentorSetupProfile mentor={mentor} />

          {status.isReady && (
            <section className="page-section">
              <div className="page-section-header flex flex-row items-center justify-between">
                <h2>
                  Time slots{' '}
                  <span className="font-normal text-secondary mobile:block mobile:text-xs">
                    (<TimezoneLabel isFull />)
                  </span>
                </h2>
                <CreateTimeslotButton className="shrink-0" initialDate={currentWeek?.start} />
              </div>

              <ManagerCalendar onWeekChange={setCurrentWeek} />
            </section>
          )}
        </>
      }
    />
  );
};

export default MentorManagerPage;
