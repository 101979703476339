import { haptic } from '@mindkit';
import { IconBankCard, IconCheck, IconCheckOutlined, IconPackGift } from '@mindkit/icons';
import clsx from 'clsx';
import { PaymentMethod, PaymentOption } from 'modules/payment/model';
import React from 'react';
import assertUnreachable from 'shared/helpers/assertUnreachable';

interface PaymentOptionConfig {
  Icon: React.FC<React.SVGProps<SVGSVGElement>>;
  label: string;
  color: string;
}

const getPaymentOptionConfig = (paymentOption: PaymentOption): PaymentOptionConfig => {
  switch (paymentOption.method) {
    case PaymentMethod.STRIPE:
      return {
        Icon: IconBankCard,
        label: 'Credit or Debit Card',
        color: '#193EFF',
      };
    case PaymentMethod.RU_CARD:
      return {
        Icon: IconBankCard,
        label: 'Russian Card',
        color: '#2BD264',
      };
    case PaymentMethod.PACKAGE:
      return {
        Icon: IconPackGift,
        label: paymentOption.title,
        color: '#906bf3',
      };
    case PaymentMethod.FREE:
      return {
        Icon: IconPackGift,
        label: 'Free',
        color: '#906bf3',
      };
    default:
      return assertUnreachable(paymentOption);
  }
};

const PaymentOptionSelect: React.FC<{
  paymentOptions: PaymentOption[];
  selectedMethod?: PaymentMethod;
  onSelect?: (method: PaymentMethod) => void;
  className?: string;
}> = ({ paymentOptions, selectedMethod, onSelect, className }) => {
  return (
    <div className={clsx('flex flex-col gap-4', className)} role="radiogroup">
      {paymentOptions.map((paymentOption) => {
        const config = getPaymentOptionConfig(paymentOption);
        const isSelected = paymentOption.method === selectedMethod;

        return (
          <button
            key={paymentOption.method}
            role="radio"
            aria-checked={isSelected}
            className={clsx('flex flex-row items-center gap-2')}
            type="button"
            onClick={() => {
              haptic.selection();
              onSelect?.(paymentOption.method);
            }}
          >
            <div
              className="inline-flex h-9 w-9 shrink-0 items-center justify-center rounded-lg text-white"
              style={{
                backgroundColor: config.color,
              }}
            >
              <config.Icon className="h-5 w-5" />
            </div>
            <p>{config.label}</p>
            <div className="flex-grow" />
            {isSelected ? (
              <IconCheck className="h-6 w-6 shrink-0 text-primary" />
            ) : (
              <IconCheckOutlined className="h-6 w-6 shrink-0 text-secondary" />
            )}
          </button>
        );
      })}
    </div>
  );
};

export default PaymentOptionSelect;
