import { Input, notify, useShakeAnim } from '@mindkit';
import { IconCheck } from '@mindkit/icons';
import { isAxiosError } from 'axios';
import clsx from 'clsx';
import { getPlatform } from 'modules/general/actions/generalActions';
import { paymentActions } from 'modules/payment';
import { CheckoutPending } from 'modules/payment/model';
import { useRef, useState } from 'react';
import getErrorMessage from 'shared/helpers/getErrorMessage';
import isValidEmail from 'shared/helpers/isValidEmail';

const CouponField: React.FC<{
  checkout: CheckoutPending;
  isEmailRequired?: boolean;
  email?: string;
  className?: string;
}> = ({ checkout, isEmailRequired, email, className }) => {
  const { coupons, selectedCoupon } = checkout;

  const [couponCode, setCouponCode] = useState(selectedCoupon?.code ?? '');
  const handleCouponChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCouponCode(e.target.value);
  };

  const isApplied = selectedCoupon?.code === couponCode;

  const inputRef = useRef<HTMLInputElement>(null);
  const [shakeClass, shakeIt] = useShakeAnim();

  const [isProcessing, setIsProcessing] = useState(false);

  const applyCoupon = async (couponCode: string) => {
    if (isEmailRequired && !isValidEmail(email)) {
      notify('Please enter a valid email to apply the coupon', 'error');
      return;
    }

    setCouponCode(couponCode);

    setIsProcessing(true);

    try {
      await paymentActions.applyCoupon(
        checkout.id,
        couponCode,
        getPlatform(),
        isEmailRequired ? email : undefined,
      );
      notify('Coupon activated', 'success');
    } catch (e) {
      if (isAxiosError(e) && e.response?.status === 404) {
        notify('Coupon not found', 'error');
      } else {
        notify(getErrorMessage(e), 'error');
      }

      setCouponCode(selectedCoupon?.code ?? '');

      shakeIt();
      setTimeout(() => {
        inputRef.current?.focus();
      });

      throw e;
    } finally {
      setIsProcessing(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === 'Enter') {
      e.preventDefault();

      if (!couponCode.trim()) {
        notify('Enter a coupon code', 'error');
        return;
      }

      applyCoupon(couponCode);
    }
  };

  const handleBlur = () => {
    if (isProcessing || !couponCode.trim() || couponCode === selectedCoupon?.code) {
      return;
    }

    applyCoupon(couponCode);
  };

  return (
    <div className={className}>
      <h4 className="mb-2">Coupon</h4>
      <div className="relative">
        <Input
          ref={inputRef}
          className={clsx('w-full', shakeClass)}
          placeholder="Have a coupon? Enter it here"
          value={couponCode}
          onChange={handleCouponChange}
          onKeyDown={handleKeyDown}
          onBlur={handleBlur}
          isDisabled={isProcessing}
          isError={Boolean(shakeClass)}
        />

        <div
          className={clsx(
            'absolute right-2 top-1/2 flex -translate-y-1/2 flex-row items-center gap-1 text-primary transition-all dark:text-contrast',
            { 'scale-105 opacity-0': !isApplied || isProcessing },
          )}
        >
          <IconCheck className="h-5 w-5" />
          <p className="text-sm">ACTIVATED</p>
        </div>
      </div>

      <div className="mt-2 flex flex-row gap-2">
        {coupons.map((coupon) => {
          return (
            <button
              key={coupon.code}
              type="button"
              className="rounded-full border border-disabled px-3 py-1 text-sm text-secondary transition-all hover:border-primary hover:text-primary dark:hover:border-contrast dark:hover:text-contrast"
              onClick={() => {
                applyCoupon(coupon.code);
              }}
              disabled={isProcessing}
            >
              {coupon.code}
            </button>
          );
        })}
      </div>
    </div>
  );
};

export default CouponField;
