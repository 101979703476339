import { IconCross } from '@mindkit/icons';
import clsx from 'clsx';

import IconButton from '../IconButton/IconButton';
import { ModalContent, ModalContentProps, useModalContext } from '../ModalCore';

// ToDo: Add ref forwarding
const ClassicContainer: React.FC<ModalContentProps> = ({ className, children, ...props }) => {
  const { setOpen } = useModalContext();

  return (
    <ModalContent
      {...props}
      wrapperClassName="fixed inset-0 overflow-auto flex flex-col items-center pb-10 pt-10 px-3"
      className={clsx(
        'relative min-w-[320px] max-w-full rounded-2xl bg-level1 px-5 pb-4 pt-9',
        className,
      )}
      transitionConfig={{
        initial: { opacity: 0, transform: 'translateY(-20px)' },
        close: { opacity: 0, transform: 'translateY(-20px)' },
        duration: { open: 200, close: 200 },
      }}
    >
      <IconButton
        className="absolute right-4 top-4"
        onClick={(e) => setOpen(false, e.nativeEvent, 'click')}
      >
        <IconCross />
      </IconButton>

      {children}
    </ModalContent>
  );
};

export default ClassicContainer;
