import { Input, useShakeAnim } from '@mindkit';
import clsx from 'clsx';
import React, { useMemo, useState } from 'react';

const CodeInput: React.FC<{
  codeLength: number;
  onSubmit: (code: string) => Promise<unknown>;
  className?: string;
}> = ({ codeLength, onSubmit, className }) => {
  const [shakeClass, shakeMe] = useShakeAnim();

  const inputRefs = useMemo(
    () => Array.from({ length: codeLength }, () => React.createRef<HTMLInputElement>()),
    [codeLength],
  );

  const [isDisabled, setIsDisabled] = useState(false);

  const trySubmit = async () => {
    const code = inputRefs.map((ref) => ref.current?.value).join('');
    if (code.length !== codeLength) {
      return;
    }

    inputRefs.map((ref) => ref.current?.blur());

    try {
      setIsDisabled(true);
      await onSubmit(code);
    } catch (e) {
      shakeMe();

      inputRefs.forEach((ref) => {
        if (ref.current) {
          ref.current.value = '';
        }
      });
      setTimeout(() => {
        inputRefs[0].current?.focus();
      }, 500);
    } finally {
      setIsDisabled(false);
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    const input = e.target as HTMLInputElement;
    const inputIndex = Number(input.name);
    const prevIndex = inputIndex - 1;

    if (!input.value.length && e.key === 'Backspace' && prevIndex >= 0) {
      inputRefs[prevIndex].current?.focus();
    }
    if (e.key === 'Enter') {
      trySubmit();
    }
  };

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.value.length > 0) {
      e.target.value =
        e.target.value.length > 1 ? e.target.value.slice(1, 2) : e.target.value.slice(0, 1);

      if (!e.target.value.match(/[0-9]/)) {
        e.target.value = '';
      }
    }

    if (e.target.value) {
      const inputIndex = Number(e.target.name);
      const nextIndex = inputIndex + 1;

      if (nextIndex < codeLength) {
        inputRefs[nextIndex].current?.focus();
      } else {
        trySubmit();
      }
    }
  };

  const handlePaste = (e: React.ClipboardEvent<HTMLInputElement>) => {
    e.preventDefault();

    const paste = e.clipboardData.getData('text');
    const code = paste.replace(/\D/g, '').slice(0, codeLength);
    if (!code) {
      return;
    }

    const input = e.target as HTMLInputElement;
    const inputIndex = Number(input.name);
    for (let i = 0; i < code.length; i++) {
      const inputEl = inputRefs[inputIndex + i]?.current;
      if (inputEl) {
        inputEl.value = code[i];
      }
    }

    const lastInputIndex = Math.min(inputIndex + code.length - 1, codeLength - 1);
    inputRefs[lastInputIndex].current?.focus();

    if (lastInputIndex === codeLength - 1) {
      trySubmit();
    }
  };

  return (
    <div className={clsx('flex flex-row gap-2 self-center', shakeClass, className)}>
      {Array.from({ length: codeLength }, (_, i) => (
        <Input
          key={i}
          ref={inputRefs[i]}
          className="h-16 min-w-0 !px-1 text-center text-xl"
          name={String(i)}
          autoFocus={i === 0}
          inputMode="numeric"
          pattern="[0-9]"
          autoComplete="off"
          autoCorrect="off"
          onInput={handleInputChange}
          onKeyDown={handleKeyDown}
          isDisabled={isDisabled}
          onPaste={handlePaste}
          isError={Boolean(shakeClass)}
        />
      ))}
    </div>
  );
};

export default CodeInput;
