import clsx from 'clsx';
import React, { useCallback } from 'react';

import { haptic } from '../../haptic';

type Props = React.ComponentProps<'button'> & {
  isDisabled?: boolean;
  children?: React.ReactNode;
  className?: string;
  color?: 'neutral' | 'primary' | 'danger';
  size?: 'sm' | 'md' | 'lg';
  haptic?: 'default' | 'success' | 'warning' | 'error' | 'none';
};

const IconButton = React.forwardRef<HTMLButtonElement, Props>(
  (
    {
      isDisabled,
      onClick,
      className,
      children,
      size = 'md',
      color = 'neutral',
      haptic: hapticType = 'default',
      ...other
    },
    ref,
  ) => {
    const handleClick = useCallback(
      (e: React.MouseEvent<HTMLButtonElement>) => {
        if (hapticType === 'default') {
          haptic.selection();
        } else if (hapticType === 'success' || hapticType === 'warning' || hapticType === 'error') {
          haptic.notification(hapticType);
        }

        onClick?.(e);
      },
      [onClick, hapticType],
    );

    return (
      <button
        ref={ref}
        type="button"
        className={clsx(
          'inline-flex flex-row items-center justify-center transition-all duration-200 ease-out',
          { 'h-9 w-9': size === 'md' },

          !isDisabled && {
            'hover:opacity-70': color === 'neutral',
            'hover:text-primary': color === 'primary',
            'hover:text-danger': color === 'danger',
          },

          { 'active:scale-90': !isDisabled },
          { 'cursor-default text-disabled': isDisabled },
          className,
        )}
        disabled={isDisabled}
        onClick={handleClick}
        {...other}
      >
        {children}
      </button>
    );
  },
);
IconButton.displayName = 'IconButton';

export default IconButton;
