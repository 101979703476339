import clsx from 'clsx';
import React from 'react';

const SkeletonPlane: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <div role="status" className={clsx('skeleton-plane animate-pulse bg-disabled', className)}>
      <span className="sr-only left-0 top-0">Loading placeholder...</span>
    </div>
  );
};

export default SkeletonPlane;
