import { MentorInCatalog, MentorManager } from 'modules/mentor/model';

export const filterCatalog = (mentors: MentorInCatalog[], search: string) => {
  if (!search) {
    return mentors;
  }

  const getSearchPriority = (mentor: MentorInCatalog, search: string) => {
    const name = mentor.name.toLowerCase();
    const description = (mentor.description || '').toLowerCase();
    const expertise = (mentor.expertise || '').toLowerCase();
    const about = (mentor.about || '').toLowerCase();

    const searchLower = search.toLowerCase();

    if (name.includes(searchLower)) {
      return { priority: 10, mentor };
    }

    if (description.includes(searchLower)) {
      return { priority: 5, mentor };
    }

    if (expertise.includes(searchLower)) {
      return { priority: 3, mentor };
    }

    if (about.includes(searchLower)) {
      return { priority: 3, mentor };
    }

    return { priority: -1, mentor };
  };

  const searchResults = mentors
    .map((mentor) => getSearchPriority(mentor, search))
    .filter(({ priority }) => priority > 0)
    .sort((a, b) => b.priority - a.priority)
    .map(({ mentor }) => mentor);

  return searchResults;
};

export const getMentorManagerStatus = (mentor: MentorManager) => {
  const isInfoFilled = Boolean(
    mentor.profileDetails.description &&
      mentor.profileDetails.photoUrl &&
      mentor.profileDetails.expertise,
  );

  const zoomIntegration = mentor.integrations.find((integration) => integration.type === 'zoom');
  const isZoomConnected = zoomIntegration?.active === true;

  const calendarIntegration = mentor.integrations.find(
    (integration) => integration.type === 'calendar',
  );
  const isCalendarConnected = calendarIntegration?.active === true;

  const isReady = isInfoFilled && isZoomConnected;

  return {
    isInfoFilled,
    isZoomConnected,
    isCalendarConnected,
    isReady,

    zoomIntegration,
    calendarIntegration,
  };
};
