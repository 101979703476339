import * as React from 'react';

import usePopover, { PopoverOptions } from './usePopover';

type ContextType = ReturnType<typeof usePopover> | null;

const PopoverContext = React.createContext<ContextType>(null);

export const usePopoverContext = () => {
  const context = React.useContext(PopoverContext);

  if (context == null) {
    throw new Error('Popover components must be wrapped in <Popover />');
  }

  return context;
};

export function Popover({
  children,

  ...restOptions
}: {
  children: React.ReactNode;
} & PopoverOptions) {
  const popover = usePopover({ ...restOptions });

  return <PopoverContext.Provider value={popover}>{children}</PopoverContext.Provider>;
}
