import { useQueries, UseQueryResult } from '@tanstack/react-query';
import { Dayjs } from 'dayjs';
import { timeslotActions } from 'modules/timeslot';
import { ManagedTimeslot, timeslotHelpers } from 'modules/timeslot/model';
import { Week } from 'modules/timeslot/model/weekHelpers';
import { useCallback } from 'react';

interface ManagerWeek {
  id: string;
  start: Dayjs;
  end: Dayjs;
  isError: boolean;
  isLoaded: boolean;
  timeslots: ManagedTimeslot[];

  timeslotsCount: number;
  availableCount: number;
}

const useManagerWeeks = (weeks: Week[], currentWeekId?: string) => {
  const combine = useCallback(
    (results: UseQueryResult<ManagedTimeslot[], Error>[]) => {
      const managerWeeks: ManagerWeek[] = results.map((query, i) => {
        const week = weeks[i];
        if (query.isError) {
          return {
            id: week.id,
            start: week.start,
            end: week.end,

            isError: true,
            isLoaded: false,
            timeslots: [],
            timeslotsCount: 0,
            availableCount: 0,
          };
        }
        if (query.isPending) {
          return {
            id: week.id,
            start: week.start,
            end: week.end,

            isError: false,
            isLoaded: false,
            timeslots: [],
            timeslotsCount: 0,
            availableCount: 0,
          };
        }
        const timeslots = query.data;
        const availableTimeslots = timeslots.filter((t) => timeslotHelpers.isTimeslotAvailable(t));
        return {
          id: week.id,
          start: week.start,
          end: week.end,

          isError: false,
          isLoaded: true,
          timeslots,
          timeslotsCount: timeslots.length,
          availableCount: availableTimeslots.length,
        };
      });

      return managerWeeks;
    },
    [weeks],
  );

  const managerWeeks = useQueries({
    queries: weeks.map((week) => ({
      queryKey: ['manager-timeslots', week.id],
      queryFn: () => timeslotActions.getManagedTimeslots(week.start, week.end),
      refetchOnMount: currentWeekId === week.id,
      refetchOnReconnect: currentWeekId === week.id,
      refetchOnWindowFocus: currentWeekId === week.id,
    })),
    combine,
  });

  return managerWeeks;
};

export default useManagerWeeks;
