import { haptic, Modal } from '@mindkit';
import { Mentor } from 'modules/mentor/model';
import { useEffect } from 'react';

import MentorDetails from '../MentorDetails/MentorDetails';
import MentorInfo from '../MentorInfo/MentorInfo';

const MentorDetailsModal: React.FC<{ isOpen: boolean; onClose: () => void; mentor: Mentor }> = ({
  isOpen,
  onClose,
  mentor,
}) => {
  useEffect(() => {
    if (isOpen) {
      haptic.notification('success');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <MentorInfo profileDetails={mentor.profileDetails} />
      <MentorDetails mentor={mentor} className="mt-4" />
    </Modal>
  );
};

export default MentorDetailsModal;
