import { Button, Modal } from '@mindkit';
import { IconCopy } from '@mindkit/icons';
import clsx from 'clsx';
import { Recording } from 'modules/recording/model';
import copyToClipboard from 'modules/shared/helpers/copyToClipboard';
import { useState } from 'react';
import { ButtonProps } from 'shared/mindkit/components/Button/Button';

import cm from './WatchRecordingButton.module.css';

const WatchRecordingButton: React.FC<ButtonProps & { recording: Recording }> = ({
  recording,
  ...other
}) => {
  const { name, subtitle, description, link, passcode } = recording;

  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const handleCopyPasscode = () => {
    copyToClipboard(passcode!);
  };

  return (
    <>
      <Button {...other} onClick={handleOpenModal}>
        Watch recording
      </Button>

      <Modal isOpen={isModalOpen} onClose={handleCloseModal} className="w-[540px]">
        <h2 className="mb-2">{name}</h2>
        {subtitle && <p>{subtitle}</p>}

        <div className="my-6">
          {passcode && (
            <div className="mb-1 flex flex-row items-center gap-1">
              <p>Passcode:</p>
              <button
                type="button"
                className="flex flex-row items-center gap-1 text-primary hover:text-primary-active active:text-primary-active"
                onClick={handleCopyPasscode}
              >
                <span>{passcode}</span>
                <IconCopy className="h-4 w-4" />
              </button>
            </div>
          )}

          <Button color="primary" size="sm" as="a" href={link} target="_blank" rel="noreferrer">
            Watch in zoom
          </Button>
        </div>

        <p
          className={clsx('my-css whitespace-pre-wrap break-words', cm.htmlMessage)}
          dangerouslySetInnerHTML={{ __html: description }}
        />
      </Modal>
    </>
  );
};

export default WatchRecordingButton;
