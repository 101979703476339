import { Button } from '@mindkit';
import { IconAddSm } from '@mindkit/icons';
import { Dayjs } from 'dayjs';
import CreateTimeslotModal from 'modules/timeslot/components/CreateTimeslotModal/CreateTimeslotModal';
import { useState } from 'react';

const CreateTimeslotButton: React.FC<{ initialDate?: Dayjs; className?: string }> = ({
  initialDate,
  className,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <>
      <CreateTimeslotModal
        isOpen={isModalOpen}
        onClose={handleCloseModal}
        initialDate={initialDate}
      />
      <Button className={className} onClick={handleOpenModal}>
        Add slot
        <IconAddSm className="ml-1" />
      </Button>
    </>
  );
};

export default CreateTimeslotButton;
