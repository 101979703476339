import clsx from 'clsx';
import { Timeslot, TimeslotCategory, timeslotHelpers } from 'modules/timeslot/model';
import React from 'react';
import TimezoneLabel from 'shared/components/TimezoneLabel/TimezoneLabel';

const TimeslotPreview: React.FC<{ timeslot: Timeslot; className?: string; isSmall?: boolean }> = ({
  timeslot,
  className,
  isSmall,
}) => {
  const { mentor } = timeslot;
  const isEvent = timeslot.category === TimeslotCategory.EVENT;

  const { date, time } = timeslotHelpers.getTimeslotFormatted(timeslot, timeslot.types[0]);

  const title = isEvent ? (
    timeslot.event?.name
  ) : (
    <>
      Consultation
      <br />
      with {mentor.displayName}
    </>
  );

  return (
    <div className={clsx('', className)}>
      {isSmall ? <h3 className="mb-2">{title}</h3> : <h2 className="mb-2">{title}</h2>}
      <p>
        {date}: {time}
      </p>
      <p>
        Timezone: <TimezoneLabel />
      </p>
    </div>
  );
};

export default TimeslotPreview;
