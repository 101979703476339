import { haptic } from '@mindkit';
import clsx from 'clsx';
import { Mentor } from 'modules/mentor/model';
import React, { useMemo } from 'react';

import MentorPackItem from '../MentorPackItem/MentorPackItem';
import MentorPacksModal from '../MentorPacksModal/MentorPacksModal';

const MentorPacksWidget: React.FC<{
  mentor: Mentor;
  className?: string;
  collapsed?: boolean;
}> = ({ mentor, className, collapsed }) => {
  const { packs } = mentor;

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const handleSeeAllClick = () => {
    haptic.selection();
    setIsModalOpen(true);
  };
  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const randomPack = useMemo(() => {
    if (!packs.length) {
      return null;
    }
    const topPriority = packs.reduce(
      (acc, pack) => (pack.listPriority > acc ? pack.listPriority : acc),
      0,
    );
    const topPriorityPacks = packs.filter((pack) => pack.listPriority === topPriority);
    const randomPackIndex =
      topPriorityPacks.length > 1 ? Math.floor(Math.random() * topPriorityPacks.length) : 0;

    return topPriorityPacks[randomPackIndex];
  }, [packs]);

  if (!packs.length) {
    return null;
  }

  if (collapsed) {
    return (
      <div className={clsx('', className)}>
        <div className="mb-1 flex flex-row justify-between">
          <p>Packages</p>

          {packs.length > 1 && (
            <>
              <button className="text-secondary" type="button" onClick={handleSeeAllClick}>
                View all
              </button>
              <MentorPacksModal packs={packs} isOpen={isModalOpen} onClose={handleCloseModal} />
            </>
          )}
        </div>
        <MentorPackItem pack={randomPack!} />
      </div>
    );
  }

  return (
    <div className={clsx('flex flex-col gap-3', className)}>
      <h2>Packages</h2>
      {packs.map((mentorPack) => (
        <MentorPackItem key={mentorPack.id} pack={mentorPack} />
      ))}
    </div>
  );
};

export default MentorPacksWidget;
