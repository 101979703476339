import dayjs from 'dayjs';
import React, { useMemo } from 'react';

const nbsp = '\u00A0';

const TimezoneLabel: React.FC<{ className?: string; isFull?: boolean }> = ({
  className,
  isFull = false,
}) => {
  const timezoneLabel = useMemo(() => {
    const utcOffset = Math.round(dayjs().utcOffset() / 60);
    const timezoneName = dayjs.tz.guess().split('_').join(' ');
    const utcLabel = `UTC${utcOffset >= 0 ? '+' : ''}${utcOffset}`;
    return isFull ? `${timezoneName}${nbsp}${utcLabel}` : utcLabel;
  }, [isFull]);

  return <span className={className}>{timezoneLabel}</span>;
};

export default TimezoneLabel;
