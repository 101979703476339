import { ApiService } from 'services/api';
import { ErrorService } from 'services/error';

const sendAnalyticsEvent = async (data: Record<string, unknown>) => {
  await ApiService.apiInstance.post('analytics/send-event', data);
};

let metaData = {
  userId: 'anonymous',

  tgUserId: 'not init',
  tgUsername: 'not init',
  tgQueryId: 'not init',
  // botId: 'not init',
};

export const AnalyticsService = {
  setMetaData: (data: {
    userId?: string;

    tgUserId?: string;
    tgUsername?: string;
    tgQueryId?: string;
    referral?: string;
    // botId?: string;
  }) => {
    metaData = { ...metaData, ...data };

    if (data.userId) {
      window.gtag('config', import.meta.env.VITE_GA_ID, { user_id: data.userId });
    }
  },

  sendEvent: async (event: string, data: Record<string, unknown> = {}) => {
    const analyticsData = {
      event,
      timestamp: new Date().toISOString(),

      ...metaData,

      custom: {
        ...data,
      },
    };

    window.gtag('event', event, data);

    const trySendAnalytics = async (attempts: number) => {
      try {
        await sendAnalyticsEvent(analyticsData);
      } catch (e) {
        ErrorService.captureException(e);

        if (attempts > 0) {
          setTimeout(async () => {
            await trySendAnalytics(attempts - 1);
          }, 2000);
        }
      }
    };
    trySendAnalytics(2);
  },
};
