import { Dayjs } from 'dayjs';
import { Timeslot, TimeslotCategory, timeslotHelpers } from 'modules/timeslot/model';
import { Week } from 'modules/timeslot/model/weekHelpers';
import { useMemo } from 'react';

import useTimeslots from './useTimeslots';

const isWeekLoaded = (week: Week, loadedRange: { start: Dayjs; end: Dayjs }) => {
  const isLoaded =
    week.start.isBetween(loadedRange.start, loadedRange.end, 'day', '[]') &&
    week.end.isBetween(loadedRange.start, loadedRange.end, 'day', '[]');

  return isLoaded;
};

interface TimeslotWeek {
  id: string;
  start: Dayjs;
  end: Dayjs;
  isError: boolean;
  isLoaded: boolean;
  timeslots: Timeslot[];
  events: Timeslot[];
  availableCount: number;
}

const useCalendar = (mentorId: string, weeks: Week[]) => {
  const { data, isError } = useTimeslots(mentorId, weeks[0].start, weeks[weeks.length - 1].end);

  const timeslotsByWeeks = useMemo((): TimeslotWeek[] => {
    if (!data || isError) {
      return weeks.map((week) => {
        return {
          id: `${week.start.format('YYYY-MM-DD')}--${week.end.format('YYYY-MM-DD')}`,
          start: week.start,
          end: week.end,
          isLoaded: false,
          isError,
          timeslots: [],
          events: [],
          availableCount: 0,
        };
      });
    }

    const { timeslots, range } = data;

    return weeks.map((week) => {
      const weekAllTimeslots = timeslotHelpers.filterWeekTimeslots(timeslots, week.start, week.end);
      const isLoaded = isWeekLoaded(week, range);

      const weekTimeslots: Timeslot[] = [];
      const weekEvents: Timeslot[] = [];
      weekAllTimeslots.forEach((timeslot) => {
        if (timeslot.category === TimeslotCategory.EVENT) {
          weekEvents.push(timeslot);
        } else {
          weekTimeslots.push(timeslot);
        }
      });

      return {
        id: week.id,
        start: week.start,
        end: week.end,
        isLoaded,
        isError: false,
        timeslots: weekTimeslots,
        events: weekEvents,
        availableCount: weekAllTimeslots.filter(timeslotHelpers.isTimeslotAvailable).length,
      };
    });
  }, [weeks, data, isError]);

  return { timeslotsByWeeks };
};

export default useCalendar;
