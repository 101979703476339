import clsx from 'clsx';
import React from 'react';

const TextLine: React.FC<{ className?: string }> = ({ className }) => (
  <div
    className={clsx('my-[calc((1lh-1em)/2)] box-content h-[1em] rounded-md bg-disabled', className)}
  />
);

const SkeletonText: React.FC<{
  className?: string;
  lines?: number;
}> = ({ lines = 4, className }) => {
  return (
    <div role="status" className={clsx('skeleton-text animate-pulse', className)}>
      {lines > 1 ? (
        <>
          {Array.from({ length: lines - 1 }).map((_, i) => (
            // eslint-disable-next-line react/no-array-index-key
            <TextLine key={i} className="w-full" />
          ))}
          <TextLine className="w-2/3" />
        </>
      ) : (
        <TextLine className="w-full" />
      )}

      <span className="sr-only left-0 top-0">Loading text placeholder...</span>
    </div>
  );
};

export default SkeletonText;
