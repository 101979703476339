import * as paymentApi from 'modules/payment/api';
import { UnfinishedCheckout } from 'modules/payment/model';
import { QueryService } from 'services/query';

export const getUnfinishedCheckout = async (mentorId?: string) => {
  const checkout = await paymentApi.getUnfinishedCheckout(mentorId);

  return checkout;
};

export const dropUnfinishedCheckout = (checkoutId?: string) => {
  if (checkoutId) {
    QueryService.getClient().setQueriesData<UnfinishedCheckout | null>(
      { queryKey: ['unfinishedCheckout'] },
      (data) => {
        if (data?.id === checkoutId) {
          return null;
        }

        return data;
      },
    );
    QueryService.getClient().invalidateQueries({ queryKey: ['unfinishedCheckout'] });
  } else {
    QueryService.getClient().removeQueries({ queryKey: ['unfinishedCheckout'] });
  }
};

export const invalidateUnfinishedCheckout = (mentorId?: string) => {
  const queryKey = mentorId ? ['unfinishedCheckout', 'mentor', mentorId] : ['unfinishedCheckout'];
  QueryService.getClient().invalidateQueries({ queryKey });
};
