import { useMergeRefs } from '@floating-ui/react';
import React from 'react';

import { usePopoverContext } from './PopoverContext';

interface PopoverTriggerProps {
  children: React.ReactNode;
}

const PopoverTrigger = React.forwardRef<
  HTMLElement,
  React.HTMLProps<HTMLElement> & PopoverTriggerProps
>(function PopoverTrigger({ children, ...props }, propRef) {
  const context = usePopoverContext();
  const childrenRef = (children as any).ref;
  const ref = useMergeRefs([context.refs.setReference, propRef, childrenRef]);

  if (!React.isValidElement(children)) {
    throw new Error('PopoverTrigger requires a valid React element as a child');
  }

  return React.cloneElement(
    children,
    context.getReferenceProps({
      ref,
      ...props,
      ...children.props,
      'data-state': context.open ? 'open' : 'closed',
    }),
  );
});

export default PopoverTrigger;
