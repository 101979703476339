import { Button, IconButton, IfScreen } from '@mindkit';
import { IconCross } from '@mindkit/icons';
import clsx from 'clsx';
import { mentorHelpers, MentorManager } from 'modules/mentor/model';
import React, { useState } from 'react';

import StepCard from './StepCard';

const MentorSetupProfile: React.FC<{ mentor: MentorManager; className?: string }> = ({
  mentor,
  className,
}) => {
  const [isClosed, setIsClosed] = useState(
    Boolean(localStorage.getItem('mentorSetupProfileClosed')),
  );
  const handleClose = () => {
    localStorage.setItem('mentorSetupProfileClosed', 'true');
    setIsClosed(true);
  };

  const status = mentorHelpers.getMentorManagerStatus(mentor);
  const isSetupCompleted =
    status.isInfoFilled && status.isZoomConnected && status.isCalendarConnected;
  const isOnlyCalendarLeft =
    status.isInfoFilled && status.isZoomConnected && !status.isCalendarConnected;

  if (isSetupCompleted || (isOnlyCalendarLeft && isClosed)) {
    return null;
  }

  const handleConnectZoom = () => {
    window.open(status.zoomIntegration!.authUrl!, '_blank');
  };
  const handleConnectCalendar = () => {
    window.open(status.calendarIntegration!.authUrl!, '_blank');
  };

  const step1 = (
    <StepCard
      title="Step 1"
      doneSlot={
        <div>
          <p>Awesome! Your profile info is uploaded!</p>
        </div>
      }
      isDone
    />
  );
  const step2 = (
    <StepCard
      title="Step 2"
      doneSlot={
        <div>
          <p>
            Great! Your Zoom is connected!
            <br />
            Now you can add your slots.
          </p>
        </div>
      }
      notDoneSlot={
        <div className="flex flex-row justify-between gap-4">
          <p>To add slots connect your Zoom account</p>
          <Button variant="outlined" onClick={handleConnectZoom}>
            Connect
          </Button>
        </div>
      }
      isDone={status.isZoomConnected}
    />
  );
  const step3 = (
    <StepCard
      title="Step 3"
      doneSlot={
        <div>
          <p>Great! Your calendar account is connected!</p>
        </div>
      }
      notDoneSlot={
        <div className="flex flex-row justify-between gap-4">
          <p>Sync your calendar to add booked slots</p>
          <Button variant="outlined" onClick={handleConnectCalendar}>
            Connect
          </Button>
        </div>
      }
      isOptional
      isDone={status.isCalendarConnected}
    />
  );

  return (
    <section className={clsx('page-section', className)}>
      <div className="page-section-header flex flex-row items-center justify-between">
        <h2>Setup profile</h2>
        {isOnlyCalendarLeft && (
          <IconButton
            className="self-center"
            aria-label="Close setup profile"
            color="danger"
            onClick={handleClose}
          >
            <IconCross />
          </IconButton>
        )}
      </div>

      <IfScreen
        mobile={!status.isZoomConnected ? step2 : step3}
        desktop={
          <div className="grid grid-cols-3 gap-4">
            {step1}
            {step2}
            {status.isZoomConnected ? step3 : null}
          </div>
        }
      />
    </section>
  );
};

export default MentorSetupProfile;
