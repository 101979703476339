import { bookingAdapter } from 'modules/booking/api';
import { userPackAdapter } from 'modules/pack/api';
import { recordingOwnedAdapter } from 'modules/recording/api';
import { Profile, User } from 'modules/user/model';

import { ProfileResponse } from './userApiInterfaces';

export const profileAdapter = (profileResponse: ProfileResponse): Profile => {
  const user: User = {
    id: profileResponse.id,
    language: profileResponse.language || 'en',
    upcomingBookingsCount: profileResponse.upcomingBookingsCount,

    firstName: profileResponse.firstName ?? undefined,
    email: profileResponse.email ?? undefined,

    isMentor: profileResponse.isMentor,
  };

  return {
    user,
    bookings: profileResponse.bookings.map((booking) => bookingAdapter(booking)),
    packs: profileResponse.userPackages.map((pack) => userPackAdapter(pack)),
    recordings: profileResponse.recordings.map((recording) => recordingOwnedAdapter(recording)),
  };
};
