import { Loader } from '@mindkit';
import BookingsWidget from 'modules/booking/components/BookingsWidget/BookingsWidget';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import MentorDetails from 'modules/mentor/components/MentorDetails/MentorDetails';
import MentorDetailsButton from 'modules/mentor/components/MentorDetailsButton/MentorDetailsButton';
import MentorInfo from 'modules/mentor/components/MentorInfo/MentorInfo';
import MentorPageHelmet from 'modules/mentor/components/MentorPageHelmet/MentorPageHelmet';
import useFastCheckout from 'modules/mentor/hooks/useFastCheckout';
import useMentor from 'modules/mentor/hooks/useMentor';
import MentorPacksWidget from 'modules/pack/components/MentorPacksWidget/MentorPacksWidget';
import UnfinishedCheckoutItem from 'modules/payment/components/UnfinishedCheckoutItem/UnfinishedCheckoutItem';
import useUnfinishedCheckout from 'modules/payment/hooks/useUnfinishedCheckout';
import EventAndRecordingsEntry from 'modules/recording/components/EventAndRecordingsEntry/EventAndRecordingsEntry';
import ShareReferralButton from 'modules/referral/components/ShareReferralButton/ShareReferralButton';
import Calendar from 'modules/timeslot/components/Calendar/Calendar';
import { timeslotHelpers } from 'modules/timeslot/model';
import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { AnalyticsService } from 'services/analytics';

const MentorPage: React.FC = () => {
  const { mentorId } = useParams();
  if (!mentorId) {
    throw new Error('Unable to get mentorId from URL');
  }

  const { data: mentor, isSuccess, isPending, isError } = useMentor(mentorId);

  useFastCheckout(mentor);

  const { data: unfinishedCheckout } = useUnfinishedCheckout(mentorId);

  useEffect(() => {
    if (isSuccess) {
      const availableTimeslots = mentor.timeslots.filter(timeslotHelpers.isTimeslotAvailable);
      AnalyticsService.sendEvent('mentor_load_success', {
        mentorId,
        availableTimeslots: availableTimeslots.length,
      });
    }

    // Exclude mentor from deps to send event only once
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess, mentorId]);

  if (isError) {
    return <p className="m-auto text-danger">Something went wrong ...</p>;
  }

  if (isPending) {
    return <Loader className="m-auto" />;
  }

  return (
    <PageLayout
      bodyClassName="gap-4 desk:gap-8 relative"
      sidebarClassName="gap-8"
      mobileBody={
        <>
          <MentorPageHelmet mentor={mentor} />
          <ShareReferralButton mentorId={mentor.id} className="absolute right-2 top-2" />

          <MentorDetailsButton mentor={mentor}>
            <MentorInfo profileDetails={mentor.profileDetails} />
          </MentorDetailsButton>

          <MentorPacksWidget mentor={mentor} collapsed />

          {mentor.hasEventsAndRecordings && (
            <EventAndRecordingsEntry mentorId={mentor.id} collapsed />
          )}

          {unfinishedCheckout ? (
            <UnfinishedCheckoutItem unfinishedCheckout={unfinishedCheckout} />
          ) : (
            <BookingsWidget mentorId={mentor.id} />
          )}

          <Calendar mentorId={mentor.id} />
        </>
      }
      deskBody={
        <>
          <MentorPageHelmet mentor={mentor} />
          <div className="page-section flex flex-col gap-8">
            <ShareReferralButton mentorId={mentor.id} className="absolute right-4 top-4" />
            <MentorInfo profileDetails={mentor.profileDetails} />
            <Calendar mentorId={mentor.id} />
          </div>
        </>
      }
      deskSidebar={
        <>
          {unfinishedCheckout && <UnfinishedCheckoutItem unfinishedCheckout={unfinishedCheckout} />}
          <BookingsWidget mentorId={mentor.id} count={2} />
          {mentor.hasEventsAndRecordings && <EventAndRecordingsEntry mentorId={mentor.id} />}
          <MentorPacksWidget mentor={mentor} />
          <MentorDetails mentor={mentor} className="page-section" />
        </>
      }
    />
  );
};

export default MentorPage;
