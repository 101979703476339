import { Button } from '@mindkit';
import React from 'react';
import { Link } from 'react-router-dom';

const TestUIPage: React.FC = () => {
  const [buttonSize, setButtonSize] = React.useState<'sm' | 'md'>('md');

  return (
    <div className="flex w-full flex-col p-6">
      <h1>Test UI</h1>

      <div className="flex flex-col items-start gap-2 border border-primary p-4">
        <h2>Buttons</h2>
        <div className="mb-4 flex flex-row items-center gap-2">
          <h4>Size:</h4>
          <Button
            onClick={() => setButtonSize('sm')}
            variant={buttonSize === 'sm' ? 'solid' : 'outlined'}
          >
            SM
          </Button>
          <Button
            onClick={() => setButtonSize('md')}
            variant={buttonSize === 'md' ? 'solid' : 'outlined'}
          >
            MD
          </Button>
        </div>

        <div className="flex flex-col items-start gap-2">
          <h4>Solid</h4>
          <Button size={buttonSize} variant="solid">
            BUTTON
          </Button>
          <Button size={buttonSize} variant="solid" isDisabled>
            BUTTON
          </Button>

          <h4>Outlined</h4>
          <Button size={buttonSize} variant="outlined">
            BUTTON
          </Button>
          <Button size={buttonSize} variant="outlined" isDisabled>
            BUTTON
          </Button>

          <div className="flex flex-col gap-2 bg-primary p-4">
            <h4 className="text-primary-foreground">Inversed</h4>
            <Button size={buttonSize} variant="outlined" color="primary-inverse">
              BUTTON
            </Button>
            <Button size={buttonSize} variant="outlined" color="primary-inverse" isDisabled>
              BUTTON
            </Button>
          </div>

          <h4>Light</h4>
          <Button size={buttonSize} variant="light">
            BUTTON
          </Button>
          <Button size={buttonSize} variant="light" isDisabled>
            BUTTON
          </Button>

          <h4>Polimorph (buttons as links)</h4>
          <Button
            size={buttonSize}
            variant="solid"
            as="a"
            href="https://google.com"
            target="_blank"
          >
            GOOGLE
          </Button>
          <Button size={buttonSize} variant="solid" as={Link} to="/">
            HOME
          </Button>
        </div>
      </div>

      <div className="flex flex-col items-start gap-2 border border-primary p-4">
        <h1>Headline 1</h1>
        <h2>Headline 2</h2>
        <h3>Headline 3</h3>
        <h4>Headline 4</h4>
        <p>Paragraph text</p>
        <p className="text-sm">Paragraph text sm</p>
        <p className="text-xs">Paragraph text xs</p>
      </div>

      <div className="flex flex-col items-start gap-2 border border-primary p-4">
        <p className="text-default">Body text</p>
        <p className="text-secondary">Secondary text</p>
        <p className="text-disabled">Disabled text</p>
        <p className="text-primary">Primary text</p>
        <p className="text-success">Success text</p>
        <p className="text-danger">Danger text</p>
      </div>
    </div>
  );
};

export default TestUIPage;
