import { IconCheck } from '@mindkit/icons';
import clsx from 'clsx';
import React from 'react';

import cm from './Layout.module.css';

const Layout: React.FC<{
  title: string;
  description: string;
  children: React.ReactNode;
  className?: string;
}> = ({ title, description, children, className }) => {
  return (
    <div
      className={clsx(
        'flex w-full flex-col items-center self-center pb-6 desk:mt-10 desk:max-w-lg desk:rounded-3xl desk:bg-level1',
        cm.container,
        className,
      )}
    >
      <IconCheck className="mb-5 mt-20 h-32 w-32 text-primary desk:mt-28" />

      <h1 className="mb-2 text-primary">{title}</h1>
      <p className="mb-9 px-4 text-center text-primary">{description}</p>

      <div className="flex w-full flex-col items-center px-5">{children}</div>
    </div>
  );
};

export default Layout;
