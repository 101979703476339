import { Button, notify } from '@mindkit';
import { openSignInModal } from 'modules/auth';
import useAuth from 'modules/auth/hooks/useAuth';
import { getPackStylePreset, MentorPack } from 'modules/pack/model';
import usePayment from 'modules/payment/hooks/usePayment';
import React from 'react';

const MentorPackItem: React.FC<{ pack: MentorPack }> = ({ pack }) => {
  const { designDetails, name, price } = pack;

  const { color, icon, oldPrice } = designDetails;
  const { colorPreset, IconPreset } = getPackStylePreset(color, icon);

  const { isAuth } = useAuth();
  const { buyMentorPack } = usePayment();
  const handleBuy = () => {
    if (!isAuth) {
      openSignInModal();
      notify('Please sign in to buy mentor package');
      return;
    }

    buyMentorPack(pack.id);
  };

  return (
    <div
      className="flex flex-row gap-3 rounded-3xl pb-3 pl-4 pr-3 pt-4 text-white"
      style={{
        background: `linear-gradient(to right, ${colorPreset.bgColorStart}, ${colorPreset.bgColorEnd})`,
      }}
    >
      <IconPreset className="h-6 w-6 shrink-0" />
      <div className="flex flex-grow flex-col">
        <p className="mb-4 mr-2 text-xl font-bold">{name}</p>

        <div className="flex flex-row items-center justify-end">
          {oldPrice && <p className="mr-2 text-xs text-white/60 line-through">${oldPrice}</p>}
          <Button
            className="min-w-[80px]"
            variant="outlined"
            color="primary-inverse"
            size="sm"
            onClick={handleBuy}
          >
            ${price}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default MentorPackItem;
