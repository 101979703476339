import WatchRecordingButton from 'modules/recording/components/WatchRecordingButton/WatchRecordingButton';
import { NotOwnedRecording, Recording } from 'modules/recording/model';
import React from 'react';

import BuyRecordingButton from '../BuyRecordingButton/BuyRecordingButton';
import RecordingCard from '../RecordingCard/RecordingCard';

const RecordingItem: React.FC<{
  recording: Recording | NotOwnedRecording;
  isHighlighted?: boolean;
  className?: string;
}> = ({ recording, isHighlighted, className }) => {
  const { name, subtitle } = recording;

  return (
    <RecordingCard
      className={className}
      name={name}
      subtitle={subtitle}
      footer={
        <>
          {recording.duration && <p>{recording.duration} min</p>}

          <div className="flex-grow" />

          {recording.status === 'not_owned' ? (
            <BuyRecordingButton
              recording={recording}
              variant="outlined"
              color={isHighlighted ? 'primary' : 'primary dark:primary-inverse'}
            />
          ) : (
            <WatchRecordingButton
              recording={recording}
              variant="outlined"
              color={isHighlighted ? 'primary' : 'primary dark:primary-inverse'}
            />
          )}
        </>
      }
      isHighlighted={isHighlighted}
    />
  );
};

export default RecordingItem;
