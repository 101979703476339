import MentorPackPreview from 'modules/pack/components/MentorPackPreview/MentorPackPreview';
import { CheckoutPending } from 'modules/payment/model';
import RecordingCheckoutPreview from 'modules/recording/components/RecordingCheckoutPreview/RecordingCheckoutPreview';
import TimeslotPreview from 'modules/timeslot/components/TimeslotPreview/TimeslotPreview';
import React from 'react';

const CheckoutItemPreview: React.FC<{
  checkoutRequest: CheckoutPending['checkoutRequest'];
  className?: string;
  isMinimal?: boolean;
}> = ({ checkoutRequest, className, isMinimal }) => {
  if (checkoutRequest.type === 'timeslot') {
    const { timeslot } = checkoutRequest;

    return <TimeslotPreview timeslot={timeslot} className={className} isSmall={isMinimal} />;
  }

  if (checkoutRequest.type === 'package') {
    const { pack } = checkoutRequest;

    return <MentorPackPreview pack={pack} className={className} isMinimal={isMinimal} />;
  }

  if (checkoutRequest.type === 'recording') {
    const { recording } = checkoutRequest;

    return (
      <RecordingCheckoutPreview recording={recording} className={className} isMinimal={isMinimal} />
    );
  }

  return <h4 className="text-danger">Unknown checkout item</h4>;
};

export default CheckoutItemPreview;
