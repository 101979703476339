import { ApiService } from 'services/api';

import { recordingAdapter, recordingOwnedAdapter } from './recordingApiAdapters';
import { RecordingResponse } from './recordingApiInterfaces';

export const getUserRecordings = async () => {
  const response = await ApiService.apiInstance.get<RecordingResponse[]>('recordings');

  return response.data.map((recording) => recordingOwnedAdapter(recording));
};

export const getMentorRecordings = async (mentorId: string) => {
  const response = await ApiService.apiInstance.get<RecordingResponse[]>('recordings/by-mentor', {
    params: { mentor: mentorId },
  });

  return response.data.map((booking) => recordingAdapter(booking));
};
