import { Button, haptic, Input, Modal, notify, TextArea } from '@mindkit';
import React, { useEffect, useState } from 'react';
import { AnalyticsService } from 'services/analytics';

import isValidEmail from '../../../../shared/helpers/isValidEmail';
import { closeQuestionnaireModal, useQuestionnaireModalStore } from './betaQuestionnaireStore';

const BetaQuestionnaireModal: React.FC = () => {
  const { isOpen, from } = useQuestionnaireModalStore();

  useEffect(() => {
    if (isOpen && from) {
      AnalyticsService.sendEvent('modal_beta_questionnaire_open', { from });
    }
  }, [isOpen, from]);

  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [expertise, setExpertise] = useState('');
  const [description, setDescription] = useState('');

  const handleNameChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
  };
  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(e.target.value);
  };
  const handleExpertiseChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setExpertise(e.target.value);
  };
  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDescription(e.target.value);
  };

  const isFormValid = Boolean(
    name.trim() && isValidEmail(email.trim()) && expertise.trim() && description.trim(),
  );

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    AnalyticsService.sendEvent('modal_beta_questionnaire_btn_submit_click', {
      name,
      email,
      expertise,
      description,
    });

    haptic.notification('success');
    notify('Thanks for your interest!\nWe will notify you when the beta starts.', 'success');

    closeQuestionnaireModal();
  };

  const handleClose = () => {
    AnalyticsService.sendEvent('modal_beta_questionnaire_btn_close_click');

    closeQuestionnaireModal();
  };

  useEffect(() => {
    if (isOpen) {
      AnalyticsService.sendEvent('modal_beta_questionnaire_open');

      setName('');
      setEmail('');
      setExpertise('');
      setDescription('');
    }
  }, [isOpen]);

  return (
    <Modal isOpen={isOpen} onClose={handleClose} type="overlay-classic" className="w-lg">
      <h2 className="pr-8">Hello, please fill this short&nbsp;form.</h2>

      <form className="mt-6 flex flex-col gap-2" onSubmit={handleSubmit}>
        <Input value={name} placeholder="Your Name" onChange={handleNameChange} />
        <Input value={email} placeholder="Your Email" onChange={handleEmailChange} />
        <Input value={expertise} placeholder="Your Expertise" onChange={handleExpertiseChange} />
        <TextArea
          className="max-h-[120px] min-h-[80px] resize-none"
          value={description}
          placeholder="Short info about you and your skills. Please add link to your web page or social network."
          rows={2}
          onChange={handleDescriptionChange}
        />

        <Button className="w-full" isDisabled={!isFormValid} type="submit">
          Let me in beta
        </Button>
      </form>
    </Modal>
  );
};

export default BetaQuestionnaireModal;
