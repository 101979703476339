import { haptic, notify } from '@mindkit';
import useAuth from 'modules/auth/hooks/useAuth';
import { getPlatform } from 'modules/general/actions/generalActions';
import { MentorPack } from 'modules/pack/model';
import { paymentActions } from 'modules/payment';
import { NotOwnedRecording } from 'modules/recording/model';
import { referralActions } from 'modules/referral';
import { Timeslot } from 'modules/timeslot/model';
import { useCallback } from 'react';
import { AnalyticsService } from 'services/analytics';
import { ErrorService } from 'services/error';
import getErrorMessage from 'shared/helpers/getErrorMessage';

import useCheckout from './useCheckout';

const usePayment = () => {
  const { isAuth } = useAuth();

  const { startCheckout } = useCheckout();

  const buyTimeslot = useCallback(
    async (timeslot: Timeslot) => {
      let checkout;
      try {
        checkout = await paymentActions.createTimeslotCheckout(
          timeslot,
          getPlatform(),
          isAuth,
          referralActions.getReferral(),
        );
      } catch (e) {
        ErrorService.captureException(e);
        AnalyticsService.sendEvent('create_checkout_error', {
          productType: 'timeslot',
          productId: timeslot.id,
          errorMessage: getErrorMessage(e),
        });

        notify(getErrorMessage(e), 'error');

        return;
      }

      await startCheckout(checkout);
    },
    [isAuth, startCheckout],
  );

  const buyMentorPack = useCallback(
    async (mentorPackId: string) => {
      let checkout;
      try {
        checkout = await paymentActions.createPackageCheckout(
          mentorPackId,
          getPlatform(),
          isAuth,
          referralActions.getReferral(),
        );
      } catch (e) {
        ErrorService.captureException(e);
        AnalyticsService.sendEvent('create_checkout_error', {
          productType: 'package',
          productId: mentorPackId,
          errorMessage: getErrorMessage(e),
        });

        notify(getErrorMessage(e), 'error');

        return;
      }

      await startCheckout(checkout);
    },
    [isAuth, startCheckout],
  );

  const buyRecording = useCallback(
    async (recordingId: string) => {
      let checkout;
      try {
        checkout = await paymentActions.createRecordingCheckout(
          recordingId,
          getPlatform(),
          isAuth,
          referralActions.getReferral(),
        );
      } catch (e) {
        ErrorService.captureException(e);
        AnalyticsService.sendEvent('create_checkout_error', {
          productType: 'recording',
          productId: recordingId,
          errorMessage: getErrorMessage(e),
        });

        notify(getErrorMessage(e), 'error');

        return;
      }

      await startCheckout(checkout);
    },
    [isAuth, startCheckout],
  );

  const continueCheckout = useCallback(
    async (checkoutId: string) => {
      let checkout;
      try {
        checkout = await paymentActions.renewCheckout(checkoutId, getPlatform());
      } catch (e) {
        ErrorService.captureException(e);

        notify(getErrorMessage(e), 'error');

        return;
      }

      await startCheckout(checkout);
    },
    [startCheckout],
  );

  const cancelCheckout = useCallback(async (checkoutId: string) => {
    try {
      await paymentActions.cancelCheckout(checkoutId);
      notify('Payment cancelled', 'success');
      haptic.notification('success');
    } catch (e) {
      ErrorService.captureException(e);

      notify(getErrorMessage(e), 'error');
    }
  }, []);

  return { buyTimeslot, buyMentorPack, buyRecording, continueCheckout, cancelCheckout };
};

export default usePayment;
