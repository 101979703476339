import { useQuery } from '@tanstack/react-query';
import useAuth from 'modules/auth/hooks/useAuth';
import { getProfile } from 'modules/user/api';

const useProfile = () => {
  const { isAuth } = useAuth();

  return useQuery({
    queryKey: ['profile'],
    queryFn: () => {
      return getProfile();
    },
    enabled: isAuth,
  });
};

export default useProfile;
