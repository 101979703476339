import { flip, offset, Placement } from '@floating-ui/react';
import clsx from 'clsx';
import React from 'react';

import { Popover, PopoverContent, PopoverTrigger } from '../Popover';
import CloseOnClick from './CloseOnClick';
import cm from './Dropdown.module.css';

const Dropdown: React.FC<{
  placement?: Placement;
  trigger: React.ReactNode;
  content: React.ReactNode;
}> = ({ placement, trigger, content }) => {
  return (
    <Popover placement={placement} middleware={[offset(8), flip()]}>
      <PopoverTrigger>{trigger}</PopoverTrigger>

      <PopoverContent
        className={clsx('dropdown-menu rounded-2xl bg-level1 py-2', cm.menuShadow)}
        transitionConfig={{
          initial: { transform: 'translateY(-40%) scaleY(0.4)', opacity: 0 },
          close: { opacity: 0 },
          duration: { open: 200, close: 80 },
        }}
      >
        <CloseOnClick>{content}</CloseOnClick>
      </PopoverContent>
    </Popover>
  );
};

export default Dropdown;
