import { Skeleton } from '@mindkit';
import React from 'react';

import RecordingCard from '../RecordingCard/RecordingCard';

const RecordingItemSkeleton: React.FC<{
  className?: string;
}> = ({ className }) => {
  return (
    <RecordingCard
      className={className}
      name={<Skeleton.Text lines={1} className="w-full max-w-xs text-h3" />}
      subtitle={<Skeleton.Text lines={2} />}
      footer={
        <>
          <div className="flex-grow" />
          <Skeleton.Plane className="h-10 w-[80px] rounded-2xl" />
        </>
      }
    />
  );
};

export default RecordingItemSkeleton;
