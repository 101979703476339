import { haptic, Skeleton } from '@mindkit';
import clsx from 'clsx';
import { Dayjs } from 'dayjs';
import React, { useEffect, useRef } from 'react';

const CalendarTab: React.FC<{
  start: Dayjs;
  end: Dayjs;
  slotsLabel?: string;
  isSelected?: boolean;
  onClick?: () => void;
  className?: string;
  isLoading?: boolean;
}> = ({ start, end, isSelected, onClick, slotsLabel, className, isLoading }) => {
  const label = `${start.format('MMM DD')} - ${end.format('MMM DD')}`;

  const handleClick = () => {
    haptic.selection();

    onClick?.();
  };

  const elRef = useRef<HTMLButtonElement>(null);
  useEffect(() => {
    if (isSelected) {
      elRef.current?.scrollIntoView({ inline: 'center', block: 'nearest', behavior: 'smooth' });
    }
  }, [isSelected]);

  return (
    <button
      ref={elRef}
      type="button"
      role="tab"
      aria-selected={isSelected}
      className={clsx(
        'inline-block rounded-3xl border border-contrast px-2 py-4 text-center',
        {
          'bg-contrast text-contrast-foreground': isSelected,
        },
        className,
      )}
      onClick={handleClick}
    >
      <p className="">{label}</p>
      {isLoading ? (
        <Skeleton.Text className="m-auto w-10 text-sm" lines={1} />
      ) : (
        <p className={clsx('text-sm', isSelected ? 'text-disabled' : 'text-secondary')}>
          {slotsLabel}
        </p>
      )}
    </button>
  );
};

export default CalendarTab;
