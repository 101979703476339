import React from 'react';

export enum PackColorPresetType {
  BLACK = 'black',
  BLUE = 'blue',
  PURPLE = 'purple',
  MINT = 'mint',
}

export enum PackIconPresetType {
  GIFT = 'gift',
  DIAMOND = 'diamond',
}

export interface PackColorPreset {
  bgColorStart: string;
  bgColorEnd: string;
}

export type PackIcon = React.FC<React.SVGProps<SVGSVGElement>>;
