import { bookingAdapter } from 'modules/booking/api';
import { mentorPackAdapter, userPackAdapter } from 'modules/pack/api/packApiAdapters';
import {
  CheckoutFailed,
  CheckoutPaid,
  CheckoutPending,
  Coupon,
  PaymentLink,
  PaymentMethod,
  UnfinishedCheckout,
} from 'modules/payment/model';
import { recordingNotOwnedAdapter, recordingOwnedAdapter } from 'modules/recording/api';
import { timeslotAdapter } from 'modules/timeslot/api';
import assertUnreachable from 'shared/helpers/assertUnreachable';

import {
  CheckoutResponse,
  CouponResponse,
  PaymentLinkResponse,
  UnfinishedCheckoutResponse,
} from './paymentApiInterfaces';

const couponAdapter = (couponResponse: CouponResponse) => {
  const coupon: Coupon = {
    id: couponResponse.id,
    type: couponResponse.type,
    code: couponResponse.code,
    description: couponResponse.description,
    value: couponResponse.value,
  };

  return coupon;
};

export const checkoutAdapter = (checkoutResponse: CheckoutResponse) => {
  if (checkoutResponse.status === 'Pending') {
    let checkoutRequest: CheckoutPending['checkoutRequest'];
    let aggregatedInfo: CheckoutPending['aggregatedInfo'];

    if (checkoutResponse.checkoutRequest.type === 'timeslot') {
      checkoutRequest = {
        type: checkoutResponse.checkoutRequest.type,
        timeslotTypeId: checkoutResponse.checkoutRequest.timeslotTypeId,
        timeslot: timeslotAdapter(checkoutResponse.checkoutRequest.timeslot),
      };

      aggregatedInfo = {
        checkoutId: checkoutResponse.id,
        mentorId: checkoutResponse.checkoutRequest.timeslot.mentor.mentorId,
        productId: checkoutResponse.checkoutRequest.timeslot.id,
        productType: checkoutResponse.checkoutRequest.type,
      };
    } else if (checkoutResponse.checkoutRequest.type === 'package') {
      checkoutRequest = {
        type: checkoutResponse.checkoutRequest.type,
        pack: mentorPackAdapter(checkoutResponse.checkoutRequest.package),
      };

      aggregatedInfo = {
        checkoutId: checkoutResponse.id,
        mentorId: checkoutResponse.checkoutRequest.package.mentor.mentorId,
        productId: checkoutResponse.checkoutRequest.package.id,
        productType: checkoutResponse.checkoutRequest.type,
      };
    } else if (checkoutResponse.checkoutRequest.type === 'recording') {
      checkoutRequest = {
        type: checkoutResponse.checkoutRequest.type,
        recording: recordingNotOwnedAdapter(checkoutResponse.checkoutRequest.recording),
      };

      aggregatedInfo = {
        checkoutId: checkoutResponse.id,
        mentorId: undefined,
        productId: checkoutResponse.checkoutRequest.recording.id,
        productType: checkoutResponse.checkoutRequest.type,
      };
    } else {
      return assertUnreachable(checkoutResponse.checkoutRequest, 'Unknown checkoutRequest type');
    }

    const paymentOptions = checkoutResponse.paymentOptions.map((option) => {
      if (option.type === PaymentMethod.PACKAGE) {
        if (!option.title || !option.productId) {
          throw new Error('Missing title or productId');
        }

        return {
          method: option.type,
          title: option.title,
          productId: option.productId,
        };
      }
      return {
        method: option.type,
      };
    });

    const checkout: CheckoutPending = {
      id: checkoutResponse.id,
      status: checkoutResponse.status,
      emailRequired: checkoutResponse.emailRequired,
      suggestedEmail: checkoutResponse.suggestedEmail ?? undefined,
      policyRequired: checkoutResponse.policyRequired ?? true,
      paymentOptions,
      checkoutRequest,
      mentorId: checkoutResponse.mentorId,
      priceSummary: checkoutResponse.priceSummary,
      aggregatedInfo,

      coupons: checkoutResponse.coupons.map(couponAdapter),
      selectedCoupon: checkoutResponse.selectedCoupon
        ? couponAdapter(checkoutResponse.selectedCoupon)
        : undefined,
    };

    return checkout;
  }

  if (checkoutResponse.status === 'Paid') {
    let checkoutResult: CheckoutPaid['checkoutResult'];
    if (checkoutResponse.checkoutResult.type === 'booking') {
      checkoutResult = {
        type: checkoutResponse.checkoutResult.type,
        booking: bookingAdapter(checkoutResponse.checkoutResult.booking),
      };
    } else if (checkoutResponse.checkoutResult.type === 'userPackage') {
      checkoutResult = {
        type: checkoutResponse.checkoutResult.type,
        pack: userPackAdapter(checkoutResponse.checkoutResult.userPackage),
      };
    } else if (checkoutResponse.checkoutResult.type === 'recording') {
      checkoutResult = {
        type: checkoutResponse.checkoutResult.type,
        recording: recordingOwnedAdapter(checkoutResponse.checkoutResult.recording),
      };
    } else {
      return assertUnreachable(checkoutResponse.checkoutResult, 'Unknown checkoutResult type');
    }

    const checkout: CheckoutPaid = {
      id: checkoutResponse.id,
      status: checkoutResponse.status,
      checkoutResult,
      mentorId: checkoutResponse.mentorId,
      priceSummary: checkoutResponse.priceSummary,
    };

    return checkout;
  }

  if (checkoutResponse.status === 'Failed') {
    const checkout: CheckoutFailed = {
      id: checkoutResponse.id,
      status: checkoutResponse.status,
    };

    return checkout;
  }

  return assertUnreachable(checkoutResponse, 'Unknown checkout status');
};

export const paymentLinkAdapter = (paymentLinkResponse: PaymentLinkResponse) => {
  const paymentLink: PaymentLink = {
    type: paymentLinkResponse.type,
    url: paymentLinkResponse.url,
    expiry: paymentLinkResponse.expiry,
  };

  return paymentLink;
};

export const unfinishedCheckoutAdapter = (checkoutResponse: UnfinishedCheckoutResponse) => {
  let checkoutRequest: UnfinishedCheckout['checkoutRequest'];
  if (checkoutResponse.checkoutRequest.type === 'timeslot') {
    checkoutRequest = {
      type: checkoutResponse.checkoutRequest.type,
      timeslotTypeId: checkoutResponse.checkoutRequest.timeslotTypeId,
      timeslot: timeslotAdapter(checkoutResponse.checkoutRequest.timeslot),
    };
  } else if (checkoutResponse.checkoutRequest.type === 'package') {
    checkoutRequest = {
      type: checkoutResponse.checkoutRequest.type,
      pack: mentorPackAdapter(checkoutResponse.checkoutRequest.package),
    };
  } else if (checkoutResponse.checkoutRequest.type === 'recording') {
    checkoutRequest = {
      type: checkoutResponse.checkoutRequest.type,
      recording: recordingNotOwnedAdapter(checkoutResponse.checkoutRequest.recording),
    };
  } else {
    return assertUnreachable(checkoutResponse.checkoutRequest, 'Unknown checkoutRequest type');
  }

  const checkout: UnfinishedCheckout = {
    id: checkoutResponse.id,
    checkoutRequest,
  };

  return checkout;
};
