import { create } from 'zustand';

export interface AuthStore {
  token: string | null;
  userId: string | null;

  isSignInModalOpen: boolean;
}

export const useAuthStore = create<AuthStore>((set) => ({
  token: null,
  userId: null,

  isSignInModalOpen: false,
}));

export const openSignInModal = () => {
  useAuthStore.setState({ isSignInModalOpen: true });
};

export const closeSignInModal = () => {
  useAuthStore.setState({ isSignInModalOpen: false });
};
