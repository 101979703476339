import {
  FloatingFocusManager,
  FloatingPortal,
  useMergeRefs,
  useTransitionStyles,
  UseTransitionStylesProps,
} from '@floating-ui/react';
import React from 'react';

import { usePopoverContext } from './PopoverContext';

interface PopoverContentProps {
  transitionConfig?: UseTransitionStylesProps;
}

const PopoverContent = React.forwardRef<
  HTMLDivElement,
  PopoverContentProps & React.HTMLProps<HTMLDivElement>
>(function PopoverContent({ style, transitionConfig, ...props }, propRef) {
  const { context: floatingContext, ...context } = usePopoverContext();
  const ref = useMergeRefs([context.refs.setFloating, propRef]);

  const { isMounted, styles: transitionStyles } = useTransitionStyles(
    floatingContext,
    transitionConfig,
  );
  const hasTransition = Boolean(transitionConfig);
  const isRendered = floatingContext.open || (hasTransition && isMounted);

  if (!isRendered) {
    return null;
  }

  return (
    <FloatingPortal>
      <FloatingFocusManager context={floatingContext}>
        <div ref={ref} style={context.floatingStyles} className="z-[100]">
          <div
            style={{ ...style, ...(hasTransition ? transitionStyles : {}) }}
            {...context.getFloatingProps(props)}
          >
            {props.children}
          </div>
        </div>
      </FloatingFocusManager>
    </FloatingPortal>
  );
});

export default PopoverContent;
