import { NotOwnedRecording } from 'modules/recording/model';
import React from 'react';

const RecordingCheckoutPreview: React.FC<{
  recording: NotOwnedRecording;
  className?: string;
  isMinimal?: boolean;
}> = ({ recording, className, isMinimal }) => {
  return (
    <div className={className}>
      {isMinimal ? (
        <h3 className="mb-1">{recording.name}</h3>
      ) : (
        <h2 className="mb-1">{recording.name}</h2>
      )}
      {!isMinimal && recording.subtitle && <p>{recording.subtitle}</p>}
    </div>
  );
};

export default RecordingCheckoutPreview;
