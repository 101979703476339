import useIsDesktopScreen from '../../hooks/useIsDesktopScreen';
import { ModalCore, ModalCoreProps } from '../ModalCore';
import ClassicContainer from './ClassicContainer';
import MobileContainer from './MobileContainer';
import OverlayContainer from './OverlayContainer';

type ModalVariant = 'mobile' | 'classic' | 'overlay';
// Combined type like 'overlay-classic' means 'overlay' modal on mobile and 'classic' on desktop
type ModalType = 'auto' | ModalVariant | `${ModalVariant}-${ModalVariant}`;

const Modal: React.FC<ModalCoreProps & { type?: ModalType; className?: string }> = ({
  children,
  type = 'auto',
  className,
  ...modalProps
}) => {
  const isDesktop = useIsDesktopScreen();

  if (type.includes('-')) {
    const [mobileType, deskType] = type.split('-');
    type = (isDesktop ? deskType : mobileType) as ModalType;
  }

  let body;
  if (type === 'auto') {
    body = isDesktop ? (
      <ClassicContainer className={className}>{children}</ClassicContainer>
    ) : (
      <MobileContainer className={className}>{children}</MobileContainer>
    );
  } else if (type === 'classic') {
    body = <ClassicContainer className={className}>{children}</ClassicContainer>;
  } else if (type === 'mobile') {
    body = <MobileContainer className={className}>{children}</MobileContainer>;
  } else if (type === 'overlay') {
    body = <OverlayContainer className={className}>{children}</OverlayContainer>;
  } else {
    throw new Error(`Unknown modal type: ${type}`);
  }

  return <ModalCore {...modalProps}>{body}</ModalCore>;
};

export default Modal;
