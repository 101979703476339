import { haptic } from '@mindkit';
import clsx from 'clsx';
import useAuth from 'modules/auth/hooks/useAuth';
import useBookings from 'modules/booking/hooks/useBookings';
import { bookingHelpers } from 'modules/booking/model';
import React, { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';

import BookingItem from '../BookingItem/BookingItem';

const BookingsWidget: React.FC<{ mentorId: string; count?: number; className?: string }> = ({
  mentorId,
  count = 1,
  className,
}) => {
  const { isAuth } = useAuth();

  const navigate = useNavigate();

  const handleViewAll = () => {
    haptic.selection();
    navigate(`/me/bookings`);
  };

  const { data: bookings } = useBookings();
  const mentorBookings = bookings.filter((b) => b.mentor.id === mentorId);

  const { upcoming } = useMemo(() => {
    return bookingHelpers.getBookingCategories(mentorBookings);
  }, [mentorBookings]);

  const actualBookings = upcoming.splice(0, count);

  if (!actualBookings.length) {
    return null;
  }

  return (
    <div className={clsx('flex flex-col gap-1 desk:gap-3', className)}>
      <div className="flex flex-row justify-between">
        <p className="desk:text-h2">My upcoming calls</p>
        {isAuth && (
          <button type="button" className="text-secondary" onClick={handleViewAll}>
            View all
          </button>
        )}
      </div>

      {actualBookings.map((booking) => (
        <BookingItem key={booking.id} booking={booking} withoutMentorInfo />
      ))}
    </div>
  );
};

export default BookingsWidget;
