import WebApp from '@twa-dev/sdk';
import Cookies from 'js-cookie';

let mentorId: string | undefined;

const parseSearchParams = (startParam: string) => {
  if (!startParam) {
    return new URLSearchParams();
  }

  const data = startParam.split('--');

  const result: Record<string, string> = {};

  for (let i = 0; i < data.length; i += 2) {
    result[data[i]] = data[i + 1];
  }

  return new URLSearchParams(result);
};

let searchParams = new URLSearchParams();

const GENERAL_BOT_IDS = ['6498385363', '7099867961'];
let botId = '';
let isGeneralBot = false;

export const TelegramService = {
  init: (config: {
    href: string;
    onClose?: () => void;
    onViewportChange?: (stableHeight: number) => void;
  }) => {
    if (!TelegramService.isTelegramApp()) {
      return;
    }
    Cookies.set('mindshareTgUserId', TelegramService.getUserId() || '');

    const botIdParam = new URL(config.href).searchParams.get('botId');
    botId = botIdParam || '';
    isGeneralBot = GENERAL_BOT_IDS.includes(botId);

    searchParams = parseSearchParams(WebApp.initDataUnsafe.start_param ?? '');

    WebApp.ready();

    // eslint-disable-next-line
    // @ts-ignore
    window?.Telegram?.WebView?.onEvent('web_app_close', () => {
      config.onClose?.();
    });

    WebApp.onEvent('viewportChanged', (e) => {
      if (!e.isStateStable) {
        return;
      }

      config.onViewportChange?.(WebApp.viewportStableHeight);
    });
  },

  isTelegramApp: () => {
    return Boolean(WebApp.initDataUnsafe.user);
  },

  getUserId: () => {
    return WebApp.initDataUnsafe.user?.id.toString();
  },

  getUser: () => {
    return WebApp.initDataUnsafe.user;
  },

  getQueryId: () => {
    return WebApp.initDataUnsafe.query_id;
  },

  isNewUserSession: () => {
    return Cookies.get('mindshareTgUserId') !== TelegramService.getUserId();
  },

  processPayment: async (
    paymentLink: string,
  ): Promise<'cancelled' | 'failed' | 'paid' | 'pending'> => {
    return new Promise((resolve) => {
      WebApp.openInvoice(paymentLink, async (status) => {
        resolve(status);
      });
    });
  },

  getViewportStableHeight: () => {
    return WebApp.viewportStableHeight;
  },

  createHapticImplementation: () => {
    return {
      selection: () => {
        WebApp.HapticFeedback.selectionChanged();
      },
      notification: (type: 'success' | 'error' | 'warning') => {
        WebApp.HapticFeedback.notificationOccurred(type);
      },
    };
  },

  setInitialMentorId: (id: string | undefined) => {
    mentorId = id;
  },
  getInitialMentorId: () => {
    return mentorId;
  },

  getSearchParams: () => {
    return searchParams;
  },

  getBotId: () => {
    return botId;
  },

  isGeneralBot: () => {
    return isGeneralBot;
  },

  getSafeRootRedirect: () => {
    if (!TelegramService.isTelegramApp()) {
      return '/';
    }

    if (TelegramService.isGeneralBot()) {
      return '/';
    }

    return `/mentor/${TelegramService.getInitialMentorId()}`;
  },

  openFull: () => {
    return WebApp.expand();
  },
};
