import { Skeleton } from '@mindkit';
import { IconChevronDown } from '@mindkit/icons';
import clsx from 'clsx';
import { Profile } from 'modules/user/model';
import React from 'react';

interface Props {
  profile?: Profile;
  className?: string;
}

const ProfileButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ profile, className, ...rest }, ref) => {
    return (
      <button
        ref={ref}
        type="button"
        className={clsx('group flex flex-row items-center', className)}
        {...rest}
      >
        <div className="flex h-10 flex-row items-center gap-2 rounded-3xl dark:text-contrast-foreground desk:bg-level2 desk:pl-5 desk:pr-1 desk:dark:bg-contrast">
          <div className="mobile:hidden">
            {profile ? (
              <p>Upcoming calls: {profile.user.upcomingBookingsCount}</p>
            ) : (
              <Skeleton.Text className="w-28" lines={1} />
            )}
          </div>

          <div className="h-8 w-8 overflow-hidden rounded-full">
            {profile ? (
              // <img
              //   className="h-8 w-8 rounded-full object-cover"
              //   alt="avatar"
              //   src={profile.user.}
              // />
              <div className="flex h-full w-full flex-col items-center justify-center bg-black">
                <span>🦸</span>
              </div>
            ) : (
              <Skeleton.Plane className="h-full w-full rounded-full" />
            )}
          </div>
        </div>

        <IconChevronDown className="ml-2 h-3 w-3 text-secondary transition-transform duration-300 group-data-[state=open]:rotate-180" />
      </button>
    );
  },
);
ProfileButton.displayName = 'ProfileButton';

export default ProfileButton;
