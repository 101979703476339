import clsx from 'clsx';
import React from 'react';

export interface Tab {
  id: string;
  label: string;
}

const Tabs: React.FC<{
  tabs: Tab[];
  selectedTab: string;
  onSelect: (tabId: string) => void;
  className?: string;
}> = ({ tabs, selectedTab, onSelect, className }) => {
  return (
    <div
      role="tablist"
      className={clsx(
        'flex snap-x snap-mandatory flex-row rounded-full border border-contrast',
        className,
      )}
    >
      {tabs.map((tab) => {
        const isSelected = tab.id === selectedTab;
        return (
          <button
            id={`tab-${tab.id}`}
            key={tab.id}
            type="button"
            className={clsx('flex-grow snap-start whitespace-nowrap px-5 py-2', {
              'bg-contrast text-contrast-foreground': isSelected,
            })}
            role="tab"
            aria-selected={isSelected}
            onClick={() => onSelect(tab.id)}
          >
            {tab.label}
          </button>
        );
      })}
    </div>
  );
};

export default Tabs;
