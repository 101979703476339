import { Button, notify } from '@mindkit';
import { IllustrationOk } from '@mindkit/illustrations';
import { authActions } from 'modules/auth';
import React from 'react';
import { ErrorService } from 'services/error';

import CodeInput from '../SignInModal/CodeInput';

const CodeStep: React.FC<{ email: string; onBack: () => void; onSignIn?: () => void }> = ({
  email,
  onBack,
  onSignIn,
}) => {
  const handleCodeSubmit = async (code: string) => {
    try {
      await authActions.authViaCode(code);

      onSignIn?.();
    } catch (e) {
      ErrorService.captureException(e, { fingerprint: ['authViaCode'] });
      notify('Invalid code, please try again', 'error');

      throw e;
    }
  };

  return (
    <div className="flex flex-col">
      <IllustrationOk className="self-center" />

      <div className="m-auto mt-10 flex max-w-[348px] flex-col text-center">
        <h2 className="mb-2">Check your email</h2>
        <p>
          We emailed a code and magic link to
          <br />
          <span className="font-bold">{email}</span>
        </p>
      </div>

      <CodeInput className="mb-6 mt-4 max-w-xs" codeLength={6} onSubmit={handleCodeSubmit} />

      <Button className="mb-6" variant="light" size="sm" onClick={onBack}>
        Change email
      </Button>
    </div>
  );
};

export default CodeStep;
