import './css/index.css';

import { haptic } from '@mindkit';
import dayjs from 'dayjs';
import isBetween from 'dayjs/plugin/isBetween';
import timezone from 'dayjs/plugin/timezone';
import utc from 'dayjs/plugin/utc';
import weekday from 'dayjs/plugin/weekday';
import { authActions } from 'modules/auth';
import { bookingActions } from 'modules/booking';
import { PlatformType } from 'modules/general/model';
import { paymentActions } from 'modules/payment';
import { userActions } from 'modules/user';
import React from 'react';
import ReactDOM from 'react-dom/client';
import { AnalyticsService } from 'services/analytics';
import { ApiService } from 'services/api';
import { ErrorService } from 'services/error';
import { QueryService } from 'services/query';
import { TelegramService } from 'services/telegram';
import { getMentorIdFromURL } from 'shared/helpers/getMentorId';

import Root from './Root';

const main = () => {
  dayjs.extend(utc);
  dayjs.extend(weekday);
  dayjs.extend(timezone);
  dayjs.extend(isBetween);

  ErrorService.init();

  QueryService.init({
    onError: (error, queryKey) => {
      ErrorService.captureException(error, { extra: { queryKey } });
    },
  });

  ApiService.init(authActions.getAuthToken, authActions.dropAuth);

  authActions.subscribeOnDropAuth(() => {
    bookingActions.onUserAuthDrop();
    userActions.onUserAuthDrop();
    paymentActions.onUserAuthDrop();
  });

  if (TelegramService.isTelegramApp() && TelegramService.isNewUserSession()) {
    authActions.dropAuth();
  }

  authActions.reviewAuth();

  TelegramService.init({
    href: document.location.href,
    onClose: () => {
      AnalyticsService.sendEvent('app_close');
    },
    onViewportChange: (stableHeight: number) => {
      AnalyticsService.sendEvent('app_viewport_changed', {
        viewportStableHeight: stableHeight,
      });
    },
  });

  const mentorId = getMentorIdFromURL();
  if (TelegramService.isTelegramApp()) {
    haptic.setImplementation(TelegramService.createHapticImplementation());

    TelegramService.setInitialMentorId(mentorId);

    AnalyticsService.setMetaData({
      tgUserId: TelegramService.getUser()?.id?.toString() || 'not found',
      tgUsername: TelegramService.getUser()?.username?.toString() || 'not found',
      tgQueryId: TelegramService.getQueryId() || 'not found',
      // botId: 'not found',
    });

    AnalyticsService.sendEvent('app_open', {
      platform: PlatformType.TELEGRAM,
      mentorId: mentorId || 'not found',
      viewportStableHeight: TelegramService.getViewportStableHeight(),
    });
    window.gtag('set', 'user_properties', { platform: PlatformType.TELEGRAM });
  } else {
    AnalyticsService.sendEvent('app_open', {
      platform: PlatformType.WEB,
      mentorId: mentorId || 'not found',
    });
    window.gtag('set', 'user_properties', { platform: PlatformType.WEB });
  }

  ReactDOM.createRoot(document.getElementById('root')!).render(
    <React.StrictMode>
      <Root />
    </React.StrictMode>,
  );
};

export default main;
