import { ApiService } from 'services/api';

import { mentorReferralAdapter } from './referralApiAdapters';
import { MentorReferralResponse } from './referralApiInterfaces';

export const generateMentorReferral = async (
  mentorId: string,
  botId?: string,
  timeslotId?: string,
) => {
  const response = await ApiService.apiInstance.get<MentorReferralResponse>(
    'profiles/referral-code',
    { params: { botId, mentorId, slotId: timeslotId } },
  );

  return mentorReferralAdapter(response.data);
};
