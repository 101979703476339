import { ApiService } from 'services/api';

import { profileAdapter } from './userApiAdapters';
import { ProfileResponse } from './userApiInterfaces';

export const getProfile = async () => {
  const response = await ApiService.apiInstance.get<ProfileResponse>(`profiles/me`);

  return profileAdapter(response.data);
};
