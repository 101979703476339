import { useAuthStore } from 'modules/auth';

const useAuth = () => {
  const { token, userId } = useAuthStore();

  // console.log('token', `http://localhost:3097/auth?sign=${token}&userId=${userId}`);

  const isAuth = Boolean(token) && Boolean(userId);
  if (isAuth) {
    return {
      isAuth: true as const,
      token: token as string,
      userId: userId as string,
    };
  }

  return {
    isAuth: false as const,
  };
};

export default useAuth;
