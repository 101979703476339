import { ErrorMessage } from '@hookform/error-message';
import { Button, Input, Loader, notify, TextArea } from '@mindkit';
import clsx from 'clsx';
import { mentorActions } from 'modules/mentor';
import useMentorManager from 'modules/mentor/hooks/useMentorManager';
import React, { useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import getErrorMessage from 'shared/helpers/getErrorMessage';

import Avatar from './Avatar';

interface FormInputs {
  name: string;
  description: string;
  expertise: string;
  about: string;
  photoUrl: string;
}

const MentorDetailsEditForm: React.FC<{ onSuccess?: () => void; className?: string }> = ({
  onSuccess,
  className,
}) => {
  const { data: mentor, isPending, isError } = useMentorManager();

  const [isSaving, setIsSaving] = useState(false);

  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
    reset,
  } = useForm<FormInputs>();

  useEffect(() => {
    if (!mentor) {
      return;
    }

    const { name, description, expertise, about, photoUrl } = mentor.profileDetails;
    reset({
      name,
      description,
      expertise,
      about,
      photoUrl,
    });
  }, [mentor, reset]);

  if (isError) {
    return (
      <div className={clsx('text-danger', className)}>
        <p>Something went wrong...</p>
      </div>
    );
  }

  if (isPending) {
    return (
      <div className={clsx('flex h-40 flex-col items-center justify-center', className)}>
        <Loader />
      </div>
    );
  }

  const handleSave: SubmitHandler<FormInputs> = async (data) => {
    const { name, description, expertise, about, photoUrl } = data;

    setIsSaving(true);
    try {
      await mentorActions.updateMentorManager({
        name,
        description,
        expertise,
        about,
        photoUrl,
      });

      onSuccess?.();
    } catch (e) {
      notify(getErrorMessage(e), 'error');

      throw e;
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <form className={clsx('flex flex-col gap-4', className)} onSubmit={handleSubmit(handleSave)}>
      <div className="flex flex-row gap-4">
        <div>
          <Controller
            control={control}
            name="photoUrl"
            rules={{ required: { value: true, message: 'Required' } }}
            render={({ field }) => (
              <Avatar {...field} className="shrink-0" avatarUrl={mentor.profileDetails.photoUrl} />
            )}
          />
          <ErrorMessage
            errors={errors}
            name="photoUrl"
            as="p"
            className="mt-1 text-xs text-danger"
          />
        </div>

        <div className="flex min-w-0 flex-grow flex-col gap-2">
          <div>
            <Input
              className="w-full"
              placeholder="Your name"
              isDisabled={isSaving}
              isError={Boolean(errors.name)}
              {...register('name', {
                required: { value: true, message: 'Required' },
                maxLength: { value: 35, message: 'Too long, max length is 35 chars' },
              })}
            />
            <ErrorMessage errors={errors} name="name" as="p" className="mt-1 text-xs text-danger" />
          </div>

          <div>
            <Input
              className="w-full"
              placeholder="Your role"
              isDisabled={isSaving}
              isError={Boolean(errors.description)}
              {...register('description', {
                required: { value: true, message: 'Required' },
                maxLength: { value: 50, message: 'Too long, max length is 50 chars' },
              })}
            />
            <ErrorMessage
              errors={errors}
              name="description"
              as="p"
              className="mt-1 text-xs text-danger"
            />
          </div>
        </div>
      </div>

      <div>
        <h4>Expertise</h4>
        <TextArea
          className="block max-h-[120px] min-h-[80px] w-full resize-none"
          placeholder="Enter your expertise and key skills"
          isDisabled={isSaving}
          isError={Boolean(errors.expertise)}
          {...register('expertise', { required: { value: true, message: 'Required' } })}
        />
        <ErrorMessage
          errors={errors}
          name="expertise"
          as="p"
          className="mt-1 text-xs text-danger"
        />
      </div>

      <div>
        <h4>About</h4>
        <TextArea
          className="max-h-[120px] min-h-[80px] w-full resize-none"
          placeholder="Enter a few words about yourself"
          isDisabled={isSaving}
          {...register('about')}
        />
      </div>

      <Button type="submit" isLoading={isSaving} isDisabled={isSaving}>
        Save
      </Button>
    </form>
  );
};

export default MentorDetailsEditForm;
