import { Mentor, MentorCatalog, MentorIntegration, MentorManager } from 'modules/mentor/model';
import { mentorPackAdapter } from 'modules/pack/api';
import { unfinishedCheckoutAdapter } from 'modules/payment/api';
import { timeslotAdapter, timeslotTypeAdapter } from 'modules/timeslot/api';

import {
  MentorCatalogResponse,
  MentorIntegrationResponse,
  MentorManagerResponse,
  MentorResponse,
} from './mentorApiInterfaces';

export const mentorAdapter = (mentor: MentorResponse): Mentor => {
  return {
    id: mentor.id,
    botId: mentor.botId,
    contentLang: mentor.contentLang,

    hasEventsAndRecordings: mentor.hasEventsAndRecordings,

    profileDetails: {
      name: mentor.profileDetails.name,
      description: mentor.profileDetails.description,
      photoUrl: mentor.profileDetails.photoUrl ?? undefined,

      about: mentor.profileDetails.about ?? undefined,
      booked: mentor.profileDetails.booked ?? undefined,
      expertise: mentor.profileDetails.expertise ?? undefined,
      returned: mentor.profileDetails.returned ?? undefined,
    },

    timeslots: mentor.timeslots.map((timeslot) => timeslotAdapter(timeslot)),

    packs: mentor.packages.map((mentorPackage) => mentorPackAdapter(mentorPackage)),

    unfinishedCheckout: mentor.continuePayment
      ? unfinishedCheckoutAdapter(mentor.continuePayment.checkout)
      : undefined,
  };
};

export const mentorCatalogAdapter = (mentorCatalog: MentorCatalogResponse): MentorCatalog => {
  return mentorCatalog.map((mentor) => ({
    id: mentor.mentorId,
    name: mentor.name,
    description: mentor.description,

    photoUrl: mentor.photoUrl ?? undefined,
    about: mentor.about ?? undefined,
    expertise: mentor.expertise ?? undefined,
  }));
};

export const mentorManagerIntegrationAdapter = (
  integration: MentorIntegrationResponse,
): MentorIntegration => {
  return {
    type: integration.type,
    active: integration.active,
    activeProfile: integration.activeProfile ?? undefined,
    authUrl: integration.authUrl ?? undefined,
    canRevoke: integration.canRevoke,
  };
};

export const mentorManagerAdapter = (mentor: MentorManagerResponse): MentorManager => {
  return {
    id: mentor.id,

    profileDetails: {
      name: mentor.profileDetails.name,
      description: mentor.profileDetails.description,
      photoUrl: mentor.profileDetails.photoUrl ?? undefined,

      about: mentor.profileDetails.about ?? undefined,
      booked: mentor.profileDetails.booked ?? undefined,
      expertise: mentor.profileDetails.expertise ?? undefined,
      returned: mentor.profileDetails.returned ?? undefined,
    },

    timeslotTypes: mentor.timeslotTypes.map((timeslotType) => timeslotTypeAdapter(timeslotType)),

    integrations: mentor.integrations.map(mentorManagerIntegrationAdapter),
  };
};
