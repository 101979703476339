import React from 'react';
import { Outlet } from 'react-router-dom';

import PageHeader from '../PageHeader/PageHeader';

const AppLayout: React.FC = () => {
  return (
    <div className="flex min-h-full w-full flex-col bg-level1 desk:bg-level2">
      <PageHeader className="px-5 desk:px-8" />

      <Outlet />
    </div>
  );
};

export default AppLayout;
