import clsx from 'clsx';
import UserPackItem from 'modules/pack/components/UserPackItem/UserPackItem';
import { UserPack } from 'modules/pack/model';
import React from 'react';

const UserPacks: React.FC<{ packs: UserPack[]; className?: string }> = ({ packs, className }) => {
  const isEmpty = !packs.length;
  if (isEmpty) {
    return null;
  }

  return (
    <section className={clsx('', className)}>
      <h2 className="mb-4">Packages</h2>

      <div className="flex flex-col gap-2 desk:gap-4">
        {packs.map((pack) => {
          return <UserPackItem key={pack.id} pack={pack} />;
        })}
      </div>
    </section>
  );
};

export default UserPacks;
