import { Button, haptic } from '@mindkit';
import { IconError } from '@mindkit/icons';
import clsx from 'clsx';
import CheckoutItemPreview from 'modules/payment/components/CheckoutItemPreview/CheckoutItemPreview';
import usePayment from 'modules/payment/hooks/usePayment';
import { UnfinishedCheckout } from 'modules/payment/model';
import React, { useEffect } from 'react';
import { AnalyticsService } from 'services/analytics';

const UnfinishedCheckoutItem: React.FC<{
  unfinishedCheckout: UnfinishedCheckout;
  className?: string;
}> = ({ unfinishedCheckout, className }) => {
  const { continueCheckout, cancelCheckout } = usePayment();

  const [isProcessing, setIsProcessing] = React.useState(false);

  useEffect(() => {
    AnalyticsService.sendEvent('unfinished_checkout_show', {
      checkoutId: unfinishedCheckout.id,
    });
  }, [unfinishedCheckout.id]);

  const handleContinue = async () => {
    haptic.selection();
    AnalyticsService.sendEvent('btn_continue_payment_click', { checkoutId: unfinishedCheckout.id });

    setIsProcessing(true);
    await continueCheckout(unfinishedCheckout.id);
    setIsProcessing(false);
  };

  const handleCancel = async () => {
    haptic.selection();
    AnalyticsService.sendEvent('btn_cancel_payment_click', { checkoutId: unfinishedCheckout.id });

    setIsProcessing(true);
    await cancelCheckout(unfinishedCheckout.id);
    setIsProcessing(false);
  };

  return (
    <div className={clsx('flex flex-col rounded-3xl bg-disabled-surface pb-4', className)}>
      <div className="flex flex-row gap-3 px-4 pt-4">
        <IconError className="h-6 w-6 shrink-0" />

        <div className="flex-grow">
          <CheckoutItemPreview checkoutRequest={unfinishedCheckout.checkoutRequest} isMinimal />
          <div className="mt-3 flex flex-row items-center justify-between">
            <Button
              color="primary dark:primary-inverse"
              variant="light"
              size="sm"
              onClick={handleCancel}
              isDisabled={isProcessing}
            >
              Cancel
            </Button>
            <Button
              color="primary dark:primary-inverse"
              variant="outlined"
              size="sm"
              onClick={handleContinue}
              isDisabled={isProcessing}
            >
              Continue payment
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UnfinishedCheckoutItem;
