import { Loader } from '@mindkit';
import UserArchiveCalls from 'modules/booking/components/UserArchiveCalls/UserArchiveCalls';
import UserUpcomingCalls from 'modules/booking/components/UserUpcomingCalls/UserUpcomingCalls';
import { bookingHelpers } from 'modules/booking/model';
import PageLayout from 'modules/general/components/PageLayout/PageLayout';
import UserPacks from 'modules/pack/components/UserPacks/UserPacks';
import UnfinishedCheckoutItem from 'modules/payment/components/UnfinishedCheckoutItem/UnfinishedCheckoutItem';
import useUnfinishedCheckout from 'modules/payment/hooks/useUnfinishedCheckout';
import useProfile from 'modules/user/hooks/useProfile';
import React, { useMemo } from 'react';
import HowToGetReady from 'shared/components/HowToGetReady/HowToGetReady';

const UserBookingsPage: React.FC = () => {
  const { data: unfinishedCheckout } = useUnfinishedCheckout();
  const { data: profile, isPending: isProfilePending, isError: isProfileError } = useProfile();

  const { upcoming, archive, recordings, activePacks } = useMemo(() => {
    if (!profile) {
      return { upcoming: [], archive: [], recordings: [], activePacks: [] };
    }

    const { bookings, packs, recordings } = profile;
    const { upcoming, archive } = bookingHelpers.getBookingCategories(bookings);
    const activePacks = packs.filter((pack) => pack.active);

    return { upcoming, archive, recordings, activePacks };
  }, [profile]);

  if (isProfileError) {
    return <p className="m-auto text-danger">Something went wrong ...</p>;
  }

  if (isProfilePending) {
    return <Loader className="m-auto" />;
  }

  return (
    <PageLayout
      bodyClassName="gap-8"
      sidebarClassName="gap-8"
      mobileBody={
        <>
          {unfinishedCheckout ? (
            <UnfinishedCheckoutItem unfinishedCheckout={unfinishedCheckout} />
          ) : null}

          <UserUpcomingCalls upcoming={upcoming} />

          <UserPacks packs={activePacks} />
          <HowToGetReady />

          <UserArchiveCalls archive={archive} recordings={recordings} />
        </>
      }
      deskBody={
        <>
          <UserUpcomingCalls upcoming={upcoming} />
          <UserArchiveCalls archive={archive} recordings={recordings} />
        </>
      }
      deskSidebar={
        <>
          {unfinishedCheckout ? (
            <UnfinishedCheckoutItem unfinishedCheckout={unfinishedCheckout} />
          ) : null}
          <UserPacks packs={activePacks} />
          <HowToGetReady />
        </>
      }
    />
  );
};

export default UserBookingsPage;
