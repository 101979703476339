import clsx from 'clsx';
import { MentorInCatalog } from 'modules/mentor/model';
import React from 'react';
import { Link } from 'react-router-dom';

const MentorCatalogItem: React.FC<{ mentor: MentorInCatalog; className?: string }> = ({
  mentor,
  className,
}) => {
  const { photoUrl, name, description, expertise } = mentor;

  return (
    <article className={clsx('relative flex flex-col rounded-3xl bg-level1 p-8', className)}>
      <Link
        className="absolute inset-0"
        to={`/mentor/${mentor.id}`}
        aria-label={`Go to ${name}'s page`}
      />

      <div className="mb-4 flex flex-row gap-5">
        {photoUrl && (
          <img
            className="h-16 w-16 flex-shrink-0 overflow-hidden rounded-full object-cover"
            alt="avatar"
            src={photoUrl}
          />
        )}
      </div>

      <div className="mb-6">
        <h3 className="mb-1">{name}</h3>
        <p className="line-clamp-3 overflow-hidden text-ellipsis whitespace-pre-wrap">
          {description}
        </p>
      </div>

      {expertise && (
        <div className="">
          <h4>Expertise</h4>
          <p className="line-clamp-3 overflow-hidden text-ellipsis">{expertise}</p>
        </div>
      )}
    </article>
  );
};

export default MentorCatalogItem;
