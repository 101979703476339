import { Button, Modal } from '@mindkit';
import { IconCalendar } from '@mindkit/icons';
import clsx from 'clsx';
import { getPackStylePreset, UserPack } from 'modules/pack/model';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';

const UserPackItem: React.FC<{ pack: UserPack; className?: string }> = ({ pack, className }) => {
  const { mentorPack, expiresAt, sessionsLeft } = pack;
  const { name, designDetails } = mentorPack;

  const isEventPack = sessionsLeft === undefined;

  const { colorPreset } = getPackStylePreset(designDetails.color, designDetails.icon);

  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const handleOpenDetails = () => {
    setIsDetailsOpen(true);
  };
  const handleCloseDetails = () => {
    setIsDetailsOpen(false);
  };

  const expiresBlock = (
    <div className="mt-3 flex flex-row items-center gap-2">
      <IconCalendar className="h-5 w-5 shrink-0" />
      {isEventPack ? (
        <p className="text-sm">Exp.: {expiresAt.format('DD.MM.YYYY')}</p>
      ) : (
        <p className="text-sm">
          Left: {sessionsLeft} (exp.: {expiresAt.format('DD.MM.YYYY')})
        </p>
      )}
    </div>
  );

  return (
    <>
      <Modal isOpen={isDetailsOpen} onClose={handleCloseDetails} className="max-w-lg">
        <h2 className="mb-1">{mentorPack.name}</h2>
        <p>with {mentorPack.mentor.displayName}</p>

        {expiresBlock}

        <p className="mt-5 whitespace-pre-wrap">{mentorPack.description}</p>

        {!isEventPack && (
          <Button
            className="mt-5 w-full"
            as={Link}
            to={`/mentor/${mentorPack.mentor.id}`}
            variant="solid"
            color="primary"
            onClick={(e) => e.stopPropagation()}
          >
            Book slot
          </Button>
        )}
      </Modal>

      <button
        type="button"
        className={clsx('flex flex-col rounded-3xl p-5 text-left text-white', className)}
        style={{
          background: `linear-gradient(to right bottom, ${colorPreset.bgColorStart}, ${colorPreset.bgColorEnd})`,
        }}
        onClick={handleOpenDetails}
      >
        <h3>{name}</h3>
        <p className="text-sm">with {mentorPack.mentor.displayName}</p>

        {expiresBlock}

        {isEventPack ? (
          <div className="mt-5 w-full rounded-2xl bg-white/20 px-5 py-3">
            <p className="text-xs">
              All set and good to go!
              <br />
              You&apos;ll receive automatic notifications for all upcoming events!
            </p>
          </div>
        ) : (
          <Button
            className="mt-5 w-full"
            as={Link}
            to={`/mentor/${mentorPack.mentor.id}`}
            variant="outlined"
            color="primary-inverse"
            onClick={(e) => e.stopPropagation()}
          >
            Book slot
          </Button>
        )}
      </button>
    </>
  );
};

export default UserPackItem;
