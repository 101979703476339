import { haptic } from '@mindkit';
import { IconArrowLeft, IconArrowRight } from '@mindkit/icons';
import clsx from 'clsx';
import dayjs from 'dayjs';
import useManagerWeeks from 'modules/timeslot/hooks/useManagerWeeks';
import { weekHelpers } from 'modules/timeslot/model';
import { Week } from 'modules/timeslot/model/weekHelpers';
import React, { memo, useCallback, useEffect, useState } from 'react';
import { AnalyticsService } from 'services/analytics';

import CalendarTab from '../CalendarTab/CalendarTab';
import TimeslotList from './TimeslotList';

const INITIAL_WEEKS_COUNT = 5;
const LOAD_MORE_WEEKS_COUNT = 4;

const ManagerCalendar: React.FC<{
  onWeekChange?: (week: Week | undefined) => void;
  className?: string;
}> = memo(({ onWeekChange, className }) => {
  const [weeks, setWeeks] = useState(() => weekHelpers.getWeeks(dayjs(), INITIAL_WEEKS_COUNT));
  const [currentWeekId, setCurrentWeekId] = useState(weeks[0].id);

  const managerWeeks = useManagerWeeks(weeks, currentWeekId);

  const currentWeek = managerWeeks.find((w) => w.id === currentWeekId);
  useEffect(() => {
    onWeekChange?.(currentWeek);
  }, [currentWeek, onWeekChange]);

  const handleWeekClick = useCallback(
    (id: string) => {
      if (id !== currentWeekId) {
        AnalyticsService.sendEvent('calendar_week_change_click');
        setCurrentWeekId(id);
      }
    },
    [currentWeekId],
  );

  const handleLoadMore = () => {
    haptic.selection();

    AnalyticsService.sendEvent('calendar_load_more_click');

    const updatedWeeks = weekHelpers.getWeeks(dayjs(), weeks.length + LOAD_MORE_WEEKS_COUNT);
    setWeeks(updatedWeeks);
  };

  const handleNextWeek = () => {
    haptic.selection();

    const currentIndex = managerWeeks.findIndex((w) => w.id === currentWeekId);
    const nextIndex = currentIndex + 1;
    if (nextIndex >= managerWeeks.length) {
      AnalyticsService.sendEvent('calendar_load_more_click');

      const updatedWeeks = weekHelpers.getWeeks(dayjs(), weeks.length + LOAD_MORE_WEEKS_COUNT);
      setWeeks(updatedWeeks);
      setCurrentWeekId(updatedWeeks[nextIndex].id);
    } else {
      setCurrentWeekId(managerWeeks[nextIndex]?.id);
    }
  };
  const handlePrevWeek = () => {
    haptic.selection();

    const currentIndex = managerWeeks.findIndex((w) => w.id === currentWeekId);
    const prevIndex = currentIndex - 1;
    if (prevIndex >= 0) {
      setCurrentWeekId(managerWeeks[prevIndex]?.id);
    }
  };

  return (
    <div className={clsx('', className)}>
      <div className="flex max-w-full flex-row gap-2 overflow-hidden">
        <button
          type="button"
          className="py-4 transition-all active:scale-95 mobile:hidden"
          onClick={handlePrevWeek}
          aria-label="Prev week"
        >
          <IconArrowLeft />
        </button>

        <div
          className="hide-scrollbar flex flex-grow snap-x snap-mandatory flex-row gap-2 overflow-x-scroll"
          role="tablist"
        >
          {managerWeeks.map((week) => {
            const { id, start, end, isLoaded } = week;

            return (
              <CalendarTab
                key={id}
                className="shrink-0 flex-grow basis-[152px] snap-start"
                start={start}
                end={end}
                slotsLabel={
                  week.timeslotsCount === 0
                    ? '0 slots'
                    : `${week.availableCount}/${week.timeslotsCount} slots available`
                }
                isSelected={id === currentWeekId}
                onClick={() => handleWeekClick(id)}
                isLoading={!isLoaded}
              />
            );
          })}

          <button
            className="rounded-3xl border border-contrast px-4 text-contrast transition-colors duration-300 hover:bg-contrast hover:text-contrast-foreground desk:hidden"
            type="button"
            aria-label="Load more slots"
            onClick={handleLoadMore}
          >
            <IconArrowRight />
          </button>
        </div>

        <button
          type="button"
          className="py-4 transition-all active:scale-95 mobile:hidden"
          onClick={handleNextWeek}
          aria-label="Next week"
        >
          <IconArrowRight />
        </button>
      </div>

      <TimeslotList
        className="mt-4"
        timeslots={currentWeek?.timeslots || []}
        isLoading={!currentWeek?.isLoaded}
        isError={currentWeek?.isError}
      />
    </div>
  );
});
ManagerCalendar.displayName = 'ManagerCalendar';

export default ManagerCalendar;
