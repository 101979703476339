import { IconButton } from '@mindkit';
import { IconShare } from '@mindkit/icons';
import clsx from 'clsx';
import useAuth from 'modules/auth/hooks/useAuth';
import React, { useState } from 'react';

import MentorReferralModal from '../MentorReferralModal/MentorReferralModal';

const ShareReferralButton: React.FC<{
  mentorId: string;
  timeslotId?: string;
  className?: string;
}> = ({ mentorId, timeslotId, className }) => {
  const [isOpen, setIsOpen] = useState(false);
  const handleOpenModal = () => {
    setIsOpen(true);
  };
  const handleCloseModal = () => {
    setIsOpen(false);
  };

  const { isAuth } = useAuth();
  if (!isAuth) {
    return null;
  }

  return (
    <>
      <MentorReferralModal
        isOpen={isOpen}
        onClose={handleCloseModal}
        mentorId={mentorId}
        timeslotId={timeslotId}
      />
      <IconButton
        className={className}
        color="primary"
        aria-label="Share referral"
        onClick={handleOpenModal}
      >
        <IconShare />
      </IconButton>
    </>
  );
};

export default ShareReferralButton;
