import { Button } from '@mindkit';
import React from 'react';
import { Link } from 'react-router-dom';

const UnknownPage: React.FC = () => {
  return (
    <div className="flex h-full w-full flex-col items-center justify-center">
      <h2>Ooops, the page doesn&apos;t exist 🙈</h2>
      <Button className="mt-4 w-40" as={Link} to="/">
        Home
      </Button>
    </div>
  );
};

export default UnknownPage;
