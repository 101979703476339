import { Button } from '@mindkit';
import clsx from 'clsx';
import { openQuestionnaireModal } from 'modules/betaPromo/components/BetaQuestionnaireModal/betaQuestionnaireStore';
import React from 'react';

const BetaPromoButton: React.FC<{
  className?: string;
}> = ({ className }) => {
  const handleOpenModal = () => {
    openQuestionnaireModal('page_header');
  };

  return (
    <div className={clsx('flex flex-col items-center rounded-3xl bg-level2 px-5', className)}>
      <h3 className="mb-2">Want the same tool?</h3>
      <Button
        onClick={handleOpenModal}
        variant="outlined"
        color="primary dark:primary-inverse"
        size="sm"
      >
        I&apos;m a mentor
      </Button>
    </div>
  );
};

export default BetaPromoButton;
