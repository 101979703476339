import { Button, Input, notify } from '@mindkit';
import { IconAdd } from '@mindkit/icons';
import clsx from 'clsx';
import dayjs, { Dayjs } from 'dayjs';
import { timeslotActions } from 'modules/timeslot';
import { ManagedTimeslot, TimeslotType } from 'modules/timeslot/model';
import React, { useEffect, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import TimezoneLabel from 'shared/components/TimezoneLabel/TimezoneLabel';
import getErrorMessage from 'shared/helpers/getErrorMessage';

import cm from './Select.module.css';

const getTimeslotTypeLabel = (type: TimeslotType) => {
  return `${type.duration} min ${type.price} ${type.currency}`;
};

const getLastSelectedTimeslotType = (timeslotTypes: TimeslotType[]) => {
  const lastSelected = localStorage.getItem('createTimeslotForm.timeslotType');
  if (lastSelected) {
    const timeslotType = Number(lastSelected);
    if (timeslotTypes.find((t) => t.id === timeslotType)) {
      return timeslotType;
    }
  }
};
const setLastSelectedTimeslotType = (timeslotType: number) => {
  localStorage.setItem('createTimeslotForm.timeslotType', String(timeslotType));
};

interface FormInputs {
  date: string;
  time: string;
  timeslotType: string;
}

const CreateTimeslotForm: React.FC<{
  timeslotTypes: TimeslotType[];
  onCreated?: (timeslot: ManagedTimeslot) => void;
  initialDate?: Dayjs;
  className?: string;
}> = ({ timeslotTypes, onCreated, initialDate, className }) => {
  const handleShowPicker = (e: React.MouseEvent<HTMLInputElement>) => {
    if ('showPicker' in HTMLInputElement.prototype) {
      (e.target as HTMLInputElement).showPicker();
    }
  };

  const { register, handleSubmit, watch } = useForm<FormInputs>({
    defaultValues: {
      date: (initialDate || dayjs()).format('YYYY-MM-DD'),
      time: '08:00',
      timeslotType:
        getLastSelectedTimeslotType(timeslotTypes)?.toString() || timeslotTypes[0].id.toString(),
    },
  });

  const timeslotType = watch('timeslotType');
  useEffect(() => {
    setLastSelectedTimeslotType(Number(timeslotType));
  }, [timeslotType]);

  const [isCreating, setIsCreating] = useState(false);

  const handleCreate: SubmitHandler<FormInputs> = async (data) => {
    const hoursMinutes = data.time.split(':');
    const date = dayjs(data.date)
      .hour(Number(hoursMinutes[0]))
      .minute(Number(hoursMinutes[1]))
      .utc();

    const timeslotType = Number(data.timeslotType);

    setIsCreating(true);
    try {
      const timeslot = await timeslotActions.createTimeslot(date, timeslotType);
      onCreated?.(timeslot);
    } catch (e) {
      notify(getErrorMessage(e), 'error');

      throw e;
    } finally {
      setIsCreating(false);
    }
  };

  return (
    <form className={clsx('flex flex-col', className)} onSubmit={handleSubmit(handleCreate)}>
      <h2 className="mb-4">New 1:1 consultation slot</h2>
      <div className="mb-4">
        <h4 className="mb-1">Date</h4>
        <Input
          className="w-full cursor-pointer"
          type="date"
          onClick={handleShowPicker}
          {...register('date', { required: true })}
        />
      </div>

      <div className="mb-4">
        <h4 className="mb-1">
          Starts at
          <span className="ml-1 font-normal text-secondary">
            (<TimezoneLabel isFull />)
          </span>
        </h4>
        <Input
          className="w-full cursor-pointer"
          type="time"
          onClick={handleShowPicker}
          {...register('time', { required: true })}
        />
      </div>

      <div>
        <h4 className="mb-1">Type</h4>
        <div className={cm.selectWrapper}>
          <select
            className={clsx(
              'h-12 w-full cursor-pointer appearance-none rounded-xl border border-disabled bg-transparent px-4 outline-none focus:border-primary',
            )}
            {...register('timeslotType', { required: true })}
          >
            {timeslotTypes.map((timeslotType) => (
              <option key={timeslotType.id} value={timeslotType.id}>
                {getTimeslotTypeLabel(timeslotType)}
              </option>
            ))}
          </select>
        </div>
      </div>

      <Button type="submit" className="mt-4" isDisabled={isCreating} isLoading={isCreating}>
        Add slot
        <IconAdd className="ml-1 h-4 w-4" />
      </Button>
    </form>
  );
};

export default CreateTimeslotForm;
