import { Button } from '@mindkit';
import { IconCheck } from '@mindkit/icons';
import clsx from 'clsx';
import { Booking, bookingHelpers } from 'modules/booking/model';
import WatchRecordingButton from 'modules/recording/components/WatchRecordingButton/WatchRecordingButton';
import React from 'react';
import { Link } from 'react-router-dom';

import CalendarLink from './CalendarLink';
import ZoomLink from './ZoomLink';

const BookingItem: React.FC<{
  booking: Booking;
  withoutMentorInfo?: boolean;
  className?: string;
}> = ({ booking, withoutMentorInfo, className }) => {
  const { event, mentor } = booking;

  const category = bookingHelpers.getBookingCategory(booking);
  const isUpcoming = category === 'upcoming';
  const isArchive = category === 'archive';

  const isEvent = Boolean(event);

  const { date, time } = bookingHelpers.getBookingFormated(booking);

  const withHeader = isEvent || !withoutMentorInfo;

  return (
    <div
      className={clsx(
        'flex flex-col rounded-3xl pb-4',
        isUpcoming ? 'bg-success-surface text-on-surface' : 'bg-disabled-surface',
        className,
      )}
    >
      {withHeader && (
        <div className={clsx('border-b border-dashed border-on-surface p-4')}>
          {isEvent ? <h3>{event?.name}</h3> : <h3>Consultation with {mentor.displayName}</h3>}
        </div>
      )}

      <div className="flex flex-row gap-3 px-4 pt-4">
        <IconCheck className="h-6 w-6 shrink-0" />

        <div className="flex-grow">
          <p>
            <span className="font-bold">{date}, </span>
            <span>{time}</span>
          </p>
        </div>
      </div>

      {isUpcoming && (
        <div className={clsx('mt-3 flex flex-row items-center justify-between px-4')}>
          <CalendarLink booking={booking} />
          <ZoomLink booking={booking} />
        </div>
      )}

      {isArchive && booking.event?.recording?.status === 'owned' && (
        <div className={clsx('mt-3 flex flex-row justify-end px-4')}>
          <WatchRecordingButton recording={booking.event.recording} />
        </div>
      )}

      {isArchive && !isEvent && (
        <div className={clsx('mt-3 flex flex-row justify-end px-4')}>
          <Button
            color="primary dark:primary-inverse"
            variant="outlined"
            size="sm"
            as={Link}
            to={`/mentor/${mentor.id}`}
          >
            Book again
          </Button>
        </div>
      )}
    </div>
  );
};

export default BookingItem;
