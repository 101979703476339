import { useQuery } from '@tanstack/react-query';
import useAuth from 'modules/auth/hooks/useAuth';
import { mentorActions } from 'modules/mentor';
import { timeslotHelpers } from 'modules/timeslot/model';

const useMentor = (mentorId: string) => {
  const { isAuth } = useAuth();

  return useQuery({
    queryKey: ['mentor', mentorId, 'auth', isAuth],
    queryFn: () => {
      const timeslotPreloadRange = timeslotHelpers.getTimeslotsPreloadRange();
      return mentorActions.getMentor(mentorId, timeslotPreloadRange);
    },
  });
};

export default useMentor;
