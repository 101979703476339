import { IconArrowRight } from '@mindkit/icons';
import clsx from 'clsx';
import React from 'react';
import { Link } from 'react-router-dom';

const EventAndRecordingsEntry: React.FC<{
  mentorId: string;
  className?: string;
  collapsed?: boolean;
}> = ({ mentorId, className, collapsed }) => {
  return (
    <Link
      to={`/mentor/${mentorId}/recordings`}
      className={clsx(
        'rounded-3xl bg-primary-surface',
        { 'px-5 py-8': !collapsed, 'px-5 py-3': collapsed },
        className,
      )}
    >
      <p className={clsx('text-primary', { 'max-w-[200px] text-h2': !collapsed })}>
        <span>Events&nbsp;& Recordings</span>
        <IconArrowRight className={clsx('ml-1 inline-block', { 'float-right': collapsed })} />
      </p>
    </Link>
  );
};

export default EventAndRecordingsEntry;
