import { isAxiosError } from 'axios';
import dayjs from 'dayjs';
import { PlatformType } from 'modules/general/model';
import { MentorPack } from 'modules/pack/model';
import { setCheckout } from 'modules/payment';
import * as paymentApi from 'modules/payment/api';
import {
  CheckoutPending,
  CheckoutProcessResult,
  PaymentLink,
  PaymentMethod,
  PaymentOption,
} from 'modules/payment/model';
import { NotOwnedRecording } from 'modules/recording/model';
import { Timeslot } from 'modules/timeslot/model';
import { TelegramService } from 'services/telegram';
import assertUnreachable from 'shared/helpers/assertUnreachable';

import { dropUnfinishedCheckout } from './unfinishedCheckoutActions';

export * from './unfinishedCheckoutActions';

export const createTimeslotCheckout = async (
  timeslot: Timeslot,
  platform: PlatformType,
  isAuth: boolean,
  referralCode?: string,
) => {
  const checkout = await paymentApi.createCheckout(
    {
      platform,
      referralCode,

      productType: 'timeslot',
      productDetails: {
        timeslotId: timeslot.id,
        timeslotTypeId: timeslot.types[0].id,
      },
    },
    isAuth,
  );

  return checkout;
};

export const createPackageCheckout = async (
  mentorPackId: string,
  platform: PlatformType,
  isAuth: boolean,
  referralCode?: string,
) => {
  const checkout = await paymentApi.createCheckout(
    {
      platform,
      referralCode,

      productType: 'package',
      productDetails: {
        packageId: mentorPackId,
      },
    },
    isAuth,
  );

  return checkout;
};

export const createRecordingCheckout = async (
  recordingId: string,
  platform: PlatformType,
  isAuth: boolean,
  referralCode?: string,
) => {
  const checkout = await paymentApi.createCheckout(
    {
      platform,
      referralCode,

      productType: 'recording',
      productDetails: {
        recordingId,
      },
    },
    isAuth,
  );

  return checkout;
};

const generatePaymentLink = async (data: paymentApi.PaymentLinkRequest) => {
  const paymentLink = await paymentApi.generatePaymentLink(data);

  return paymentLink;
};

const processPaymentLink = async (
  paymentLink: PaymentLink,
  platform: PlatformType,
): Promise<CheckoutProcessResult> => {
  if (platform === PlatformType.WEB || platform === PlatformType.TELEGRAM) {
    window.open(paymentLink.url, '_self');

    return Promise.resolve('redirect');
  }

  // if (platform === PlatformType.TELEGRAM) {
  //   const status = await TelegramService.processPayment(paymentLink.url);
  //   if (status === 'paid') {
  //     return 'paid';
  //   }
  //   if (status === 'cancelled') {
  //     return 'cancelled';
  //   }
  //   return 'failed';
  // }

  throw new Error('Unknown platform to process payment');
};

export const processCheckout = async (
  checkout: CheckoutPending,
  paymentOption: PaymentOption,
  platform: PlatformType,
  timezone: string,
  privacyChecked: boolean,
  email?: string,
): Promise<CheckoutProcessResult> => {
  if (checkout.policyRequired && !privacyChecked) {
    throw new Error('Please agree with Privacy Policy');
  }
  if (checkout.emailRequired && !email) {
    throw new Error('Email is required for checkout');
  }

  if (
    paymentOption.method === PaymentMethod.STRIPE ||
    paymentOption.method === PaymentMethod.RU_CARD
  ) {
    const paymentLink = await generatePaymentLink({
      checkoutId: checkout.id,
      paymentType: paymentOption.method,
      platform,
      timezone,
      email,
      botId: platform === PlatformType.TELEGRAM ? TelegramService.getBotId() : undefined,
    });

    const result = await processPaymentLink(paymentLink, platform);

    return result;
  }
  if (paymentOption.method === PaymentMethod.PACKAGE) {
    await paymentApi.payWithPackage(checkout.id, paymentOption.productId);

    return 'paid';
  }

  if (paymentOption.method === PaymentMethod.FREE) {
    await paymentApi.payFree(checkout.id);

    return 'paid';
  }

  return assertUnreachable(paymentOption, 'Unknown payment method');
};

export const renewCheckout = async (checkoutId: string, platform: PlatformType) => {
  try {
    const checkout = await paymentApi.renewCheckout(checkoutId, platform);

    return checkout;
  } catch (e) {
    if (isAxiosError(e) && e.response?.status === 400) {
      dropUnfinishedCheckout(checkoutId);
    }

    throw e;
  }
};

export const getCheckout = async (checkoutId: string, platform: PlatformType) => {
  const checkout = await paymentApi.getCheckout(checkoutId, platform);

  return checkout;
};

export const cancelCheckout = async (checkoutId: string) => {
  await paymentApi.cancelCheckout(checkoutId);

  dropUnfinishedCheckout(checkoutId);
};

export const applyCoupon = async (
  checkoutId: string,
  couponCode: string,
  platform: PlatformType,
  email?: string,
) => {
  const checkout = await paymentApi.applyCoupon(
    checkoutId,
    couponCode,
    platform,
    dayjs.tz.guess(),
    email,
  );
  setCheckout(checkout);

  return checkout;
};

export const onUserAuthDrop = () => {
  dropUnfinishedCheckout();
};
