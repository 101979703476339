import { Loader } from '@mindkit';
import clsx from 'clsx';
import useMentorManager from 'modules/mentor/hooks/useMentorManager';
import ShareReferralButton from 'modules/referral/components/ShareReferralButton/ShareReferralButton';
import React from 'react';

import SettingsDropdown from './SettingsDropdown';

const MentorManagerInfo: React.FC<{ className?: string }> = ({ className }) => {
  const { data: mentor, isPending, isError } = useMentorManager();

  if (isPending) {
    return (
      <div className={className}>
        <Loader />
      </div>
    );
  }
  if (isError) {
    return (
      <div className={className}>
        <p>Something went wrong...</p>
      </div>
    );
  }

  const { photoUrl, name, description, expertise } = mentor.profileDetails;

  return (
    <section
      className={clsx('page-section flex flex-col gap-2 desk:flex-row desk:gap-5', className)}
    >
      <div className="flex flex-row">
        {photoUrl && (
          <img
            className="h-14 w-14 flex-shrink-0 overflow-hidden rounded-full object-cover desk:h-20 desk:w-20"
            alt="avatar"
            src={photoUrl}
          />
        )}
        <div className="flex-grow" />
        <div className="desk:hidden">
          <ShareReferralButton mentorId={mentor.id} />
          <SettingsDropdown mentor={mentor} />
        </div>
      </div>
      <div className="flex flex-grow flex-col items-start">
        <h4 className="desk:text-h2">{name}</h4>
        <p className="whitespace-pre-wrap text-sm desk:text-base">{description}</p>

        {expertise && (
          <>
            <h4 className="mb-1 mt-2">Expertise</h4>
            <p>{expertise}</p>
          </>
        )}
      </div>

      <div className="mobile:hidden">
        <ShareReferralButton mentorId={mentor.id} />
        <SettingsDropdown mentor={mentor} />
      </div>
    </section>
  );
};

export default MentorManagerInfo;
