import SignInForm from 'modules/auth/components/SignInForm/SignInForm';
import useAuth from 'modules/auth/hooks/useAuth';
import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const SignInPage: React.FC = () => {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const redirect = searchParams.get('redirect') || '/';

  const { isAuth } = useAuth();
  useEffect(() => {
    if (isAuth) {
      navigate(redirect);
    }
  }, [isAuth, redirect, navigate]);

  return (
    <div className="flex h-full w-full flex-col items-center justify-center bg-level1 desk:bg-level2">
      <div className="rounded-3xl desk:w-[420px] desk:bg-level1 desk:p-10">
        <SignInForm redirect={redirect} />
      </div>
    </div>
  );
};

export default SignInPage;
