import clsx from 'clsx';
import { ProfileDetails } from 'modules/mentor/model';
import React from 'react';

const MentorInfo: React.FC<{
  profileDetails: Pick<ProfileDetails, 'photoUrl' | 'name' | 'description'>;
  className?: string;
}> = ({ profileDetails, className }) => {
  const { photoUrl, name, description } = profileDetails;

  return (
    <div className={clsx('flex flex-row gap-2 desk:gap-5', className)}>
      {photoUrl && (
        <img
          className="h-14 w-14 flex-shrink-0 overflow-hidden rounded-full object-cover desk:h-20 desk:w-20"
          alt="avatar"
          src={photoUrl}
        />
      )}
      <div className="flex flex-col justify-center">
        <h1 className="text-h4 desk:text-h2">{name}</h1>
        <p className="whitespace-pre-wrap text-sm desk:text-base">{description}</p>
      </div>
    </div>
  );
};

export default MentorInfo;
