import dayjs from 'dayjs';
import Cookies from 'js-cookie';
import { useAuthStore } from 'modules/auth';
import * as authApi from 'modules/auth/api';
import { TgUser } from 'modules/general/model';
import { AnalyticsService } from 'services/analytics';

const setAuth = (token: string, userId: string) => {
  Cookies.set('mindshare', token);
  Cookies.set('mindshare_user_id', userId);

  AnalyticsService.setMetaData({ userId });

  useAuthStore.setState({ token, userId });
};

export const getAuthToken = () => {
  return useAuthStore.getState().token;
};

export const reviewAuth = () => {
  const token = Cookies.get('mindshare');
  const userId = Cookies.get('mindshare_user_id');
  if (token && userId) {
    setAuth(token, userId);
  }
};

export const dropAuth = () => {
  Cookies.remove('mindshare');
  Cookies.remove('mindshare_user_id');

  AnalyticsService.sendEvent('auth_drop');
  AnalyticsService.setMetaData({ userId: 'anonymous' });

  useAuthStore.setState({ token: null, userId: null });
};

export const authViaTelegram = async (botId: string, user: TgUser) => {
  const { accessToken, userId } = await authApi.authViaTelegram({
    botId,
    userId: user.id.toString(),
    username: user.username ?? '',
    firstName: user.first_name,
    lastName: user.last_name ?? null,
    languageCode: user.language_code ?? 'en',
    allowsPm: user.allows_write_to_pm ?? false,
    timezone: dayjs.tz.guess(),
  });

  AnalyticsService.setMetaData({ userId });
  AnalyticsService.sendEvent('auth_via_telegram');

  setAuth(accessToken, userId);
};

export const authViaCode = async (code: string) => {
  const { accessToken, userId } = await authApi.authViaCode(code, dayjs.tz.guess());

  AnalyticsService.setMetaData({ userId });
  AnalyticsService.sendEvent('auth_via_code');

  setAuth(accessToken, userId);
};

export const authViaToken = (token: string, userId: string) => {
  AnalyticsService.setMetaData({ userId });
  AnalyticsService.sendEvent('auth_via_link');

  setAuth(token, userId);
};

export const subscribeOnDropAuth = (callback: () => void) => {
  useAuthStore.subscribe((state, prevState) => {
    if (prevState.token && !state.token) {
      callback();
    }
  });
};
