import { mentorActions } from 'modules/mentor';
import React, { useEffect } from 'react';

const PrefetchMentorCatalog: React.FC = () => {
  useEffect(() => {
    mentorActions.prefetchMentorCatalog();
  }, []);

  return null;
};

export default PrefetchMentorCatalog;
