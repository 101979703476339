import { Button } from '@mindkit';
import BookingItem from 'modules/booking/components/BookingItem/BookingItem';
import UserPackItem from 'modules/pack/components/UserPackItem/UserPackItem';
import { CheckoutPaid } from 'modules/payment/model';
import RecordingItem from 'modules/recording/components/RecordingItem/RecordingItem';
import React from 'react';
import { Link } from 'react-router-dom';

import Layout from './Layout';

const CheckoutItemResult: React.FC<{
  mentorId: string;
  checkoutResult: CheckoutPaid['checkoutResult'];
}> = ({ mentorId, checkoutResult }) => {
  if (checkoutResult.type === 'booking') {
    const { booking } = checkoutResult;

    return (
      <Layout
        title="Success!"
        description={`You succesfuly booked a slot with ${booking.mentor.displayName}`}
      >
        <BookingItem className="w-full max-w-sm" booking={booking} />
        <Button as={Link} className="mt-4" to={`/mentor/${mentorId}`} variant="light">
          Back to the mentor
        </Button>
      </Layout>
    );
  }

  if (checkoutResult.type === 'userPackage') {
    const { pack } = checkoutResult;

    return (
      <Layout title="Success!" description="You succesfuly activated a pack">
        <UserPackItem className="w-full max-w-md" pack={pack} />
        <Button as={Link} className="mt-4" to={`/mentor/${mentorId}`} variant="light">
          Back to the mentor
        </Button>
      </Layout>
    );
  }

  if (checkoutResult.type === 'recording') {
    const { recording } = checkoutResult;

    return (
      <Layout title="Success!" description="You succesfuly purchased a recording">
        <RecordingItem className="w-full max-w-md" recording={recording} />
        <Button as={Link} className="mt-4" to={`/mentor/${mentorId}`} variant="light">
          Back to the mentor
        </Button>
      </Layout>
    );
  }

  return <h4 className="text-danger">Unknown item</h4>;
};

export default CheckoutItemResult;
