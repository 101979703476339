import { haptic, notify } from '@mindkit';
import dayjs from 'dayjs';
import { bookingActions } from 'modules/booking';
import { getPlatform } from 'modules/general/actions/generalActions';
import { mentorActions } from 'modules/mentor';
import { openCheckoutModal, paymentActions } from 'modules/payment';
import { Checkout, CheckoutPending, PaymentOption } from 'modules/payment/model';
import { timeslotActions } from 'modules/timeslot';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { AnalyticsService } from 'services/analytics';
import { ErrorService } from 'services/error';
import getErrorMessage from 'shared/helpers/getErrorMessage';

const isInstantCheckout = (checkout: CheckoutPending) => {
  return (
    !checkout.emailRequired &&
    !checkout.policyRequired &&
    checkout.paymentOptions.length === 1 &&
    checkout.checkoutRequest.type !== 'package'
  );
};

const useCheckout = () => {
  const navigate = useNavigate();

  const processCheckout = useCallback(
    async (
      checkout: CheckoutPending,
      paymentOption: PaymentOption,
      privacyChecked = false,
      email?: string,
    ) => {
      try {
        const result = await paymentActions.processCheckout(
          checkout,
          paymentOption,
          getPlatform(),
          dayjs.tz.guess(),
          privacyChecked,
          email,
        );
        AnalyticsService.sendEvent('checkout_attempt', {
          ...checkout.aggregatedInfo,
          paymentMethod: paymentOption.method,
          result,
        });
        if (result === 'paid') {
          haptic.notification('success');

          paymentActions.dropUnfinishedCheckout(checkout.id);
          bookingActions.invalidateBookings();
          mentorActions.invalidateMentor(checkout.mentorId);
          timeslotActions.invalidateTimeslots(checkout.mentorId);
        }

        return result;
      } catch (e) {
        ErrorService.captureException(e);
        AnalyticsService.sendEvent('checkout_error', {
          ...checkout.aggregatedInfo,
          paymentMethod: paymentOption.method,
          errorMessage: getErrorMessage(e),
        });

        notify(getErrorMessage(e), 'error');

        return 'failed';
      }
    },
    [],
  );

  const startCheckout = useCallback(
    async (checkout: Checkout) => {
      if (checkout.status !== 'Pending') {
        throw new Error('Checkout status is not pending');
      }

      if (checkout.paymentOptions.length === 10) {
        notify('No available payment options', 'error');
        AnalyticsService.sendEvent('checkout_no_payment_options_error', checkout.aggregatedInfo);
        return;
      }

      AnalyticsService.sendEvent('checkout_start', checkout.aggregatedInfo);

      let result;
      if (isInstantCheckout(checkout)) {
        result = await processCheckout(checkout, checkout.paymentOptions[0], true);
        if (!result) {
          ErrorService.captureException(new Error('Unexpected instant checkout result'));
          notify('Something went wrong', 'error');
          return;
        }
      } else {
        result = await openCheckoutModal(checkout);
      }

      AnalyticsService.sendEvent('checkout_complete', { ...checkout.aggregatedInfo, result });

      if (result === 'paid') {
        navigate(`/checkout/${checkout.id}`);
      }
      if (result === 'cancelled') {
        haptic.notification('warning');
        paymentActions.invalidateUnfinishedCheckout();
      }
    },
    [navigate, processCheckout],
  );

  return {
    processCheckout,
    startCheckout,
  };
};

export default useCheckout;
